import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RestaurantMealTagSelect from './RestaurantMealTagSelect';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CustomDialogTitle = ({ children, onClose, ...other }) => {
  const classes = useStyles();
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default function RestaurantMealTagSelectDialog({
  onClose,
  open,
  tagOptions,
  data: inData,
  onUpdate,
}) {
  const classes = useStyles();

  useEffect(() => {
  }, []);

  const handleClose = async (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose && onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleClose}
      >
        <CustomDialogTitle id="form-dialog-titlse" onClose={handleClose}>
          修改統計項目
        </CustomDialogTitle>
        <DialogContent className={classes.content} dividers>
          <RestaurantMealTagSelect
            tagOptions={tagOptions}
            mode='edit'
            data={inData}
            onUpdate={(value) => {
              onUpdate && onUpdate(value);
              handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

RestaurantMealTagSelectDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  tagOptions: PropTypes.array,
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
