import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DayPicker, { DateUtils } from 'react-day-picker';
import TimePicker from 'react-time-picker';
import moment from 'moment-timezone';
import { getTranslatedMealSlots } from 'utilities/translate';

import { TIME_ZONE, MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from '@silvergatedelivery/constants';

// const TIME_ZONE = 'Asia/Taipei';

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    minHeight: 300,
  },
  summary: {
    padding: theme.spacing(1),
    marginTop: -12,
  },
}));

// https://react-day-picker.js.org/examples/months-restrict

const dayMapping = [
  'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday',
];

export default function MealDateSelector({
  mealSlot = 'lunch',
  defaultSelectedDays = [],
  forceUpdateDefaultSelectedDays = false,
  onChange,
  unlockDates = false,
  openingHours = [],
  holidays = [],
  dateOnly = false,
  excludeToday = false,
}) {
  const classes = useStyles();

  const defaultTime = (mealSlot === 'lunch' ? '12:00' : '18:00');
  const { label: mealSlotTitle } = getTranslatedMealSlots().find(({ value }) => value === mealSlot);

  const [hasSetDefault, setHasSetDefault] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [time, setTime] = useState(defaultTime);
  const [month, setMonth] = useState();
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const [rangeSelectStart, setRangeSelectStart] = useState();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Shift') {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Shift') {
        setShiftKeyPressed(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!forceUpdateDefaultSelectedDays && hasSetDefault) return;

    setHasSetDefault(true);

    setSelectedDays([...defaultSelectedDays.map((x) => new Date(x))]);

    if (defaultSelectedDays[0]) {
      const newTime = moment(defaultSelectedDays[0]).tz(TIME_ZONE).format('HH:mm');
      setTime(newTime);
      if (!month) { // 當defaultSelectedDays裡有多個月份時，例如有1月和2月, 讓月曆的月份不會在點選2月時後自動跳回1月
        setMonth(new Date(defaultSelectedDays[0]));
      }
    } else {
      setTime(defaultTime);
      setMonth(new Date());
    }
  }, [defaultSelectedDays, hasSetDefault, forceUpdateDefaultSelectedDays]);

  const updateSelectedDays = (time, selectedDays) => {
    if (!time) return;

    const [hour, minute] = time.split(':');
    const days = selectedDays.map((day) => {
      const dayItem = moment(day).tz(TIME_ZONE);
      dayItem.set({
        hour,
        minute,
        second: 0,
        millisecond: 0,
      });
      if (dateOnly) {
        return dayItem.format('YYYY-MM-DD');
      }
      return dayItem.toISOString();
    }).sort();
    if (onChange) {
      onChange(days);
    }
  };

  // useEffect(() => {
  //   // alter the time based on the selected delivery time
  // }, [time, selectedDays, onChange]);

  const handleDayClick = (day, { selected, disabled }) => {
    if (disabled) return;

    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day));
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
      if (!shiftKeyPressed) {
        setRangeSelectStart(day);
      }
      if (shiftKeyPressed && rangeSelectStart && !DateUtils.isSameDay(day, rangeSelectStart)) {
        let startDay = rangeSelectStart;
        let endDay = day;
        if (rangeSelectStart > day) {
          startDay = day;
          endDay = rangeSelectStart;
        }
        const dayList = [];
        for (const currentDate = new Date(startDay); currentDate <= endDay; currentDate.setDate(currentDate.getDate() + 1)) {
          dayList.push(new Date(currentDate));
        }
        dayList.forEach((day) => {
          if (selectedDays.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day)) === -1) {
            selectedDays.push(day);
          }
        });
      }
    }
    setSelectedDays([...selectedDays]);
    updateSelectedDays(time, selectedDays);
  };

  const onPickTime = (newTime) => {
    const time = newTime || defaultTime;
    setTime(time);
    updateSelectedDays(time, selectedDays);
  };

  const timeInSlot = (startTime, endTime, toCheck) => {
    const toMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };
    return toMinutes(toCheck) >= toMinutes(startTime) && toMinutes(toCheck) <= toMinutes(endTime);
  };

  const modifiers = {
    today: new Date(),
    holiday: (day) => {
      if (holidays && holidays.length !== 0) {
        const dateItem = moment(day).tz(TIME_ZONE);
        const date = dateItem.format('YYYY-MM-DD');
        const year = dateItem.year();
        const holidaysToCheck = holidays.find(
          (holiday) => holiday.year === year,
        );
        if (holidaysToCheck && holidaysToCheck.closedDays.length !== 0) {
          if (holidaysToCheck.closedDays.includes(date)) {
            return true;
          }
        }
      }
      if (!openingHours || Object.keys(openingHours).length === 0 ||
        Object.keys(openingHours).every((key) => openingHours[key].length === 0)) {
        return false;
      }
      const openingHour = openingHours[dayMapping[day.getDay()]];
      if (openingHour.length === 0) {
        return true;
      }
      if (mealSlot === 'lunch') {
        return !openingHour.some((slot)=> timeInSlot(slot.startTime, slot.endTime, '12:00'));
      } else if (mealSlot === 'dinner') {
        return !openingHour.some((slot)=> timeInSlot(slot.startTime, slot.endTime, '18:00'));
      }
      return false;
    },
  };

  const modifiersStyles = {
    today: {
      color: 'black',
      border: '2px solid blue',
    },
    holiday: {
      color: 'red',
    },
  };

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={1} direction="column" className={classes.container} >
      <DayPicker
        selectedDays={selectedDays}
        months={MONTHS}
        month={month}
        onMonthChange={setMonth}
        weekdaysLong={WEEKDAYS_LONG}
        weekdaysShort={WEEKDAYS_SHORT}
        onDayClick={handleDayClick}
        showWeekNumbers
        className={classes.day}
        disabledDays={unlockDates ? {} : (excludeToday ? { before: tomorrow } : { before: today })}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        style={{ width: '100%' }}
      />
      <div className={classes.summary}>
        {!dateOnly &&
        <TimePicker
          onChange={onPickTime}
          value={time}
          locale={'zh-TW'}
          disableClock={true}
          format={'HH:mm'}
          // minTime={'11:00'}
          // maxTime={'20:00'}
          required={true}
        />}
        <div style={{ width: 10, display: 'inline-block' }} />
        {!dateOnly && mealSlotTitle} 天數：{selectedDays.length}
      </div>
    </Grid>
  );
}

MealDateSelector.propTypes = {
  mealSlot: PropTypes.string,
  defaultSelectedDays: PropTypes.array,
  onChange: PropTypes.func,
  forceUpdateDefaultSelectedDays: PropTypes.bool,
  unlockDates: PropTypes.bool,
  openingHours: PropTypes.object,
  holidays: PropTypes.array,
  dateOnly: PropTypes.bool,
  excludeToday: PropTypes.bool,
};
