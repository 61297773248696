import CheckboxesWithSelectAll from 'forms/components/CheckboxesWithSelectAll';
import AutoSelectWidget from 'forms/components/AutoSelectWidget';

const uiSchema = {
  'clientId': { 'ui:width': '50%' },
  'relationshipToClient': { 'ui:width': '50%' },
  'name': { 'ui:width': '33.33%' },
  'nickname': { 'ui:width': '33.33%' },
  'username': { 'ui:width': '33.33%' },
  'phoneNumber': { 'ui:width': '33.33%' },
  'phoneNumber2': { 'ui:width': '33.33%' },
  'phoneNumber3': { 'ui:width': '33.33%' },
  'identificationCardId': { 'ui:width': '33.33%' },
  'birthday': { 'ui:width': '23.33%' },
  'gender': {
    'ui:width': '43.33%',
    'ui:widget': 'radio',
    'ui:options': {
      'inline': true,
    },
  },
  'isDisadvantaged': {
    'ui:width': '50%',
    'ui:widget': 'radio',
    'ui:options': {
      'inline': true,
    },
  },
  'isAllowedToEnterHome': {
    'ui:width': '50%',
    'ui:widget': 'radio',
    'ui:options': {
      'inline': true,
    },
  },
  'onTimeDelivery': {
    'ui:width': '50%',
    'ui:widget': 'radio',
    'ui:options': {
      'inline': true,
    },
  },
  'dementiaQuestionnaire': {
    'ui:width': '50%',
    'ui:widget': 'radio',
    'ui:options': {
      'inline': true,
    },
  },
  'disabilityLevel': {
    'ui:width': '50%',
  },
  'cmsLevel': {
    'ui:width': '50%',
  },
  'serviceCode': {
    'ui:width': '50%',
    'ui:options': {
      'allowCustomInput': true,
    },
    'ui:widget': AutoSelectWidget,
  },
  'linkGroupName': { 'ui:width': '50%' },
  'otherSocialServices': {
    'ui:widget': 'textarea',
  },
  'noteForDelivery': {
    'ui:widget': 'textarea',
  },
  'lunchRepeatOn': {
    'ui:widget': CheckboxesWithSelectAll,
    'ui:options': {
      'inline': true,
    },
  },
  'dinnerRepeatOn': {
    'ui:widget': CheckboxesWithSelectAll,
    'ui:options': {
      'inline': true,
    },
  },
  'noteForMeal': {
    'ui:widget': 'textarea',
  },
  'noteForHealth': {
    'ui:widget': 'textarea',
  },
  'note': {
    'ui:widget': 'textarea',
  },
  'disadvantagedTypesHistory': {
    'items': {
      'disadvantagedTypes': { 'ui:width': '50%' },
      'approvedDate': { 'ui:width': '50%' },
      'ui:title': '',
    },
    'ui:options': {
      'orderable': false,
    },
  },
  'caseNumber': { 'ui:width': '50%' },
  'govQualifiedCount': { 'ui:width': '50%' },
  'supervisorId': { 'ui:width': '50%' },
  'fixedDeliveryStaffFee': { 'ui:width': '50%' },
};

export default uiSchema;
