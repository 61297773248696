export const NAME = '銀色大門｜長照送餐管理系統';

export const SHORT_NAME = '銀色大門';

export const DRAWER_WIDTH = 180;

export const LOCALE = 'zh-TW';

export const TIME_ZONE = 'Asia/Taipei';

export const LAUNCH_DATE = '2019-12-31T16:00:00.000Z';

export const AUTO_UPDATE_INTERVAL_IN_MILLISECONDS = 5 * 60 * 1000;

export const SUPPORT_NUMBER = '+886958441885';

export const PASSWORD_MIN_LENGTH = 8;

export const MONTHS = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月',
];

export const WEEKDAYS_LONG = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

export const WEEKDAYS_SHORT = ['日', '一', '二', '三', '四', '五', '六'];

export const SHOP_PREFIX = 'shop';
export const GROUP_ORDER_PREFIX= 'grouporder';

export const liffIds = {
  // 'c147-218-35-139-153.ngrok-free.app': '2006592741-lQyMBpKG',
  'default': '2006592741-lQyMBpKG',
  'shop.develop.silvergatedelivery.com': '2006592741-lQyMBpKG',
  'shopdemo.silvergatedelivery.com': '2006592741-0AAjgGkw',
  'shop.silvergatedelivery.com': '2006592741-NJBMVjRX',
};
