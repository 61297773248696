import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'components/Table/DataTable';
import TagChip from 'components/TagChip';
import { sortBy } from 'utilities/sorting';
import IconButton from '@material-ui/core/IconButton';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

export const formatTags = (tags) => {
  if (!tags || tags.length === 0) {
    return '';
  }

  return (
    <React.Fragment>
      {tags
        .map((tag) => ({ label: tag, category: '送餐對象', subcategory: '飲食' }))
        .sort(sortBy('name'))
        .map((tag, index)=>(
          <TagChip
            key={index}
            tag={tag}
          />
        ))}
    </React.Fragment>);
};

const defaultColumns = [
  {
    name: 'index',
    label: '順序',
    options: {
      display: true,
      filter: false,
      sort: true,
      customBodyRender: (value) => value + 1,
    },
  },
  {
    name: 'restaurantName',
    label: '餐廳',
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'mealItemName',
    label: '餐點',
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: 'tags',
    label: '飲食標籤',
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: formatTags,
    },
  },
];

export default function MealStatisticsTable({
  description,
  data: inData,
  onRowUp,
  onRowDown,
  onRowEdit,
  onRowDelete,
  onCellClick,
  onRefresh,
  ...props
}) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(defaultColumns);
  const [filterList, setFilterList] = useState([]);

  const actionButtons = {
    name: 'index',
    label: ' ',
    options: {
      display: 'always',
      filter: false,
      sort: false,
      download: false,
      print: false,
      customBodyRender(index, tableMeta) {
        const tags = tableMeta.rowData[3];
        if (!tags || tags.length === 0) {
          return null;
        }
        return (
          <>
            <Tooltip
              title={'往上移'}
              placement="bottom"
            >
              <IconButton
                size={'small'}
                onClick={() => {
                  onRowUp && onRowUp(index);
                }}
              >
                {<ArrowUpwardIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={'往下移'}
              placement="bottom"
            >
              <IconButton
                size={'small'}
                onClick={() => {
                  onRowDown && onRowDown(index);
                }}
              >
                {<ArrowDownwardIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={'修改統計項目'}
              placement="bottom"
            >
              <IconButton
                size={'small'}
                onClick={() => {
                  onRowEdit && onRowEdit(index);
                }}
              >
                {<EditIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={'刪除統計項目'}
              placement="bottom"
            >
              <IconButton
                size={'small'}
                onClick={() => {
                  onRowDelete && onRowDelete(index);
                }}
              >
                {<DeleteIcon />}
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  };

  const options = {
    expandableRows: false,
    isRowExpandable: () => false,
    onTableChange: (action, tableState) => {
      if (action === 'filterChange') {
        setFilterList(tableState.filterList);
      }
    },
  };

  useEffect(() => {
    const newData = [];
    const newColumns = [...defaultColumns];
    const restaurantFilter = filterList[1] || [];
    const mealFilter = filterList[2] || [];
    const total = inData.reduce((sum, row) => {
      let needToCount = true;
      if (restaurantFilter.length !== 0 && !restaurantFilter.includes(row.restaurantName)) {
        needToCount = false;
      }
      if (mealFilter.length !== 0 && !mealFilter.includes(row.mealItemName)) {
        needToCount = false;
      }
      if (needToCount) {
        return sum + row.total || 0;
      } else {
        return sum;
      }
    }, 0);

    if (inData?.length !== 0) {
      newColumns.push({
        name: 'total',
        label: `數量 (${total})`,
        options: {
          display: 'always',
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => (
            <div style={{ cursor: 'pointer' }} onClick={() => {
              onCellClick && onCellClick({ orderIds: inData[tableMeta.rowData[0]].orderIds || [] });
            }}>
              {value}
            </div>
          ),
        },
      });
      inData[0].deliveryStaffMealCountList.forEach(({ id, name }) => {
        const getCellInfo = (rowIndex) => {
          const { orderIds } = inData[rowIndex].deliveryStaffMealCountList.find((item) => item.id === id);
          return {
            orderIds,
          };
        };
        const deliveryStaffTotal = inData.reduce((sum, row) => {
          let needToCount = true;
          if (restaurantFilter.length !== 0 && !restaurantFilter.includes(row.restaurantName)) {
            needToCount = false;
          }
          if (mealFilter.length !== 0 && !mealFilter.includes(row.mealItemName)) {
            needToCount = false;
          }
          if (needToCount) {
            return sum + (row.deliveryStaffMealCountList.find((item) => item.id === id) || {}).count || 0;
          } else {
            return sum;
          }
        }, 0);
        newColumns.push({
          name: id,
          label: `${name} (${deliveryStaffTotal})`,
          options: {
            display: 'always',
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => (
              <div style={{ cursor: 'pointer' }} onClick={() => {
                onCellClick && onCellClick(getCellInfo(tableMeta.rowData[0]));
              }}>
                {value}
              </div>
            ),
          },
        });
      });
      inData.forEach(({ index, restaurantName, mealItemName, tags, deliveryStaffMealCountList, total }) => {
        const row = {
          index,
          restaurantName,
          mealItemName,
          tags,
          total,
        };
        deliveryStaffMealCountList.forEach(({ id, count }) => {
          Object.assign(row, { [id]: count });
        });
        newData.push(row);
      });
    }
    setData(newData);
    setColumns(newColumns);
    setColumns([...newColumns, actionButtons]);
  }, [inData, filterList]);

  return (
    <DataTable
      data={data}
      title={'餐點統計'}
      description={description}
      columns={columns}
      options={options}
      defaultIndex={0}
      indexes={[]}
      queryDefaultParams={{}}
      onRefresh={onRefresh}
      {...props}
    />
  );
}

MealStatisticsTable.propTypes = {
  description: PropTypes.string,
  data: PropTypes.array,
  onRowUp: PropTypes.func,
  onRowDown: PropTypes.func,
  onRowEdit: PropTypes.func,
  onRowDelete: PropTypes.func,
  onCellClick: PropTypes.func,
  onRefresh: PropTypes.func,
};
