import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import cache from 'utilities/cache';
import { formatAddress } from 'utilities/format';
import { request, asyncListAll } from 'utilities/graph';
import { createElder, updateElder, individualClientCreateOrder } from 'graphql/mutations';
import { getEldersByClientByStatus, getUser } from 'graphql/queries';
import LinePayButton from 'components/Payment/LinePayButton';
import NewebPayButton from 'components/Payment/NewebPayButton';
import moment from 'moment';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import { useCache } from 'CacheProvider';
import AddressDialog from './AddressDialog';
import ReceiverDialog from './ReceiverDialog';
import InvoiceDialog from './InvoiceDialog';
import PaymentDialog from './PaymentDialog';
import OrderDetail from './OrderDetail';
import AutoSizeDialog from 'components/AutoSizeDialog';
import { sortBy } from 'utilities/sorting';
import { useHistory } from 'react-router-dom';

const defaultAddress = {
  county: '臺北市',
  district: '中正區',
  street: '',
  note: '',
};
const defaultReceiver = {
  name: '',
  phoneNumber: '',
  phoneNumber2: '',
  phoneNumber3: '',
  noteForDelivery: '',
};
const defaultInvoice = {
  type: 'individual',
  email: '',
  carrier: '',
  title: '',
  taxIdNumber: '',
};
const defaultPayment = { type: 'linepay' };

const formatInvoice = (invoice) => {
  if (!invoice) {
    return '';
  }
  switch (invoice.type) {
    case 'individual':
      return '個人';
    case 'enterprise':
      return '企業';
    case 'donation':
      return '捐贈給社團法人銀色大門老人福利組織';
    default:
      return '';
  }
};

const formatReceiver = (receiver) => {
  if (!receiver) {
    return '';
  }
  let receiverDisplay = `${receiver.name}\n電話：${receiver.phoneNumber}`;
  if (receiver.noteForDelivery) {
    receiverDisplay += `\n備註：${receiver.noteForDelivery}`;
  }
  return receiverDisplay;
};

const formatPayment = (payment) => {
  if (!payment) {
    return '';
  }
  switch (payment.type) {
    case 'linepay':
      return 'LINE Pay';
    case 'newebpay':
      return '信用卡或網路ATM（藍新金流）';
    default:
      return '';
  }
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionButton: {
    width: '80%',
    borderRadius: '20px',
    color: 'white',
  },
  settingTitle: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  settingBlock: {
    border: '1px solid #cecece',
    borderRadius: '10px',
  },
  price: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

export default function CompleteOrderDialog({
  onClose,
  open,
  orders = [],
}) {
  const classes = useStyles();
  const [address, setAddress] = useState(cache.get('public:menu2-address') || defaultAddress);
  const [receiver, setReceiver] = useState(cache.get('public:menu2-receiver') || defaultReceiver);
  const [invoice, setInvoice] = useState(cache.get('public:menu2-invoice') || defaultInvoice);
  const [payment, setPayment] = useState(cache.get('public:menu2-payment') || defaultPayment);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeCancelInvoice, setAgreeCancelInvoice] = useState(false);
  const [mealTotalPrice, setMealTotalPrice] = useState();
  const [totalDeliveryStaffFee, setTotalDeliveryStaffFee] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { appGroup, userGroupOptions } = useCache();
  const history = useHistory();
  const email = cache.get('app:email');

  useEffect(() => {
    let clientOption;
    if (userGroupOptions?.length > 0) {
      clientOption = userGroupOptions.find(({ userGroupName }) => userGroupName === 'Clients');
    }
    // 如果在cart頁面logiin, recevier資料需要update
    if (!receiver?.name && clientOption?.clientId) {
      const { clientId } = clientOption;
      (async () => {
        const existingElders = (await asyncListAll(getEldersByClientByStatus, {
          clientId,
          status: { eq: '使用中' },
        })).sort(sortBy('updatedAt'));
        if (existingElders.length !== 0) {
          const { name, phoneNumber, phoneNumber2, phoneNumber3, noteForDelivery } = existingElders[existingElders.length - 1];
          const existingReceiver = {
            name,
            phoneNumber,
            phoneNumber2,
            phoneNumber3,
            noteForDelivery,
          };
          cache.set('public:menu2-receiver', existingReceiver);
          setReceiver(existingReceiver);
        }
      })();
    }
  }, [appGroup, userGroupOptions]);

  useEffect(() => {
    const { mealTotalPrice, totalDeliveryStaffFee } = orders.reduce((acc, order) => {
      acc.mealTotalPrice += order.mealItems.reduce(( acc, mealItem ) => {
        acc += mealItem.price * mealItem.quantity;
        return acc;
      }, 0);
      acc.totalDeliveryStaffFee += order.quotation?.deliveryStaffFee || 0;
      return acc;
    }, { mealTotalPrice: 0, totalDeliveryStaffFee: 0 });
    setMealTotalPrice(mealTotalPrice);
    setTotalDeliveryStaffFee(totalDeliveryStaffFee);
  }, [orders]);

  const settings = [
    {
      key: 'address',
      title: '外送地址',
      value: address ? formatAddress(address) : '',
      editComponent: AddressDialog,
      onSettingUpdate: setAddress,
      defaultValue: defaultAddress,
      allowEdit: false, // 修改地址後需要重算運費，而且餐廳可能超出範圍
    },
    {
      key: 'receiver',
      title: '取餐人資訊',
      value: receiver ? formatReceiver(receiver) : '',
      editComponent: ReceiverDialog,
      onSettingUpdate: setReceiver,
      defaultValue: defaultReceiver,
      allowEdit: true,
    },
    {
      key: 'invoice',
      title: '發票類型',
      value: invoice?.type ? formatInvoice(invoice) : '',
      editComponent: InvoiceDialog,
      onSettingUpdate: setInvoice,
      defaultValue: {
        ...defaultInvoice,
        email,
      },
      allowEdit: true,
    },
    {
      key: 'payment',
      title: '付款方式',
      value: payment?.type ? formatPayment(payment) : '',
      editComponent: PaymentDialog,
      onSettingUpdate: setPayment,
      defaultValue: defaultPayment,
      allowEdit: true,
    },
  ];

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    onClose && onClose();
  };

  const handleComplete = async (transaction) => {
    setIsLoading(true);
    console.log('address', address);
    console.log('receiver', receiver);
    console.log('invoice', invoice);
    console.log('payment', payment);

    const username = cache.get('app:username');
    let individualId = cache.get('app:clientId');
    const facilityId = cache.get('public:menu-target-client-id');
    if (!individualId) {
      const { data: { getUser: existingUser } } = await request(getUser, { username });
      individualId = existingUser.clientId;
    }

    const { id: transactionId } = transaction || {};
    const { name: elderName, phoneNumber, phoneNumber2, phoneNumber3, noteForDelivery } = receiver || {};

    let elderId;
    let needCreateElder = false;
    let needUpdateElder = false;

    const existingElders = await asyncListAll(getEldersByClientByStatus, {
      clientId: individualId,
      status: { eq: '使用中' },
    });
    const matched = existingElders.find((existingElder) => existingElder.name === elderName &&
      formatAddress(existingElder.address, { includeZipCode: false, ignoreNote: true }) ===
      formatAddress(address, { includeZipCode: false, ignoreNote: true }));
    if (matched) {
      elderId = matched.id;
      if (matched.noteForDelivery !== noteForDelivery || matched.phoneNumber !== phoneNumber) {
        needUpdateElder = true;
      }
    } else {
      needCreateElder = true;
    }
    if (needUpdateElder) {
      await request(updateElder, {
        input: {
          id: elderId,
          updatedBy: username,
          phoneNumber,
          noteForDelivery,
        },
      }, 'AMAZON_COGNITO_USER_POOLS');
    } else if (needCreateElder) {
      const { data: { createElder: { id } } } = await request(createElder, {
        input: {
          status: '使用中',
          clientId: individualId,
          county: address.county,
          createdBy: username,
          updatedBy: username,
          username: 'N/A',
          name: elderName,
          phoneNumber,
          phoneNumber2,
          phoneNumber3,
          address,
          noteForDelivery,
          isAllowedToEnterHome: 0,
          isDisadvantaged: 0,
        },
      }, 'AMAZON_COGNITO_USER_POOLS');

      elderId = id;
    }

    const getInvoiceSetting = () => {
      const { type, email, carrier, title, taxIdNumber } = invoice;
      return {
        type,
        email,
        carrier: (type === 'individual')? carrier : '',
        title: type === 'enterprise' ? title : '',
        taxIdNumber: type === 'enterprise' ? taxIdNumber : '',
      };
    };
    console.log(orders);
    const input = {
      orders: orders.map((order) => {
        const { quotation, dateTime, mealItems, noteForMeal } = order;
        const { date, time } = dateTime;
        const deliveryBy = moment(`${date} ${time.split('-')[0]}`).tz(TIME_ZONE).toISOString();
        const total = mealItems.reduce(( acc, mealItem ) => {
          acc += mealItem.price * mealItem.quantity;
          return acc;
        }, 0);
        return {
          orderQuoteId: quotation.orderQuoteId,
          restaurantId: mealItems[0].restaurantId, // TODO
          elderId,
          category: '送餐',
          deliveryDatetimes: [deliveryBy],
          noteForDelivery,
          noteForMeal,
          // note,
          total,
          mealItems: mealItems.map(({ name, price, quantity }) => ({
            name: name,
            price: price,
            quantity: quantity,
          })),
        };
      }),
      invoiceSetting: getInvoiceSetting(),
      transactionId: transactionId,
      individualId,
      facilityId,
      source: 'web',
    };

    console.log(input);

    const res = await request(individualClientCreateOrder, { input });
    global.logger.debug(res);
    cache.purge('public:menu2-');
    cache.purge('public:menu-');
    // keep address and receiver
    cache.set('public:menu2-address', address);
    cache.set('public:menu2-receiver', receiver);
    cache.set('public:menu2-active-page', 'order');
    setIsLoading(false);
    handleClose();
    history.push('/');
    window.location.reload();
  };

  const formDateInvalid = () => {
    const validReceiver = receiver && receiver.name && receiver.phoneNumber;
    return isLoading || !agreeTerms || !agreeCancelInvoice || !validReceiver;
  };

  return (
    <React.Fragment>
      <AutoSizeDialog
        open={open}
        title='結帳'
        onClose={handleClose}
      >
        <DialogContent className={classes.content} dividers>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                訂單摘要
              </Typography>
            </Grid>
            {
              orders.map((order, index) => {
                return <Grid item xs={12} md={6} key={`order-detail-${index}`}>
                  <OrderDetail
                    order={order}
                  />
                </Grid>;
              })
            }
            {
              settings.map((item) => {
                const { key, title, value, onSettingUpdate, defaultValue, allowEdit } = item;
                const cacheKey = `public:menu2-${item.key}`;
                const formData = cache.get(cacheKey) || {};
                return (
                  <div key={key} style={{ width: '100%' }}>
                    <Grid item xs={12}>
                      <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.settingBlock}>
                      <item.editComponent
                        label={value}
                        formData={formData}
                        onUpdate={(data) => {
                          cache.set(`public:menu2-${item.key}`, data);
                          onSettingUpdate && onSettingUpdate(data);
                        }}
                        defaultValue={defaultValue}
                        allowEdit={allowEdit}
                      />
                    </Grid>
                  </div>);
              })
            }
            {/* <Grid item xs={12}>
              <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                關懷需求
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Typography variant='h6' color='textPrimary' align='left' className={classes.settingTitle}>
                費用詳情
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.price}>
                <Grid container justifyContent='space-between' style={{ padding: 10 }}>
                  <Typography variant='body1' color='textPrimary'>
                    餐點總金額
                  </Typography>
                  <Typography variant='body1' color='textPrimary'>
                    { mealTotalPrice !== undefined ? `$${mealTotalPrice}` : '計算中'}
                  </Typography>
                </Grid>
                <Grid container justifyContent='space-between' style={{ padding: 10 }}>
                  <Typography variant='body1' color='textPrimary'>
                    運費總金額
                  </Typography>
                  <Typography variant='body1' color='textPrimary'>
                    { totalDeliveryStaffFee !== undefined? `$${totalDeliveryStaffFee}` : '計算中'}
                  </Typography>
                </Grid>
                <Divider />
                <Grid container justifyContent='space-between' style={{ padding: 10 }}>
                  <Typography variant='body1' color='textPrimary'>
                    總付款金額
                  </Typography>
                  <Typography variant='body1' color='textPrimary' >
                    { mealTotalPrice !== undefined && totalDeliveryStaffFee !== undefined ?
                      `$${mealTotalPrice + totalDeliveryStaffFee}` : '計算中'}
                  </Typography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start" style={{ paddingTop: 16 }}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreeTerms"
                      checked={agreeTerms}
                      onChange={(e) => {
                        setAgreeTerms(e.target.checked);
                      }}
                    />}
                  label={
                    <div style={{ fontSize: 14 }}>
                      我同意
                      <Link
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener"
                        variant="body2"
                        underline="always"
                      >
                        隱私權及保護政策
                      </Link>
                      與
                      <Link
                        href="/return-policy"
                        target="_blank"
                        rel="noopener"
                        variant="body2"
                        underline="always"
                      >
                        退貨退款政策
                      </Link>
                    </div>}
                />
              </Grid>
              {/* {!agreeTerms && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="error" >
                    請您同意私權及保護政策與退貨退款政策
                  </Typography>
                </Grid>
              )} */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreeCancelInvoice"
                      checked={agreeCancelInvoice}
                      onChange={(e) => {
                        setAgreeCancelInvoice(e.target.checked);
                      }}
                    />}
                  label={
                    <div style={{ fontSize: 14 }}>
                      我同意辦理退貨時由銀色大門代為處理電子發票以加速退貨退款作業
                    </div>}
                />
              </Grid>
              {/* {!agreeCancelInvoice && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="error" >
                    請您同意辦理退貨時由銀色大門代為處理電子發票以加速退貨退款作業
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container>
            <Grid xs={12} item container justifyContent='center' alignItems='center'>
              {payment.type === 'linepay' &&
              <LinePayButton
                disabled={formDateInvalid()}
                order={{
                  subTotal: mealTotalPrice + totalDeliveryStaffFee,
                  amount: mealTotalPrice + totalDeliveryStaffFee,
                  discount: 0,
                  discountRule: undefined,
                  description: '網頁訂單',
                  category: '送餐',
                  items: orders.map(({ mealItems, quotation }) => {
                    const meals = mealItems.map(({ name, quantity, price }) => ({
                      name,
                      qty: quantity,
                      unit: '份',
                      unitValue: price,
                      subTotal: price * quantity,
                    }));
                    const deliveryStaffFee = {
                      name: '送餐費用',
                      qty: 1,
                      unit: '次',
                      unitValue: quotation?.deliveryStaffFee || 0,
                      subTotal: quotation?.deliveryStaffFee || 0,
                    };
                    return [...meals, deliveryStaffFee];
                  }).flat(),
                }}
                redirectPath={'/'}
                onComplete={handleComplete}
                reportLoading={(value) => {
                  setIsLoading(value);
                }}
                textButton
              />}
              {payment.type === 'newebpay' &&
              <NewebPayButton
                disabled={formDateInvalid()}
                order={{
                  subTotal: mealTotalPrice + totalDeliveryStaffFee,
                  amount: mealTotalPrice + totalDeliveryStaffFee,
                  discount: 0,
                  discountRule: undefined,
                  description: '網頁訂單',
                  category: '送餐',
                  items: orders.map(({ mealItems, quotation }) => {
                    const meals = mealItems.map(({ name, quantity, price }) => ({
                      name,
                      qty: quantity,
                      unit: '份',
                      unitValue: price,
                      subTotal: price * quantity,
                    }));
                    const deliveryStaffFee = {
                      name: '送餐費用',
                      qty: 1,
                      unit: '次',
                      unitValue: quotation?.deliveryStaffFee || 0,
                      subTotal: quotation?.deliveryStaffFee || 0,
                    };
                    return [...meals, deliveryStaffFee];
                  }).flat(),
                }}
                redirectPath={'/'}
                clientBackPath={'/'}
                email={email}
                onComplete={handleComplete}
                reportLoading={(value) => {
                  setIsLoading(value);
                }}
                textButton
              />}
            </Grid>
          </Grid>
        </DialogActions>
      </AutoSizeDialog>
    </React.Fragment>
  );
}

CompleteOrderDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  orders: PropTypes.array,
};
