import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import { getRestaurantMealsByRestaurantByIsActive } from 'forms/AdminBulkOrderForm/queries';
import { getDefaultMealItem } from 'forms/AdminBulkOrderForm/helpers';
import { getRestaurantIdSchema } from 'forms/schemas';
import { asyncListAll } from 'utilities/graph';
import { getConfigurationByCategoryByStatus } from 'graphql/queries';
import cache from 'utilities/cache';

export default function RestaurantMealSelect({ onUpdate }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [restaurantIdSelected, setRestaurantIdSelected] = useState();
  const [mealItems, setMealItems] = useState([getDefaultMealItem()]);
  const [restaurantMealOptions, setRestaurantMealOptions] = useState({});
  const [orderConfigurations, setOrderConfigurations] = useState([]);

  const getConfigurationValues = (inConfigurationKey) => {
    try {
      const items = orderConfigurations.find(({ key }) => key === inConfigurationKey).value.split(',').map((x) => parseInt(x, 10));
      return items;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const clientId = cache.get('app:facilityId');
      const [restaurantIds, orderConfigurations] = await Promise.all([
        getRestaurantIdSchema(null, null, clientId, false, false),
        asyncListAll(getConfigurationByCategoryByStatus, {
          category: '訂單',
          status: { eq: '使用中' },
        })],
      );
      setOrderConfigurations(orderConfigurations);
      setRestaurantOptions(restaurantIds.enum.map((id, index) => ({
        id,
        name: restaurantIds.enumNames[index],
      })));
      setIsLoading(false);
    })();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          id={'restaurantSelect'}
          sx={{ width: 500 }}
          options={restaurantOptions}
          loading={isLoading}
          onChange={async (event, value = {})=> {
            const newRestaurantId = value.id;
            setRestaurantIdSelected(newRestaurantId);
            const mealItems = [getDefaultMealItem()];
            setMealItems(mealItems);
            if (newRestaurantId && (!restaurantMealOptions[newRestaurantId])) {
              const mealOptions = await asyncListAll(
                getRestaurantMealsByRestaurantByIsActive, { restaurantId: newRestaurantId });
              restaurantMealOptions[newRestaurantId] = mealOptions;
              setRestaurantMealOptions({ ...restaurantMealOptions });
            }
            onUpdate && onUpdate({
              restaurantId: value.id,
              mealItems,
            });
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={t('餐廳')}
              variant="standard"
            />
          )}
        />
      </Grid>
      {mealItems && mealItems.map((mealItem, index) => {
        return (
          <Grid container spacing={1} key={`mealItem-${index}`}>
            <Grid item md={4}>
              <AutoSelect
                id={'mealItem-name'}
                options={restaurantMealOptions[restaurantIdSelected] && restaurantMealOptions[restaurantIdSelected].map(({ name }) => name)}
                values={restaurantMealOptions[restaurantIdSelected] && restaurantMealOptions[restaurantIdSelected].map(({ id }) => id)}
                required={false}
                freeSolo
                value={mealItem.name}
                label={`${t('餐點')}名稱`}
                onChange={(value)=>{
                  const restaruantMeal = restaurantMealOptions[restaurantIdSelected].find(({ id }) => id === value);
                  const name = (restaruantMeal ? restaruantMeal.name : value) || '';
                  const cost = (restaruantMeal ? restaruantMeal.cost : 0) || 0;
                  const price = (restaruantMeal ? restaruantMeal.price : 0) || 0;
                  const surcharges = (restaruantMeal ? restaruantMeal.surcharges : []) || [];
                  mealItems[index] = {
                    name,
                    quantity: mealItems[index].quantity || 1,
                    price,
                    cost,
                    note: '',
                    surcharges,
                  };
                  setMealItems([...mealItems]);
                  onUpdate && onUpdate({ mealItems });
                }}
              />
            </Grid>
            <Grid item md={1}>
              <AutoSelect
                id={`mealItem-quantity`}
                fullWidth
                type="number"
                options={getConfigurationValues('餐點數量選單').map((x) => `${x}`)}
                getOptionLabel={(x) => `${x}`}
                values={getConfigurationValues('餐點數量選單')}
                value={mealItem.quantity}
                required={true}
                label="數量"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                freeSolo={true}
                onChange={(x) => {
                  const value = x ? parseFloat(x) : 0;
                  mealItems[index].quantity = value;
                  setMealItems([...mealItems]);
                  onUpdate && onUpdate({ mealItems });
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </Grid>
            <Grid item md={2}>
              <AutoSelect
                id={`mealItem-cost`}
                fullWidth
                type="number"
                options={getConfigurationValues('餐點進價選單').map((x) => `${x}`)}
                getOptionLabel={(x) => `${x}`}
                values={getConfigurationValues('餐點進價選單')}
                value={mealItem.cost}
                required={true}
                label="進價"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                freeSolo={true}
                onChange={(x) => {
                  const value = x ? parseFloat(x) : 0;
                  mealItems[index].cost = value;
                  setMealItems([...mealItems]);
                  onUpdate && onUpdate({ mealItems });
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </Grid>
            <Grid item md={2}>
              <AutoSelect
                id={`mealItem-price`}
                fullWidth
                type="number"
                options={getConfigurationValues('餐點價格選單').map((x) => `${x}`)}
                getOptionLabel={(x) => `${x}`}
                values={getConfigurationValues('餐點價格選單')}
                value={mealItem.price}
                required={true}
                label="單價"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                freeSolo={true}
                onChange={(x) => {
                  const value = x ? parseFloat(x) : 0;
                  mealItems[index].price = value;
                  setMealItems([...mealItems]);
                  onUpdate && onUpdate({ mealItems });
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </Grid>
            <Grid container item md={3}>
              <Grid item xs>
                <TextField
                  fullWidth
                  type="text"
                  value={mealItem.note}
                  label={'註記'}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) =>{
                    const value = event.target.value;
                    mealItems[index].note = value;
                    setMealItems([...mealItems]);
                    onUpdate && onUpdate({ mealItems });
                  }}
                />
              </Grid>
              {mealItems.length > 1 &&
              <IconButton
                size={'small'}
                style={{
                  width: 40,
                  height: 40,
                }}
                onClick={() => {
                  mealItems.splice(index, 1);
                  setMealItems([...mealItems]);
                  onUpdate && onUpdate({ mealItems });
                }}
              >
                <ClearIcon />
              </IconButton>}
            </Grid>
          </Grid>
        );
      })}

      <Grid container item md={12} justifyContent='center'>
        <Button
          aria-label={'add meal item'}
          size={'small'}
          variant={'text'}
          color={'secondary'}
          style={{ height: '36px' }}
          onClick={() => {
            mealItems.push(getDefaultMealItem());
            setMealItems([...mealItems]);
            onUpdate && onUpdate({ mealItems });
          }}
        >
          <AddIcon fontSize="small" />
          新增餐點
        </Button>
      </Grid>
    </Grid>);
}

RestaurantMealSelect.propTypes = {
  onUpdate: PropTypes.func,
};
