import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { updateFacilitySettings, createFacilitySettings, updateClient } from 'graphql/mutations';
import { request } from 'utilities/graph';
import cache from 'utilities/cache';
import { getClient } from 'graphql/queries';
import moment from 'moment-timezone';
import { toastr } from 'react-redux-toastr';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonGroup: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 24,
  },
  label: {
    fontSize: '0.8rem',
    marginTop: theme.spacing(0.5),
  },
  settingName: {
    marginLeft: 36,
    width: '300px',
  },
}));

export default function MealStatisticsSettingsToolbar({ onUpdate, restaurantMealTagList = [], dirty }) {
  const [mealStatisticsSettingOptions, setMealStatisticsSettingOptions] = useState([]);
  const [settingName, setSettingName] = useState('');
  const [originalSettingName, setOriginalSettingName] = useState('');
  const [client, setClient] = useState();
  const [settingAnchorEl, setSettingAnchorEl] = useState();
  const classes = useStyles();

  const getClientFacilitySettings = async (loadInitSetting=false) => {
    const facilityId = cache.get('app:facilityId');
    if (facilityId) {
      const { data: { getClient: data } } = await request(getClient, { id: facilityId });
      if (data?.facilitySettings?.mealStatisticsSettings) {
        setMealStatisticsSettingOptions(data.facilitySettings.mealStatisticsSettings);
        if (loadInitSetting) {
          const initialSetting = cache.get('app:mealStatistics:setting');
          const settingIndex = data.facilitySettings.mealStatisticsSettings.findIndex((({ name }) => name === initialSetting));
          if (settingIndex !== -1) {
            const setting = data.facilitySettings.mealStatisticsSettings[settingIndex];
            setSettingName(setting.name);
            setOriginalSettingName(setting.name);
            onUpdate && onUpdate(setting.mealStatisticsSettingsItems);
          } else {
            cache.remove('app:mealStatistics:setting');
          }
        }
      }
      setClient(data);
    }
  };

  useEffect(() => {
    (async () => {
      await getClientFacilitySettings(true);
    })();
  }, []);

  const handleNewSetting = () => {
    let newSetting = true;
    if (dirty) {
      if (!window.confirm('有尚未儲存的變更，確定要使用新設定？')) {
        newSetting = false;
      }
    }
    if (newSetting) {
      setSettingName('');
      setOriginalSettingName('');
      cache.remove('app:mealStatistics:setting');
      onUpdate && onUpdate([]);
    }
  };

  const handleOpenSetting = (event) => {
    setSettingAnchorEl(event.currentTarget);
  };

  const onSettingChange = (setting) => {
    let loadSetting = true;
    if (dirty) {
      if (!window.confirm('有尚未儲存的變更，確定要開啟設定？')) {
        loadSetting = false;
      }
    }
    if (loadSetting) {
      setSettingName(setting.name);
      setOriginalSettingName(setting.name);
      cache.set('app:mealStatistics:setting', setting.name);
      onUpdate && onUpdate(setting.mealStatisticsSettingsItems);
    }
  };

  const handleSave = async () => {
    if (!client) return;
    const newMealStatisticsSettings = {
      name: settingName,
      mealStatisticsSettingsItems: restaurantMealTagList,
    };

    const { facilitySettings = {} } = client;
    if (facilitySettings.id) {
      if (facilitySettings.mealStatisticsSettings) {
        const settingIndex = facilitySettings.mealStatisticsSettings.findIndex(({ name }) => name === settingName);
        if (settingIndex === -1) {
          facilitySettings.mealStatisticsSettings.push(newMealStatisticsSettings);
        } else {
          if (!window.confirm(`確定覆蓋${settingName}原有設定？`)) {
            return;
          }
          facilitySettings.mealStatisticsSettings[settingIndex] = newMealStatisticsSettings;
        }
      } else {
        facilitySettings.mealStatisticsSettings = [newMealStatisticsSettings];
      }
      await request(updateFacilitySettings, { input: facilitySettings });
    } else { // TODO: test...
      const now = moment().toISOString();
      const userName = cache.get('app:userName');
      const newFacilitySettings = {
        mealStatisticsSettings: [newMealStatisticsSettings],
        createdAt: now,
        createdBy: userName,
        updatedAt: now,
        updatedBy: userName,
      };
      const { data: { createFacilitySettings: { id: facilitySettingsId } } } =
        await request(createFacilitySettings, { input: newFacilitySettings });
      await request(updateClient, { input: {
        id: client.id,
        type: client.type,
        isActive: client.isActive,
        county: client.county,
        name: client.name,
        address: client.address,
        email: client.email,
        usersLimit: client.usersLimit,
        eldersLimit: client.eldersLimit,
        createdAt: client.createdAt,
        createdBy: client.createdBy,
        updatedAt: now,
        updatedBy: userName,
        facilitySettingsId: facilitySettingsId,
      } });
    }
    await getClientFacilitySettings();
    onUpdate && onUpdate(restaurantMealTagList); // clear dirty status
    toastr.info(`設定${settingName}已儲存`);
    setOriginalSettingName(settingName);
    cache.set('app:mealStatistics:setting', settingName);
  };

  const handleDelete = async () => {
    const { facilitySettings = {} } = client;
    if (facilitySettings.id) {
      if (facilitySettings.mealStatisticsSettings) {
        const settingIndex = facilitySettings.mealStatisticsSettings.findIndex(({ name }) => name === settingName);
        if (settingIndex !== -1) {
          if (window.confirm(`確定刪除設定${settingName}？`)) {
            facilitySettings.mealStatisticsSettings.splice(settingIndex, 1);
            await request(updateFacilitySettings, { input: facilitySettings });
            await getClientFacilitySettings();
            toastr.info(`設定${settingName}已刪除`);
            setSettingName('');
            setOriginalSettingName('');
            cache.remove('app:mealStatistics:setting');
            onUpdate && onUpdate([]); // clear dirty status
          }
        }
      }
    }
  };

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.buttonGroup}>
        <div className={classes.button}>
          <IconButton onClick={handleNewSetting}>
            <CreateNewFolderOutlinedIcon className={classes.icon} />
          </IconButton>
          <Typography className={classes.label}>新設定</Typography>
        </div>
        <div className={classes.button}>
          <IconButton disabled={mealStatisticsSettingOptions?.length === 0} onClick={handleOpenSetting}>
            <FolderOpenIcon className={classes.icon} />
          </IconButton>
          <Typography className={classes.label}>開啟設定</Typography>
        </div>
        <Menu
          id="organization-menu"
          anchorEl={settingAnchorEl}
          keepMounted
          open={Boolean(settingAnchorEl)}
          onClose={() => setSettingAnchorEl(null)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {mealStatisticsSettingOptions.map((item) => {
            return (
              <MenuItem
                key={item.name}
                value={item.name}
                onClick={() => {
                  onSettingChange(item);
                  setSettingAnchorEl(null);
                }}
                disabled={item.name === originalSettingName}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
        <div className={classes.button}>
          <IconButton
            disabled={
              (!dirty && (originalSettingName === settingName)) ||
              settingName === '' ||
              restaurantMealTagList?.length === 0}
            onClick={handleSave}
          >
            <SaveIcon className={classes.icon} />
          </IconButton>
          <Typography className={classes.label}>儲存設定</Typography>
        </div>
        <div className={classes.button}>
          <IconButton onClick={handleDelete} disabled={!mealStatisticsSettingOptions.some(({ name }) => name === settingName)}>
            <DeleteIcon className={classes.icon} />
          </IconButton>
          <Typography className={classes.label}>刪除設定</Typography>
        </div>
      </div>
      <TextField
        className={classes.settingName}
        label='設定名稱'
        type="text"
        value={settingName}
        onChange={(e) => setSettingName(e.target.value)}
        placeholder='請輸入統計設定的名稱'
      />
    </Toolbar>
  );
}

MealStatisticsSettingsToolbar.propTypes = {
  onUpdate: PropTypes.func,
  restaurantMealTagList: PropTypes.array,
  dirty: PropTypes.bool,
};
