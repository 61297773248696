/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminCreateOrder = /* GraphQL */ `
  mutation AdminCreateOrder($input: AdminCreateOrderRequest!) {
    adminCreateOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const adminUpdateOrder = /* GraphQL */ `
  mutation AdminUpdateOrder($input: AdminUpdateOrderRequest!) {
    adminUpdateOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const deliveryStaffUpdateOrder = /* GraphQL */ `
  mutation DeliveryStaffUpdateOrder($input: DeliveryStaffUpdateOrderRequest!) {
    deliveryStaffUpdateOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const clientCreateOrder = /* GraphQL */ `
  mutation ClientCreateOrder($input: ClientCreateOrderRequest!) {
    clientCreateOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const individualClientCreateOrder = /* GraphQL */ `
  mutation IndividualClientCreateOrder(
    $input: IndividualClientCreateOrderRequest!
  ) {
    individualClientCreateOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const placeMultiStopOrder = /* GraphQL */ `
  mutation PlaceMultiStopOrder($input: PlaceMultiStopOrderRequest!) {
    placeMultiStopOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const restaurantOwnerUpdateOrder = /* GraphQL */ `
  mutation RestaurantOwnerUpdateOrder(
    $input: RestaurantOwnerUpdateOrderRequest!
  ) {
    restaurantOwnerUpdateOrder(input: $input) {
      errors
      message
      data {
        id
        status
        elderId
        mealSlot
        deliveryBy
        deliveryStaffFee
      }
    }
  }
`;
export const registerDeliveryStaff = /* GraphQL */ `
  mutation RegisterDeliveryStaff($input: RegisterDeliveryStaffRequest!) {
    registerDeliveryStaff(input: $input) {
      errors
      message
    }
  }
`;
export const registerClient = /* GraphQL */ `
  mutation RegisterClient($input: RegisterClientRequest!) {
    registerClient(input: $input) {
      errors
      message
    }
  }
`;
export const registerFacilityAdmin = /* GraphQL */ `
  mutation RegisterFacilityAdmin($input: RegisterFacilityAdminRequest!) {
    registerFacilityAdmin(input: $input) {
      errors
      message
    }
  }
`;
export const registerRestaurantOwner = /* GraphQL */ `
  mutation RegisterRestaurantOwner($input: RegisterRestaurantOwnerRequest!) {
    registerRestaurantOwner(input: $input) {
      errors
      message
    }
  }
`;
export const checkUser = /* GraphQL */ `
  mutation CheckUser($input: CheckUserRequest!) {
    checkUser(input: $input) {
      name
      county
      deliveryStaffId
      deliveryStaffStatus
      translation
      shouldUploadDocuments
      skipDocumentsUpload
      hideSToken
      supportNumber
      notificationTokenDeliveryStaff
      deviceModelName
      deviceOsVersion
      appVersion
      facilityId
      facilityName
      facilityUseDedicatedDeliveryStaff
      organizationId
      organizationName
      organizationUseDedicatedDeliveryStaff
    }
  }
`;
export const adminUpdateUserGroup = /* GraphQL */ `
  mutation AdminUpdateUserGroup($input: AdminUpdateUserGroupRequest!) {
    adminUpdateUserGroup(input: $input) {
      errors
      message
    }
  }
`;
export const pay = /* GraphQL */ `
  mutation Pay($input: PayRequest!) {
    pay(input: $input) {
      errors
      message
      data
    }
  }
`;
export const sponsor = /* GraphQL */ `
  mutation Sponsor($input: SponsorRequest!) {
    sponsor(input: $input) {
      errors
      message
      data
    }
  }
`;
export const becomeMember = /* GraphQL */ `
  mutation BecomeMember($input: BecomeMemberRequest!) {
    becomeMember(input: $input) {
      errors
      message
      data
    }
  }
`;
export const linePayGetPaymentUrl = /* GraphQL */ `
  mutation LinePayGetPaymentUrl($input: LinePayGetPaymentUrlRequest!) {
    linePayGetPaymentUrl(input: $input) {
      errors
      message
      data
    }
  }
`;
export const linePayConfirmTransaction = /* GraphQL */ `
  mutation LinePayConfirmTransaction(
    $input: LinePayConfirmTransactionRequest!
  ) {
    linePayConfirmTransaction(input: $input) {
      errors
      message
      data
    }
  }
`;
export const newebPayGetTradePostData = /* GraphQL */ `
  mutation NewebPayGetTradePostData($input: NewebPayGetTradePostDataRequest!) {
    newebPayGetTradePostData(input: $input) {
      errors
      message
      data
    }
  }
`;
export const newebPayConfirmTransaction = /* GraphQL */ `
  mutation NewebPayConfirmTransaction(
    $input: NewebPayConfirmTransactionRequest!
  ) {
    newebPayConfirmTransaction(input: $input) {
      errors
      message
      data
    }
  }
`;
export const pandagoAddOutlets = /* GraphQL */ `
  mutation PandagoAddOutlets($input: PandagoAddOutletsRequest!) {
    pandagoAddOutlets(input: $input) {
      errors
      message
      data
    }
  }
`;
export const changeClientId = /* GraphQL */ `
  mutation ChangeClientId($input: ChangeCliendItRequest!) {
    changeClientId(input: $input) {
      errors
      message
      data
    }
  }
`;
export const deliveryStaffGetDementiaQuestionnaire = /* GraphQL */ `
  mutation DeliveryStaffGetDementiaQuestionnaire(
    $input: DeliveryStaffGetDementiaQuestionnaireRequest!
  ) {
    deliveryStaffGetDementiaQuestionnaire(input: $input) {
      errors
      message
      data {
        id
        category
        type
        question
      }
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin(
    $input: CreateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    createAdmin(input: $input, condition: $condition) {
      username
      name
      email
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin(
    $input: UpdateAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    updateAdmin(input: $input, condition: $condition) {
      username
      name
      email
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin(
    $input: DeleteAdminInput!
    $condition: ModelAdminConditionInput
  ) {
    deleteAdmin(input: $input, condition: $condition) {
      username
      name
      email
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createAuditTrail = /* GraphQL */ `
  mutation CreateAuditTrail(
    $input: CreateAuditTrailInput!
    $condition: ModelAuditTrailConditionInput
  ) {
    createAuditTrail(input: $input, condition: $condition) {
      id
      timestamp
      username
      email
      name
      groupName
      action
      note
      organizationId
      facilityId
      clientId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateAuditTrail = /* GraphQL */ `
  mutation UpdateAuditTrail(
    $input: UpdateAuditTrailInput!
    $condition: ModelAuditTrailConditionInput
  ) {
    updateAuditTrail(input: $input, condition: $condition) {
      id
      timestamp
      username
      email
      name
      groupName
      action
      note
      organizationId
      facilityId
      clientId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteAuditTrail = /* GraphQL */ `
  mutation DeleteAuditTrail(
    $input: DeleteAuditTrailInput!
    $condition: ModelAuditTrailConditionInput
  ) {
    deleteAuditTrail(input: $input, condition: $condition) {
      id
      timestamp
      username
      email
      name
      groupName
      action
      note
      organizationId
      facilityId
      clientId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      status
      title
      content
      url
      photoS3Keys
      county
      deliveryStaffId
      deliveryStaffOrderId
      checkListIsComplete
      checkListReminder
      checkListTransfer
      checkListChat
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      status
      title
      content
      url
      photoS3Keys
      county
      deliveryStaffId
      deliveryStaffOrderId
      checkListIsComplete
      checkListReminder
      checkListTransfer
      checkListChat
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      status
      title
      content
      url
      photoS3Keys
      county
      deliveryStaffId
      deliveryStaffOrderId
      checkListIsComplete
      checkListReminder
      checkListTransfer
      checkListChat
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createCard = /* GraphQL */ `
  mutation CreateCard(
    $input: CreateCardInput!
    $condition: ModelCardConditionInput
  ) {
    createCard(input: $input, condition: $condition) {
      id
      username
      lastFour
      expDate
      type
      clientId
      nickname
      phoneNumber
      name
      email
      zipCode
      address
      tpId
      tpKey
      tpToken
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateCard = /* GraphQL */ `
  mutation UpdateCard(
    $input: UpdateCardInput!
    $condition: ModelCardConditionInput
  ) {
    updateCard(input: $input, condition: $condition) {
      id
      username
      lastFour
      expDate
      type
      clientId
      nickname
      phoneNumber
      name
      email
      zipCode
      address
      tpId
      tpKey
      tpToken
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard(
    $input: DeleteCardInput!
    $condition: ModelCardConditionInput
  ) {
    deleteCard(input: $input, condition: $condition) {
      id
      username
      lastFour
      expDate
      type
      clientId
      nickname
      phoneNumber
      name
      email
      zipCode
      address
      tpId
      tpKey
      tpToken
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      shortId
      type
      isActive
      county
      username
      name
      dba
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      phoneNumber2
      phoneNumber3
      email
      contact
      ageGroup
      birthday
      gender
      jobOccupation
      howDoYouKnowUs
      referredBy
      organizationId
      usersLimit
      eldersLimit
      note
      translation
      useDedicatedDeliveryStaffs
      featureConfiguration {
        skipRegisterSteps
        skipDocumentsUpload
        hideSToken
        supportNumber
        useDedicatedDeliveryStaffs
        dementiaQuestionnaire
        reportTypes
        supportedPartners
      }
      basicFee
      basicDistanceInKM
      extraFeeInKM
      extraFee
      platformFee
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      facilitySettingsId
      facilitySettings {
        id
        dba
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        receiptSettings {
          originalPrice
          copaymentSettings {
            disadvantagedType
            disadvantagedLabel
            copayment
          }
        }
        logoS3Keys
        deliverySetting {
          canStartDeliveryBefore
          canStartDeliveryAfter
          canCompleteOrderAfter
          canModifyDiaryAfter
        }
        mealStatisticsSettings {
          name
          mealStatisticsSettingsItems {
            mealItemName
            restaurantId
            restaurantName
            tags
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      orderableRestaurants {
        id
        isPublic
      }
      assignableDeliveryStaffs
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      shortId
      type
      isActive
      county
      username
      name
      dba
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      phoneNumber2
      phoneNumber3
      email
      contact
      ageGroup
      birthday
      gender
      jobOccupation
      howDoYouKnowUs
      referredBy
      organizationId
      usersLimit
      eldersLimit
      note
      translation
      useDedicatedDeliveryStaffs
      featureConfiguration {
        skipRegisterSteps
        skipDocumentsUpload
        hideSToken
        supportNumber
        useDedicatedDeliveryStaffs
        dementiaQuestionnaire
        reportTypes
        supportedPartners
      }
      basicFee
      basicDistanceInKM
      extraFeeInKM
      extraFee
      platformFee
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      facilitySettingsId
      facilitySettings {
        id
        dba
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        receiptSettings {
          originalPrice
          copaymentSettings {
            disadvantagedType
            disadvantagedLabel
            copayment
          }
        }
        logoS3Keys
        deliverySetting {
          canStartDeliveryBefore
          canStartDeliveryAfter
          canCompleteOrderAfter
          canModifyDiaryAfter
        }
        mealStatisticsSettings {
          name
          mealStatisticsSettingsItems {
            mealItemName
            restaurantId
            restaurantName
            tags
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      orderableRestaurants {
        id
        isPublic
      }
      assignableDeliveryStaffs
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      shortId
      type
      isActive
      county
      username
      name
      dba
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      phoneNumber2
      phoneNumber3
      email
      contact
      ageGroup
      birthday
      gender
      jobOccupation
      howDoYouKnowUs
      referredBy
      organizationId
      usersLimit
      eldersLimit
      note
      translation
      useDedicatedDeliveryStaffs
      featureConfiguration {
        skipRegisterSteps
        skipDocumentsUpload
        hideSToken
        supportNumber
        useDedicatedDeliveryStaffs
        dementiaQuestionnaire
        reportTypes
        supportedPartners
      }
      basicFee
      basicDistanceInKM
      extraFeeInKM
      extraFee
      platformFee
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      facilitySettingsId
      facilitySettings {
        id
        dba
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        receiptSettings {
          originalPrice
          copaymentSettings {
            disadvantagedType
            disadvantagedLabel
            copayment
          }
        }
        logoS3Keys
        deliverySetting {
          canStartDeliveryBefore
          canStartDeliveryAfter
          canCompleteOrderAfter
          canModifyDiaryAfter
        }
        mealStatisticsSettings {
          name
          mealStatisticsSettingsItems {
            mealItemName
            restaurantId
            restaurantName
            tags
          }
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      orderableRestaurants {
        id
        isPublic
      }
      assignableDeliveryStaffs
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createClientAppBanner = /* GraphQL */ `
  mutation CreateClientAppBanner(
    $input: CreateClientAppBannerInput!
    $condition: ModelClientAppBannerConditionInput
  ) {
    createClientAppBanner(input: $input, condition: $condition) {
      id
      isActive
      title
      content
      url
      imageUrl
      sortOrder
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClientAppBanner = /* GraphQL */ `
  mutation UpdateClientAppBanner(
    $input: UpdateClientAppBannerInput!
    $condition: ModelClientAppBannerConditionInput
  ) {
    updateClientAppBanner(input: $input, condition: $condition) {
      id
      isActive
      title
      content
      url
      imageUrl
      sortOrder
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteClientAppBanner = /* GraphQL */ `
  mutation DeleteClientAppBanner(
    $input: DeleteClientAppBannerInput!
    $condition: ModelClientAppBannerConditionInput
  ) {
    deleteClientAppBanner(input: $input, condition: $condition) {
      id
      isActive
      title
      content
      url
      imageUrl
      sortOrder
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createClientMonthlyStatement = /* GraphQL */ `
  mutation CreateClientMonthlyStatement(
    $input: CreateClientMonthlyStatementInput!
    $condition: ModelClientMonthlyStatementConditionInput
  ) {
    createClientMonthlyStatement(input: $input, condition: $condition) {
      id
      clientName
      clientId
      county
      month
      completedOrderCount
      incompleteOrderCount
      lunchCount
      incompleteLunchCount
      dinnerCount
      incompleteDinnerCount
      orderCountByIdentities {
        disadvantagedTypes
        serviceCode
        gender
        orderCount
        lunchCount
        dinnerCount
      }
      totalCost
      incompleteTotalCost
      total
      incompleteTotal
      elderStatistics {
        id
        gender
        county
        district
        disadvantagedTypes
        serviceCode
        disabilityLevel
        orderCount
        incompleteOrderCount
        lunchCount
        incompleteLunchCount
        dinnerCount
        incompleteDinnerCount
      }
      deliveryStaffIds
      deliveryStaffFee
      deliveryPlatformCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClientMonthlyStatement = /* GraphQL */ `
  mutation UpdateClientMonthlyStatement(
    $input: UpdateClientMonthlyStatementInput!
    $condition: ModelClientMonthlyStatementConditionInput
  ) {
    updateClientMonthlyStatement(input: $input, condition: $condition) {
      id
      clientName
      clientId
      county
      month
      completedOrderCount
      incompleteOrderCount
      lunchCount
      incompleteLunchCount
      dinnerCount
      incompleteDinnerCount
      orderCountByIdentities {
        disadvantagedTypes
        serviceCode
        gender
        orderCount
        lunchCount
        dinnerCount
      }
      totalCost
      incompleteTotalCost
      total
      incompleteTotal
      elderStatistics {
        id
        gender
        county
        district
        disadvantagedTypes
        serviceCode
        disabilityLevel
        orderCount
        incompleteOrderCount
        lunchCount
        incompleteLunchCount
        dinnerCount
        incompleteDinnerCount
      }
      deliveryStaffIds
      deliveryStaffFee
      deliveryPlatformCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteClientMonthlyStatement = /* GraphQL */ `
  mutation DeleteClientMonthlyStatement(
    $input: DeleteClientMonthlyStatementInput!
    $condition: ModelClientMonthlyStatementConditionInput
  ) {
    deleteClientMonthlyStatement(input: $input, condition: $condition) {
      id
      clientName
      clientId
      county
      month
      completedOrderCount
      incompleteOrderCount
      lunchCount
      incompleteLunchCount
      dinnerCount
      incompleteDinnerCount
      orderCountByIdentities {
        disadvantagedTypes
        serviceCode
        gender
        orderCount
        lunchCount
        dinnerCount
      }
      totalCost
      incompleteTotalCost
      total
      incompleteTotal
      elderStatistics {
        id
        gender
        county
        district
        disadvantagedTypes
        serviceCode
        disabilityLevel
        orderCount
        incompleteOrderCount
        lunchCount
        incompleteLunchCount
        dinnerCount
        incompleteDinnerCount
      }
      deliveryStaffIds
      deliveryStaffFee
      deliveryPlatformCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createClientOrganization = /* GraphQL */ `
  mutation CreateClientOrganization(
    $input: CreateClientOrganizationInput!
    $condition: ModelClientOrganizationConditionInput
  ) {
    createClientOrganization(input: $input, condition: $condition) {
      id
      organizationId
      clientId
      visitorMode
      clientMode
      elderSetting
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClientOrganization = /* GraphQL */ `
  mutation UpdateClientOrganization(
    $input: UpdateClientOrganizationInput!
    $condition: ModelClientOrganizationConditionInput
  ) {
    updateClientOrganization(input: $input, condition: $condition) {
      id
      organizationId
      clientId
      visitorMode
      clientMode
      elderSetting
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteClientOrganization = /* GraphQL */ `
  mutation DeleteClientOrganization(
    $input: DeleteClientOrganizationInput!
    $condition: ModelClientOrganizationConditionInput
  ) {
    deleteClientOrganization(input: $input, condition: $condition) {
      id
      organizationId
      clientId
      visitorMode
      clientMode
      elderSetting
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createClientUser = /* GraphQL */ `
  mutation CreateClientUser(
    $input: CreateClientUserInput!
    $condition: ModelClientUserConditionInput
  ) {
    createClientUser(input: $input, condition: $condition) {
      clientId
      username
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateClientUser = /* GraphQL */ `
  mutation UpdateClientUser(
    $input: UpdateClientUserInput!
    $condition: ModelClientUserConditionInput
  ) {
    updateClientUser(input: $input, condition: $condition) {
      clientId
      username
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteClientUser = /* GraphQL */ `
  mutation DeleteClientUser(
    $input: DeleteClientUserInput!
    $condition: ModelClientUserConditionInput
  ) {
    deleteClientUser(input: $input, condition: $condition) {
      clientId
      username
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      id
      status
      category
      key
      value
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      id
      status
      category
      key
      value
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      id
      status
      category
      key
      value
      description
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDataMigration = /* GraphQL */ `
  mutation CreateDataMigration(
    $input: CreateDataMigrationInput!
    $condition: ModelDataMigrationConditionInput
  ) {
    createDataMigration(input: $input, condition: $condition) {
      name
      notes
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDataMigration = /* GraphQL */ `
  mutation UpdateDataMigration(
    $input: UpdateDataMigrationInput!
    $condition: ModelDataMigrationConditionInput
  ) {
    updateDataMigration(input: $input, condition: $condition) {
      name
      notes
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDataMigration = /* GraphQL */ `
  mutation DeleteDataMigration(
    $input: DeleteDataMigrationInput!
    $condition: ModelDataMigrationConditionInput
  ) {
    deleteDataMigration(input: $input, condition: $condition) {
      name
      notes
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeleteMyAccountRequest = /* GraphQL */ `
  mutation CreateDeleteMyAccountRequest(
    $input: CreateDeleteMyAccountRequestInput!
    $condition: ModelDeleteMyAccountRequestConditionInput
  ) {
    createDeleteMyAccountRequest(input: $input, condition: $condition) {
      id
      username
      appName
      requestedAt
      status
      reason
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeleteMyAccountRequest = /* GraphQL */ `
  mutation UpdateDeleteMyAccountRequest(
    $input: UpdateDeleteMyAccountRequestInput!
    $condition: ModelDeleteMyAccountRequestConditionInput
  ) {
    updateDeleteMyAccountRequest(input: $input, condition: $condition) {
      id
      username
      appName
      requestedAt
      status
      reason
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeleteMyAccountRequest = /* GraphQL */ `
  mutation DeleteDeleteMyAccountRequest(
    $input: DeleteDeleteMyAccountRequestInput!
    $condition: ModelDeleteMyAccountRequestConditionInput
  ) {
    deleteDeleteMyAccountRequest(input: $input, condition: $condition) {
      id
      username
      appName
      requestedAt
      status
      reason
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeliveryGroup = /* GraphQL */ `
  mutation CreateDeliveryGroup(
    $input: CreateDeliveryGroupInput!
    $condition: ModelDeliveryGroupConditionInput
  ) {
    createDeliveryGroup(input: $input, condition: $condition) {
      id
      clientId
      isActive
      name
      description
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeliveryGroup = /* GraphQL */ `
  mutation UpdateDeliveryGroup(
    $input: UpdateDeliveryGroupInput!
    $condition: ModelDeliveryGroupConditionInput
  ) {
    updateDeliveryGroup(input: $input, condition: $condition) {
      id
      clientId
      isActive
      name
      description
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeliveryGroup = /* GraphQL */ `
  mutation DeleteDeliveryGroup(
    $input: DeleteDeliveryGroupInput!
    $condition: ModelDeliveryGroupConditionInput
  ) {
    deleteDeliveryGroup(input: $input, condition: $condition) {
      id
      clientId
      isActive
      name
      description
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeliveryStaff = /* GraphQL */ `
  mutation CreateDeliveryStaff(
    $input: CreateDeliveryStaffInput!
    $condition: ModelDeliveryStaffConditionInput
  ) {
    createDeliveryStaff(input: $input, condition: $condition) {
      id
      username
      isActive
      county
      clientId
      name
      nickname
      identificationCardId
      birthday
      gender
      phoneNumber
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      jobOccupation
      bankAccount {
        name
        number
      }
      whyDoYouWantToJoin
      experience
      selfInfroduction
      howDoYouKnowUs
      deliveryArea {
        county
        district
        street
        zipCode
        lat
        lng
        rangeInKilometer
      }
      documents {
        name
        s3Key
        uploadedAt
        note
      }
      interestedCounties
      tier
      balance
      totalEarnedSToken
      sTokenLastMonth
      completedOrderCount
      sTokenReportedAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeliveryStaff = /* GraphQL */ `
  mutation UpdateDeliveryStaff(
    $input: UpdateDeliveryStaffInput!
    $condition: ModelDeliveryStaffConditionInput
  ) {
    updateDeliveryStaff(input: $input, condition: $condition) {
      id
      username
      isActive
      county
      clientId
      name
      nickname
      identificationCardId
      birthday
      gender
      phoneNumber
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      jobOccupation
      bankAccount {
        name
        number
      }
      whyDoYouWantToJoin
      experience
      selfInfroduction
      howDoYouKnowUs
      deliveryArea {
        county
        district
        street
        zipCode
        lat
        lng
        rangeInKilometer
      }
      documents {
        name
        s3Key
        uploadedAt
        note
      }
      interestedCounties
      tier
      balance
      totalEarnedSToken
      sTokenLastMonth
      completedOrderCount
      sTokenReportedAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeliveryStaff = /* GraphQL */ `
  mutation DeleteDeliveryStaff(
    $input: DeleteDeliveryStaffInput!
    $condition: ModelDeliveryStaffConditionInput
  ) {
    deleteDeliveryStaff(input: $input, condition: $condition) {
      id
      username
      isActive
      county
      clientId
      name
      nickname
      identificationCardId
      birthday
      gender
      phoneNumber
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      jobOccupation
      bankAccount {
        name
        number
      }
      whyDoYouWantToJoin
      experience
      selfInfroduction
      howDoYouKnowUs
      deliveryArea {
        county
        district
        street
        zipCode
        lat
        lng
        rangeInKilometer
      }
      documents {
        name
        s3Key
        uploadedAt
        note
      }
      interestedCounties
      tier
      balance
      totalEarnedSToken
      sTokenLastMonth
      completedOrderCount
      sTokenReportedAt
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeliveryStaffMonthlyStatement = /* GraphQL */ `
  mutation CreateDeliveryStaffMonthlyStatement(
    $input: CreateDeliveryStaffMonthlyStatementInput!
    $condition: ModelDeliveryStaffMonthlyStatementConditionInput
  ) {
    createDeliveryStaffMonthlyStatement(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      month
      earnedSToken
      completedOrderCount
      completedOrderDistanceInMeters
      completedOrderDurationInSeconds
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeliveryStaffMonthlyStatement = /* GraphQL */ `
  mutation UpdateDeliveryStaffMonthlyStatement(
    $input: UpdateDeliveryStaffMonthlyStatementInput!
    $condition: ModelDeliveryStaffMonthlyStatementConditionInput
  ) {
    updateDeliveryStaffMonthlyStatement(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      month
      earnedSToken
      completedOrderCount
      completedOrderDistanceInMeters
      completedOrderDurationInSeconds
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeliveryStaffMonthlyStatement = /* GraphQL */ `
  mutation DeleteDeliveryStaffMonthlyStatement(
    $input: DeleteDeliveryStaffMonthlyStatementInput!
    $condition: ModelDeliveryStaffMonthlyStatementConditionInput
  ) {
    deleteDeliveryStaffMonthlyStatement(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      month
      earnedSToken
      completedOrderCount
      completedOrderDistanceInMeters
      completedOrderDurationInSeconds
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeliveryStaffOrder = /* GraphQL */ `
  mutation CreateDeliveryStaffOrder(
    $input: CreateDeliveryStaffOrderInput!
    $condition: ModelDeliveryStaffOrderConditionInput
  ) {
    createDeliveryStaffOrder(input: $input, condition: $condition) {
      id
      deliveryStaffId
      orderId
      clientId
      elderId
      status
      county
      category
      deliveryBy
      mealSlot
      distanceInKilometer
      timeInMilliseconds
      deliveryAcceptedAt
      deliveryStartedAt
      deliveryDeliveredAt
      deliveryCompletedAt
      deliveryAbandonedAt
      deliveryAbandonedReason
      deliveryPosition {
        accuracy
        altitude
        altitudeAccuracy
        heading
        latitude
        longitude
        speed
      }
      deliveryPositionUpdatedAt
      deliveryStaffFee
      diary
      checkListIsComplete
      deliveryResult
      checkListReminder
      checkListTransfer
      checkListChat
      photoS3Keys
      notes
      favorite
      hasIssue
      isPublished
      publishedUrl
      blogId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elderDementiaSurvey {
        items {
          id
          elderId
          clientId
          deliveryStaffOrderId
          questionId
          response
          date
          createdAt
          createdBy
          updatedAt
          updatedBy
          dementiaQuestionnaire {
            items {
              id
              isActive
              category
              type
              question
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        nextToken
      }
      issue {
        items {
          id
          priority
          status
          reportedBy
          county
          type
          summary
          description
          note
          assignee
          orderId
          elderId
          clientId
          deliveryStaffId
          deliveryStaffOrderId
          expirationUnixTime
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeliveryStaffOrder = /* GraphQL */ `
  mutation UpdateDeliveryStaffOrder(
    $input: UpdateDeliveryStaffOrderInput!
    $condition: ModelDeliveryStaffOrderConditionInput
  ) {
    updateDeliveryStaffOrder(input: $input, condition: $condition) {
      id
      deliveryStaffId
      orderId
      clientId
      elderId
      status
      county
      category
      deliveryBy
      mealSlot
      distanceInKilometer
      timeInMilliseconds
      deliveryAcceptedAt
      deliveryStartedAt
      deliveryDeliveredAt
      deliveryCompletedAt
      deliveryAbandonedAt
      deliveryAbandonedReason
      deliveryPosition {
        accuracy
        altitude
        altitudeAccuracy
        heading
        latitude
        longitude
        speed
      }
      deliveryPositionUpdatedAt
      deliveryStaffFee
      diary
      checkListIsComplete
      deliveryResult
      checkListReminder
      checkListTransfer
      checkListChat
      photoS3Keys
      notes
      favorite
      hasIssue
      isPublished
      publishedUrl
      blogId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elderDementiaSurvey {
        items {
          id
          elderId
          clientId
          deliveryStaffOrderId
          questionId
          response
          date
          createdAt
          createdBy
          updatedAt
          updatedBy
          dementiaQuestionnaire {
            items {
              id
              isActive
              category
              type
              question
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        nextToken
      }
      issue {
        items {
          id
          priority
          status
          reportedBy
          county
          type
          summary
          description
          note
          assignee
          orderId
          elderId
          clientId
          deliveryStaffId
          deliveryStaffOrderId
          expirationUnixTime
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeliveryStaffOrder = /* GraphQL */ `
  mutation DeleteDeliveryStaffOrder(
    $input: DeleteDeliveryStaffOrderInput!
    $condition: ModelDeliveryStaffOrderConditionInput
  ) {
    deleteDeliveryStaffOrder(input: $input, condition: $condition) {
      id
      deliveryStaffId
      orderId
      clientId
      elderId
      status
      county
      category
      deliveryBy
      mealSlot
      distanceInKilometer
      timeInMilliseconds
      deliveryAcceptedAt
      deliveryStartedAt
      deliveryDeliveredAt
      deliveryCompletedAt
      deliveryAbandonedAt
      deliveryAbandonedReason
      deliveryPosition {
        accuracy
        altitude
        altitudeAccuracy
        heading
        latitude
        longitude
        speed
      }
      deliveryPositionUpdatedAt
      deliveryStaffFee
      diary
      checkListIsComplete
      deliveryResult
      checkListReminder
      checkListTransfer
      checkListChat
      photoS3Keys
      notes
      favorite
      hasIssue
      isPublished
      publishedUrl
      blogId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elderDementiaSurvey {
        items {
          id
          elderId
          clientId
          deliveryStaffOrderId
          questionId
          response
          date
          createdAt
          createdBy
          updatedAt
          updatedBy
          dementiaQuestionnaire {
            items {
              id
              isActive
              category
              type
              question
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            nextToken
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
        }
        nextToken
      }
      issue {
        items {
          id
          priority
          status
          reportedBy
          county
          type
          summary
          description
          note
          assignee
          orderId
          elderId
          clientId
          deliveryStaffId
          deliveryStaffOrderId
          expirationUnixTime
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeliveryStaffStatement = /* GraphQL */ `
  mutation CreateDeliveryStaffStatement(
    $input: CreateDeliveryStaffStatementInput!
    $condition: ModelDeliveryStaffStatementConditionInput
  ) {
    createDeliveryStaffStatement(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      type
      date
      balance
      totalEarnedSToken
      totalCompletedOrderCount
      earnedSToken
      completedOrderCount
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeliveryStaffStatement = /* GraphQL */ `
  mutation UpdateDeliveryStaffStatement(
    $input: UpdateDeliveryStaffStatementInput!
    $condition: ModelDeliveryStaffStatementConditionInput
  ) {
    updateDeliveryStaffStatement(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      type
      date
      balance
      totalEarnedSToken
      totalCompletedOrderCount
      earnedSToken
      completedOrderCount
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeliveryStaffStatement = /* GraphQL */ `
  mutation DeleteDeliveryStaffStatement(
    $input: DeleteDeliveryStaffStatementInput!
    $condition: ModelDeliveryStaffStatementConditionInput
  ) {
    deleteDeliveryStaffStatement(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      type
      date
      balance
      totalEarnedSToken
      totalCompletedOrderCount
      earnedSToken
      completedOrderCount
      incompleteOrderCount
      abandonedOrderCount
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDeliveryStaffTransaction = /* GraphQL */ `
  mutation CreateDeliveryStaffTransaction(
    $input: CreateDeliveryStaffTransactionInput!
    $condition: ModelDeliveryStaffTransactionConditionInput
  ) {
    createDeliveryStaffTransaction(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      status
      type
      amount
      summary
      note
      orderId
      deliveryStaffOrderId
      deliveryStaffMonthlyStatmentId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDeliveryStaffTransaction = /* GraphQL */ `
  mutation UpdateDeliveryStaffTransaction(
    $input: UpdateDeliveryStaffTransactionInput!
    $condition: ModelDeliveryStaffTransactionConditionInput
  ) {
    updateDeliveryStaffTransaction(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      status
      type
      amount
      summary
      note
      orderId
      deliveryStaffOrderId
      deliveryStaffMonthlyStatmentId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDeliveryStaffTransaction = /* GraphQL */ `
  mutation DeleteDeliveryStaffTransaction(
    $input: DeleteDeliveryStaffTransactionInput!
    $condition: ModelDeliveryStaffTransactionConditionInput
  ) {
    deleteDeliveryStaffTransaction(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      status
      type
      amount
      summary
      note
      orderId
      deliveryStaffOrderId
      deliveryStaffMonthlyStatmentId
      deliveryStaff {
        id
        username
        isActive
        county
        clientId
        name
        nickname
        identificationCardId
        birthday
        gender
        phoneNumber
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        jobOccupation
        bankAccount {
          name
          number
        }
        whyDoYouWantToJoin
        experience
        selfInfroduction
        howDoYouKnowUs
        deliveryArea {
          county
          district
          street
          zipCode
          lat
          lng
          rangeInKilometer
        }
        documents {
          name
          s3Key
          uploadedAt
          note
        }
        interestedCounties
        tier
        balance
        totalEarnedSToken
        sTokenLastMonth
        completedOrderCount
        sTokenReportedAt
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDementiaQuestionnaire = /* GraphQL */ `
  mutation CreateDementiaQuestionnaire(
    $input: CreateDementiaQuestionnaireInput!
    $condition: ModelDementiaQuestionnaireConditionInput
  ) {
    createDementiaQuestionnaire(input: $input, condition: $condition) {
      id
      isActive
      category
      type
      question
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDementiaQuestionnaire = /* GraphQL */ `
  mutation UpdateDementiaQuestionnaire(
    $input: UpdateDementiaQuestionnaireInput!
    $condition: ModelDementiaQuestionnaireConditionInput
  ) {
    updateDementiaQuestionnaire(input: $input, condition: $condition) {
      id
      isActive
      category
      type
      question
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDementiaQuestionnaire = /* GraphQL */ `
  mutation DeleteDementiaQuestionnaire(
    $input: DeleteDementiaQuestionnaireInput!
    $condition: ModelDementiaQuestionnaireConditionInput
  ) {
    deleteDementiaQuestionnaire(input: $input, condition: $condition) {
      id
      isActive
      category
      type
      question
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createDirectionCache = /* GraphQL */ `
  mutation CreateDirectionCache(
    $input: CreateDirectionCacheInput!
    $condition: ModelDirectionCacheConditionInput
  ) {
    createDirectionCache(input: $input, condition: $condition) {
      id
      startAddress
      endAddress
      startCoordinates
      endCoordinates
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateDirectionCache = /* GraphQL */ `
  mutation UpdateDirectionCache(
    $input: UpdateDirectionCacheInput!
    $condition: ModelDirectionCacheConditionInput
  ) {
    updateDirectionCache(input: $input, condition: $condition) {
      id
      startAddress
      endAddress
      startCoordinates
      endCoordinates
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteDirectionCache = /* GraphQL */ `
  mutation DeleteDirectionCache(
    $input: DeleteDirectionCacheInput!
    $condition: ModelDirectionCacheConditionInput
  ) {
    deleteDirectionCache(input: $input, condition: $condition) {
      id
      startAddress
      endAddress
      startCoordinates
      endCoordinates
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createElder = /* GraphQL */ `
  mutation CreateElder(
    $input: CreateElderInput!
    $condition: ModelElderConditionInput
  ) {
    createElder(input: $input, condition: $condition) {
      id
      username
      status
      clientId
      county
      deliveryGroupId
      sortOrder
      name
      nickname
      birthday
      gender
      phoneNumber
      phoneNumber2
      phoneNumber3
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      identificationCardId
      serviceCode
      noteForDelivery
      noteForMeal
      noteForHealth
      note
      noteOther
      preferDeliveryTimes
      isAllowedToEnterHome
      otherSocialServices
      isDisadvantaged
      relationshipToClient
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      govQualifiedCount
      govQualifiedPrice
      supervisorId
      linkGroupName
      tags {
        items {
          id
          elderId
          tagId
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
      outOfPocket
      feeCollectMethod
      disadvantagedTypes
      disadvantagedTypesHistory {
        approvedDate
        disadvantagedTypes
      }
      disabilityLevel
      cmsLevel
      caseNumber
      lunchRepeatOn
      dinnerRepeatOn
      fixedDeliveryStaffFee
      onTimeDelivery
      dementiaQuestionnaire
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const updateElder = /* GraphQL */ `
  mutation UpdateElder(
    $input: UpdateElderInput!
    $condition: ModelElderConditionInput
  ) {
    updateElder(input: $input, condition: $condition) {
      id
      username
      status
      clientId
      county
      deliveryGroupId
      sortOrder
      name
      nickname
      birthday
      gender
      phoneNumber
      phoneNumber2
      phoneNumber3
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      identificationCardId
      serviceCode
      noteForDelivery
      noteForMeal
      noteForHealth
      note
      noteOther
      preferDeliveryTimes
      isAllowedToEnterHome
      otherSocialServices
      isDisadvantaged
      relationshipToClient
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      govQualifiedCount
      govQualifiedPrice
      supervisorId
      linkGroupName
      tags {
        items {
          id
          elderId
          tagId
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
      outOfPocket
      feeCollectMethod
      disadvantagedTypes
      disadvantagedTypesHistory {
        approvedDate
        disadvantagedTypes
      }
      disabilityLevel
      cmsLevel
      caseNumber
      lunchRepeatOn
      dinnerRepeatOn
      fixedDeliveryStaffFee
      onTimeDelivery
      dementiaQuestionnaire
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const deleteElder = /* GraphQL */ `
  mutation DeleteElder(
    $input: DeleteElderInput!
    $condition: ModelElderConditionInput
  ) {
    deleteElder(input: $input, condition: $condition) {
      id
      username
      status
      clientId
      county
      deliveryGroupId
      sortOrder
      name
      nickname
      birthday
      gender
      phoneNumber
      phoneNumber2
      phoneNumber3
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      email
      identificationCardId
      serviceCode
      noteForDelivery
      noteForMeal
      noteForHealth
      note
      noteOther
      preferDeliveryTimes
      isAllowedToEnterHome
      otherSocialServices
      isDisadvantaged
      relationshipToClient
      emergencyContact {
        name
        relationship
        phoneNumber
        phoneNumber2
      }
      govQualifiedCount
      govQualifiedPrice
      supervisorId
      linkGroupName
      tags {
        items {
          id
          elderId
          tagId
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
      outOfPocket
      feeCollectMethod
      disadvantagedTypes
      disadvantagedTypesHistory {
        approvedDate
        disadvantagedTypes
      }
      disabilityLevel
      cmsLevel
      caseNumber
      lunchRepeatOn
      dinnerRepeatOn
      fixedDeliveryStaffFee
      onTimeDelivery
      dementiaQuestionnaire
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createElderDeliveryGroup = /* GraphQL */ `
  mutation CreateElderDeliveryGroup(
    $input: CreateElderDeliveryGroupInput!
    $condition: ModelElderDeliveryGroupConditionInput
  ) {
    createElderDeliveryGroup(input: $input, condition: $condition) {
      id
      elderId
      deliveryGroupId
      clientId
      status
      sortOrder
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateElderDeliveryGroup = /* GraphQL */ `
  mutation UpdateElderDeliveryGroup(
    $input: UpdateElderDeliveryGroupInput!
    $condition: ModelElderDeliveryGroupConditionInput
  ) {
    updateElderDeliveryGroup(input: $input, condition: $condition) {
      id
      elderId
      deliveryGroupId
      clientId
      status
      sortOrder
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteElderDeliveryGroup = /* GraphQL */ `
  mutation DeleteElderDeliveryGroup(
    $input: DeleteElderDeliveryGroupInput!
    $condition: ModelElderDeliveryGroupConditionInput
  ) {
    deleteElderDeliveryGroup(input: $input, condition: $condition) {
      id
      elderId
      deliveryGroupId
      clientId
      status
      sortOrder
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createElderDementiaSurvey = /* GraphQL */ `
  mutation CreateElderDementiaSurvey(
    $input: CreateElderDementiaSurveyInput!
    $condition: ModelElderDementiaSurveyConditionInput
  ) {
    createElderDementiaSurvey(input: $input, condition: $condition) {
      id
      elderId
      clientId
      deliveryStaffOrderId
      questionId
      response
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
      dementiaQuestionnaire {
        items {
          id
          isActive
          category
          type
          question
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`;
export const updateElderDementiaSurvey = /* GraphQL */ `
  mutation UpdateElderDementiaSurvey(
    $input: UpdateElderDementiaSurveyInput!
    $condition: ModelElderDementiaSurveyConditionInput
  ) {
    updateElderDementiaSurvey(input: $input, condition: $condition) {
      id
      elderId
      clientId
      deliveryStaffOrderId
      questionId
      response
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
      dementiaQuestionnaire {
        items {
          id
          isActive
          category
          type
          question
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`;
export const deleteElderDementiaSurvey = /* GraphQL */ `
  mutation DeleteElderDementiaSurvey(
    $input: DeleteElderDementiaSurveyInput!
    $condition: ModelElderDementiaSurveyConditionInput
  ) {
    deleteElderDementiaSurvey(input: $input, condition: $condition) {
      id
      elderId
      clientId
      deliveryStaffOrderId
      questionId
      response
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
      dementiaQuestionnaire {
        items {
          id
          isActive
          category
          type
          question
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
  }
`;
export const createElderTag = /* GraphQL */ `
  mutation CreateElderTag(
    $input: CreateElderTagInput!
    $condition: ModelElderTagConditionInput
  ) {
    createElderTag(input: $input, condition: $condition) {
      id
      elderId
      tagId
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const updateElderTag = /* GraphQL */ `
  mutation UpdateElderTag(
    $input: UpdateElderTagInput!
    $condition: ModelElderTagConditionInput
  ) {
    updateElderTag(input: $input, condition: $condition) {
      id
      elderId
      tagId
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const deleteElderTag = /* GraphQL */ `
  mutation DeleteElderTag(
    $input: DeleteElderTagInput!
    $condition: ModelElderTagConditionInput
  ) {
    deleteElderTag(input: $input, condition: $condition) {
      id
      elderId
      tagId
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      key
      timestamp
      tableName
      clientId
      eventId
      eventName
      diff
      oldData
      newData
      note
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      key
      timestamp
      tableName
      clientId
      eventId
      eventName
      diff
      oldData
      newData
      note
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      key
      timestamp
      tableName
      clientId
      eventId
      eventName
      diff
      oldData
      newData
      note
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createFacilityIndividual = /* GraphQL */ `
  mutation CreateFacilityIndividual(
    $input: CreateFacilityIndividualInput!
    $condition: ModelFacilityIndividualConditionInput
  ) {
    createFacilityIndividual(input: $input, condition: $condition) {
      id
      individualId
      facilityId
      individualClient {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateFacilityIndividual = /* GraphQL */ `
  mutation UpdateFacilityIndividual(
    $input: UpdateFacilityIndividualInput!
    $condition: ModelFacilityIndividualConditionInput
  ) {
    updateFacilityIndividual(input: $input, condition: $condition) {
      id
      individualId
      facilityId
      individualClient {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteFacilityIndividual = /* GraphQL */ `
  mutation DeleteFacilityIndividual(
    $input: DeleteFacilityIndividualInput!
    $condition: ModelFacilityIndividualConditionInput
  ) {
    deleteFacilityIndividual(input: $input, condition: $condition) {
      id
      individualId
      facilityId
      individualClient {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createFacilitySettings = /* GraphQL */ `
  mutation CreateFacilitySettings(
    $input: CreateFacilitySettingsInput!
    $condition: ModelFacilitySettingsConditionInput
  ) {
    createFacilitySettings(input: $input, condition: $condition) {
      id
      dba
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      receiptSettings {
        originalPrice
        copaymentSettings {
          disadvantagedType
          disadvantagedLabel
          copayment
        }
      }
      logoS3Keys
      deliverySetting {
        canStartDeliveryBefore
        canStartDeliveryAfter
        canCompleteOrderAfter
        canModifyDiaryAfter
      }
      mealStatisticsSettings {
        name
        mealStatisticsSettingsItems {
          mealItemName
          restaurantId
          restaurantName
          tags
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateFacilitySettings = /* GraphQL */ `
  mutation UpdateFacilitySettings(
    $input: UpdateFacilitySettingsInput!
    $condition: ModelFacilitySettingsConditionInput
  ) {
    updateFacilitySettings(input: $input, condition: $condition) {
      id
      dba
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      receiptSettings {
        originalPrice
        copaymentSettings {
          disadvantagedType
          disadvantagedLabel
          copayment
        }
      }
      logoS3Keys
      deliverySetting {
        canStartDeliveryBefore
        canStartDeliveryAfter
        canCompleteOrderAfter
        canModifyDiaryAfter
      }
      mealStatisticsSettings {
        name
        mealStatisticsSettingsItems {
          mealItemName
          restaurantId
          restaurantName
          tags
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteFacilitySettings = /* GraphQL */ `
  mutation DeleteFacilitySettings(
    $input: DeleteFacilitySettingsInput!
    $condition: ModelFacilitySettingsConditionInput
  ) {
    deleteFacilitySettings(input: $input, condition: $condition) {
      id
      dba
      govQualifiedPrice
      lunchStartTime
      lunchEndTime
      dinnerStartTime
      dinnerEndTime
      receiptInfo {
        receiptType
        taxIdNumber
        faxNumber
        bankName
        accountNumber
        accountName
        note
        managerTitle
        reverseCopyType
      }
      receiptSettings {
        originalPrice
        copaymentSettings {
          disadvantagedType
          disadvantagedLabel
          copayment
        }
      }
      logoS3Keys
      deliverySetting {
        canStartDeliveryBefore
        canStartDeliveryAfter
        canCompleteOrderAfter
        canModifyDiaryAfter
      }
      mealStatisticsSettings {
        name
        mealStatisticsSettingsItems {
          mealItemName
          restaurantId
          restaurantName
          tags
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createILinkOrder = /* GraphQL */ `
  mutation CreateILinkOrder(
    $input: CreateILinkOrderInput!
    $condition: ModelILinkOrderConditionInput
  ) {
    createILinkOrder(input: $input, condition: $condition) {
      id
      iLinkQuoteId
      iLinkOrderId
      iLinkCancelCode
      status
      description
      fee
      feeMessage
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToILinkAt
      iLinkQuotePayload
      iLinkOrderPayload
      orderId
      elderId
      restaurantId
      clientId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateILinkOrder = /* GraphQL */ `
  mutation UpdateILinkOrder(
    $input: UpdateILinkOrderInput!
    $condition: ModelILinkOrderConditionInput
  ) {
    updateILinkOrder(input: $input, condition: $condition) {
      id
      iLinkQuoteId
      iLinkOrderId
      iLinkCancelCode
      status
      description
      fee
      feeMessage
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToILinkAt
      iLinkQuotePayload
      iLinkOrderPayload
      orderId
      elderId
      restaurantId
      clientId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteILinkOrder = /* GraphQL */ `
  mutation DeleteILinkOrder(
    $input: DeleteILinkOrderInput!
    $condition: ModelILinkOrderConditionInput
  ) {
    deleteILinkOrder(input: $input, condition: $condition) {
      id
      iLinkQuoteId
      iLinkOrderId
      iLinkCancelCode
      status
      description
      fee
      feeMessage
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToILinkAt
      iLinkQuotePayload
      iLinkOrderPayload
      orderId
      elderId
      restaurantId
      clientId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      username
      transactionId
      orderId
      status
      clientId
      deliveryStaffId
      restaurantOwnerId
      type
      email
      carrier
      title
      taxIdNumber
      code
      note
      Transaction {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sentAt
      sentBy
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      username
      transactionId
      orderId
      status
      clientId
      deliveryStaffId
      restaurantOwnerId
      type
      email
      carrier
      title
      taxIdNumber
      code
      note
      Transaction {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sentAt
      sentBy
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      username
      transactionId
      orderId
      status
      clientId
      deliveryStaffId
      restaurantOwnerId
      type
      email
      carrier
      title
      taxIdNumber
      code
      note
      Transaction {
        id
        username
        clientId
        deliveryStaffId
        restaurantOwnerId
        orderId
        orders {
          items {
            id
            clientId
            individualId
            restaurantId
            elderId
            date
            mealSlot
            deliveryBy
            status
            county
            countyOpenStatus
            isTemporarilyLocked
            category
            transactionId
            deliveryGroupId
            deliveryGroupSortOrder
            source
            orderQuoteId
            isAudited
            nextStatusCheckAt
            issuePriority
            issueSummary
            deliveryStaffId
            deliveryStaffOrderId
            deliveryStaffFee
            deliveryStaffFeeCalculationMethod
            tier
            tierExpiredAt
            noteForDelivery
            noteForMeal
            note
            mealItems {
              name
              quantity
              price
              cost
              note
            }
            totalCost
            total
            quantity
            subtotal
            discount
            discountCode
            discountDetails
            extraFee
            platformFee
            paymentMethod
            direction {
              startAddress
              endAddress
              distance
              duration
              distanceInMeters
              durationInSeconds
              overviewPolyline
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderGroupId
            referenceId
            linkGroupElderIds
            cancellationReason
            cancellationNote
            directionServiceProvider
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        status
        statusMessage
        type
        description
        note
        category
        items {
          name
          qty
          unit
          unitValue
          subTotal
        }
        serviceProvider
        subTotal
        discount
        discountRule
        amount
        cardId
        cardType
        cardLastFour
        tpMerchantId
        tpBankTxId
        tpRecTradeId
        linePayTransactionId
        linePayPaymentAccessToken
        linePayPaymentUrlWeb
        linePayPaymentUrlApp
        linePayInfo
        redirectUrl
        newebPayResult
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sentAt
      sentBy
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createIssue = /* GraphQL */ `
  mutation CreateIssue(
    $input: CreateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    createIssue(input: $input, condition: $condition) {
      id
      priority
      status
      reportedBy
      county
      type
      summary
      description
      note
      assignee
      orderId
      elderId
      clientId
      deliveryStaffId
      deliveryStaffOrderId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue(
    $input: UpdateIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    updateIssue(input: $input, condition: $condition) {
      id
      priority
      status
      reportedBy
      county
      type
      summary
      description
      note
      assignee
      orderId
      elderId
      clientId
      deliveryStaffId
      deliveryStaffOrderId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue(
    $input: DeleteIssueInput!
    $condition: ModelIssueConditionInput
  ) {
    deleteIssue(input: $input, condition: $condition) {
      id
      priority
      status
      reportedBy
      county
      type
      summary
      description
      note
      assignee
      orderId
      elderId
      clientId
      deliveryStaffId
      deliveryStaffOrderId
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createIssueComment = /* GraphQL */ `
  mutation CreateIssueComment(
    $input: CreateIssueCommentInput!
    $condition: ModelIssueCommentConditionInput
  ) {
    createIssueComment(input: $input, condition: $condition) {
      id
      issueId
      content
      username
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateIssueComment = /* GraphQL */ `
  mutation UpdateIssueComment(
    $input: UpdateIssueCommentInput!
    $condition: ModelIssueCommentConditionInput
  ) {
    updateIssueComment(input: $input, condition: $condition) {
      id
      issueId
      content
      username
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteIssueComment = /* GraphQL */ `
  mutation DeleteIssueComment(
    $input: DeleteIssueCommentInput!
    $condition: ModelIssueCommentConditionInput
  ) {
    deleteIssueComment(input: $input, condition: $condition) {
      id
      issueId
      content
      username
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createLalamoveOrder = /* GraphQL */ `
  mutation CreateLalamoveOrder(
    $input: CreateLalamoveOrderInput!
    $condition: ModelLalamoveOrderConditionInput
  ) {
    createLalamoveOrder(input: $input, condition: $condition) {
      id
      lalamoveDeliveryId
      quotationId
      priceBreakdown {
        base
        extraMileage
        surcharge
        totalExcludePriorityFee
        total
        currency
        priorityFee
      }
      priorityFee
      driverId
      shareLink
      status
      distance {
        value
        unit
      }
      fee
      driver {
        driverId
        name
        phone
        plateNumber
      }
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToLalamoveAt
      lalamoveQuotePayload
      lalamoveOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateLalamoveOrder = /* GraphQL */ `
  mutation UpdateLalamoveOrder(
    $input: UpdateLalamoveOrderInput!
    $condition: ModelLalamoveOrderConditionInput
  ) {
    updateLalamoveOrder(input: $input, condition: $condition) {
      id
      lalamoveDeliveryId
      quotationId
      priceBreakdown {
        base
        extraMileage
        surcharge
        totalExcludePriorityFee
        total
        currency
        priorityFee
      }
      priorityFee
      driverId
      shareLink
      status
      distance {
        value
        unit
      }
      fee
      driver {
        driverId
        name
        phone
        plateNumber
      }
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToLalamoveAt
      lalamoveQuotePayload
      lalamoveOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteLalamoveOrder = /* GraphQL */ `
  mutation DeleteLalamoveOrder(
    $input: DeleteLalamoveOrderInput!
    $condition: ModelLalamoveOrderConditionInput
  ) {
    deleteLalamoveOrder(input: $input, condition: $condition) {
      id
      lalamoveDeliveryId
      quotationId
      priceBreakdown {
        base
        extraMileage
        surcharge
        totalExcludePriorityFee
        total
        currency
        priorityFee
      }
      priorityFee
      driverId
      shareLink
      status
      distance {
        value
        unit
      }
      fee
      driver {
        driverId
        name
        phone
        plateNumber
      }
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToLalamoveAt
      lalamoveQuotePayload
      lalamoveOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createMembership = /* GraphQL */ `
  mutation CreateMembership(
    $input: CreateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    createMembership(input: $input, condition: $condition) {
      id
      clientId
      status
      membershipProgramId
      expiredAt
      discountRule
      discountPercentage
      extendedAt
      transactionIds
      logs
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateMembership = /* GraphQL */ `
  mutation UpdateMembership(
    $input: UpdateMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    updateMembership(input: $input, condition: $condition) {
      id
      clientId
      status
      membershipProgramId
      expiredAt
      discountRule
      discountPercentage
      extendedAt
      transactionIds
      logs
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteMembership = /* GraphQL */ `
  mutation DeleteMembership(
    $input: DeleteMembershipInput!
    $condition: ModelMembershipConditionInput
  ) {
    deleteMembership(input: $input, condition: $condition) {
      id
      clientId
      status
      membershipProgramId
      expiredAt
      discountRule
      discountPercentage
      extendedAt
      transactionIds
      logs
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createMembershipProgram = /* GraphQL */ `
  mutation CreateMembershipProgram(
    $input: CreateMembershipProgramInput!
    $condition: ModelMembershipProgramConditionInput
  ) {
    createMembershipProgram(input: $input, condition: $condition) {
      id
      isActive
      name
      description
      durationInDays
      discountRule
      discountPercentage
      unit
      unitValue
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateMembershipProgram = /* GraphQL */ `
  mutation UpdateMembershipProgram(
    $input: UpdateMembershipProgramInput!
    $condition: ModelMembershipProgramConditionInput
  ) {
    updateMembershipProgram(input: $input, condition: $condition) {
      id
      isActive
      name
      description
      durationInDays
      discountRule
      discountPercentage
      unit
      unitValue
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteMembershipProgram = /* GraphQL */ `
  mutation DeleteMembershipProgram(
    $input: DeleteMembershipProgramInput!
    $condition: ModelMembershipProgramConditionInput
  ) {
    deleteMembershipProgram(input: $input, condition: $condition) {
      id
      isActive
      name
      description
      durationInDays
      discountRule
      discountPercentage
      unit
      unitValue
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      adminId
      clientId
      restaurantOwnerId
      type
      status
      statusMsg
      subject
      text
      dataJsonString
      link
      email
      pushToken
      phoneNumber
      shouldBeSentAt
      sentAt
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      adminId
      clientId
      restaurantOwnerId
      type
      status
      statusMsg
      subject
      text
      dataJsonString
      link
      email
      pushToken
      phoneNumber
      shouldBeSentAt
      sentAt
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      username
      deliveryStaffId
      adminId
      clientId
      restaurantOwnerId
      type
      status
      statusMsg
      subject
      text
      dataJsonString
      link
      email
      pushToken
      phoneNumber
      shouldBeSentAt
      sentAt
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      clientId
      individualId
      restaurantId
      elderId
      date
      mealSlot
      deliveryBy
      status
      county
      countyOpenStatus
      isTemporarilyLocked
      category
      transactionId
      deliveryGroupId
      deliveryGroupSortOrder
      source
      orderQuoteId
      isAudited
      nextStatusCheckAt
      issuePriority
      issueSummary
      deliveryStaffId
      deliveryStaffOrderId
      deliveryStaffFee
      deliveryStaffFeeCalculationMethod
      tier
      tierExpiredAt
      noteForDelivery
      noteForMeal
      note
      mealItems {
        name
        quantity
        price
        cost
        note
        surcharges {
          name
          price
        }
      }
      totalCost
      total
      quantity
      subtotal
      discount
      discountCode
      discountDetails
      extraFee
      platformFee
      paymentMethod
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      orderGroupId
      referenceId
      linkGroupElderIds
      cancellationReason
      cancellationNote
      directionServiceProvider
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      clientId
      individualId
      restaurantId
      elderId
      date
      mealSlot
      deliveryBy
      status
      county
      countyOpenStatus
      isTemporarilyLocked
      category
      transactionId
      deliveryGroupId
      deliveryGroupSortOrder
      source
      orderQuoteId
      isAudited
      nextStatusCheckAt
      issuePriority
      issueSummary
      deliveryStaffId
      deliveryStaffOrderId
      deliveryStaffFee
      deliveryStaffFeeCalculationMethod
      tier
      tierExpiredAt
      noteForDelivery
      noteForMeal
      note
      mealItems {
        name
        quantity
        price
        cost
        note
        surcharges {
          name
          price
        }
      }
      totalCost
      total
      quantity
      subtotal
      discount
      discountCode
      discountDetails
      extraFee
      platformFee
      paymentMethod
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      orderGroupId
      referenceId
      linkGroupElderIds
      cancellationReason
      cancellationNote
      directionServiceProvider
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      clientId
      individualId
      restaurantId
      elderId
      date
      mealSlot
      deliveryBy
      status
      county
      countyOpenStatus
      isTemporarilyLocked
      category
      transactionId
      deliveryGroupId
      deliveryGroupSortOrder
      source
      orderQuoteId
      isAudited
      nextStatusCheckAt
      issuePriority
      issueSummary
      deliveryStaffId
      deliveryStaffOrderId
      deliveryStaffFee
      deliveryStaffFeeCalculationMethod
      tier
      tierExpiredAt
      noteForDelivery
      noteForMeal
      note
      mealItems {
        name
        quantity
        price
        cost
        note
        surcharges {
          name
          price
        }
      }
      totalCost
      total
      quantity
      subtotal
      discount
      discountCode
      discountDetails
      extraFee
      platformFee
      paymentMethod
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      orderGroupId
      referenceId
      linkGroupElderIds
      cancellationReason
      cancellationNote
      directionServiceProvider
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createOrderEvent = /* GraphQL */ `
  mutation CreateOrderEvent(
    $input: CreateOrderEventInput!
    $condition: ModelOrderEventConditionInput
  ) {
    createOrderEvent(input: $input, condition: $condition) {
      id
      orderId
      category
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateOrderEvent = /* GraphQL */ `
  mutation UpdateOrderEvent(
    $input: UpdateOrderEventInput!
    $condition: ModelOrderEventConditionInput
  ) {
    updateOrderEvent(input: $input, condition: $condition) {
      id
      orderId
      category
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteOrderEvent = /* GraphQL */ `
  mutation DeleteOrderEvent(
    $input: DeleteOrderEventInput!
    $condition: ModelOrderEventConditionInput
  ) {
    deleteOrderEvent(input: $input, condition: $condition) {
      id
      orderId
      category
      content
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createOrderQuote = /* GraphQL */ `
  mutation CreateOrderQuote(
    $input: CreateOrderQuoteInput!
    $condition: ModelOrderQuoteConditionInput
  ) {
    createOrderQuote(input: $input, condition: $condition) {
      id
      restaurantId
      restaurantName
      originalStartAddress
      originalEndAddress
      code
      message
      distance
      startAddress
      endAddress
      calculationMethod
      totalFee
      deliveryStaffFee
      extraFee
      platformFee
      fixedDeliveryStaffFee
      defaultDeliveryStaffFee
      pandagoEstimatedFee
      uberEstimatedFee
      ilinkEstimatedFee
      lalamoveEstimatedFee
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      queryAddressMethod
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderQuote = /* GraphQL */ `
  mutation UpdateOrderQuote(
    $input: UpdateOrderQuoteInput!
    $condition: ModelOrderQuoteConditionInput
  ) {
    updateOrderQuote(input: $input, condition: $condition) {
      id
      restaurantId
      restaurantName
      originalStartAddress
      originalEndAddress
      code
      message
      distance
      startAddress
      endAddress
      calculationMethod
      totalFee
      deliveryStaffFee
      extraFee
      platformFee
      fixedDeliveryStaffFee
      defaultDeliveryStaffFee
      pandagoEstimatedFee
      uberEstimatedFee
      ilinkEstimatedFee
      lalamoveEstimatedFee
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      queryAddressMethod
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderQuote = /* GraphQL */ `
  mutation DeleteOrderQuote(
    $input: DeleteOrderQuoteInput!
    $condition: ModelOrderQuoteConditionInput
  ) {
    deleteOrderQuote(input: $input, condition: $condition) {
      id
      restaurantId
      restaurantName
      originalStartAddress
      originalEndAddress
      code
      message
      distance
      startAddress
      endAddress
      calculationMethod
      totalFee
      deliveryStaffFee
      extraFee
      platformFee
      fixedDeliveryStaffFee
      defaultDeliveryStaffFee
      pandagoEstimatedFee
      uberEstimatedFee
      ilinkEstimatedFee
      lalamoveEstimatedFee
      direction {
        startAddress
        startLocation {
          lat
          lng
        }
        endAddress
        endLocation {
          lat
          lng
        }
        distance
        duration
        distanceInMeters
        durationInSeconds
        overviewPolyline
        bounds {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      queryAddressMethod
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      clients {
        items {
          id
          organizationId
          clientId
          visitorMode
          clientMode
          elderSetting
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      clients {
        items {
          id
          organizationId
          clientId
          visitorMode
          clientMode
          elderSetting
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      clients {
        items {
          id
          organizationId
          clientId
          visitorMode
          clientMode
          elderSetting
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPandagoOrder = /* GraphQL */ `
  mutation CreatePandagoOrder(
    $input: CreatePandagoOrderInput!
    $condition: ModelPandagoOrderConditionInput
  ) {
    createPandagoOrder(input: $input, condition: $condition) {
      id
      pandagoOrderId
      status
      paymentMethod
      amount
      description
      fee
      estimatedPickupTime
      estimatedDeliveryTime
      driver {
        id
        name
        phoneNumber
      }
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToPandagoAt
      pandagoOrderPayload
      proofOfDeliveryS3Key
      trackingLink
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updatePandagoOrder = /* GraphQL */ `
  mutation UpdatePandagoOrder(
    $input: UpdatePandagoOrderInput!
    $condition: ModelPandagoOrderConditionInput
  ) {
    updatePandagoOrder(input: $input, condition: $condition) {
      id
      pandagoOrderId
      status
      paymentMethod
      amount
      description
      fee
      estimatedPickupTime
      estimatedDeliveryTime
      driver {
        id
        name
        phoneNumber
      }
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToPandagoAt
      pandagoOrderPayload
      proofOfDeliveryS3Key
      trackingLink
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deletePandagoOrder = /* GraphQL */ `
  mutation DeletePandagoOrder(
    $input: DeletePandagoOrderInput!
    $condition: ModelPandagoOrderConditionInput
  ) {
    deletePandagoOrder(input: $input, condition: $condition) {
      id
      pandagoOrderId
      status
      paymentMethod
      amount
      description
      fee
      estimatedPickupTime
      estimatedDeliveryTime
      driver {
        id
        name
        phoneNumber
      }
      cancelledBy
      cancelledReason
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToPandagoAt
      pandagoOrderPayload
      proofOfDeliveryS3Key
      trackingLink
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      isActive
      name
      description
      url
      logoUrl
      bannerS3Key
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      isActive
      name
      description
      url
      logoUrl
      bannerS3Key
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      isActive
      name
      description
      url
      logoUrl
      bannerS3Key
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createRecurringOrder = /* GraphQL */ `
  mutation CreateRecurringOrder(
    $input: CreateRecurringOrderInput!
    $condition: ModelRecurringOrderConditionInput
  ) {
    createRecurringOrder(input: $input, condition: $condition) {
      id
      clientId
      elderId
      deliveryGroupId
      deliveryStaffId
      restaurantId
      deliveryBy
      mealItems {
        name
        quantity
        price
        cost
        note
        surcharges {
          name
          price
        }
      }
      mealSlot
      category
      status
      repeatOn
      startOn
      endOn
      lastExecutedAt
      lastExecutedStatus
      lastExecutedStatusMessage
      createdAt
      createdBy
      updatedAt
      updatedBy
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateRecurringOrder = /* GraphQL */ `
  mutation UpdateRecurringOrder(
    $input: UpdateRecurringOrderInput!
    $condition: ModelRecurringOrderConditionInput
  ) {
    updateRecurringOrder(input: $input, condition: $condition) {
      id
      clientId
      elderId
      deliveryGroupId
      deliveryStaffId
      restaurantId
      deliveryBy
      mealItems {
        name
        quantity
        price
        cost
        note
        surcharges {
          name
          price
        }
      }
      mealSlot
      category
      status
      repeatOn
      startOn
      endOn
      lastExecutedAt
      lastExecutedStatus
      lastExecutedStatusMessage
      createdAt
      createdBy
      updatedAt
      updatedBy
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteRecurringOrder = /* GraphQL */ `
  mutation DeleteRecurringOrder(
    $input: DeleteRecurringOrderInput!
    $condition: ModelRecurringOrderConditionInput
  ) {
    deleteRecurringOrder(input: $input, condition: $condition) {
      id
      clientId
      elderId
      deliveryGroupId
      deliveryStaffId
      restaurantId
      deliveryBy
      mealItems {
        name
        quantity
        price
        cost
        note
        surcharges {
          name
          price
        }
      }
      mealSlot
      category
      status
      repeatOn
      startOn
      endOn
      lastExecutedAt
      lastExecutedStatus
      lastExecutedStatusMessage
      createdAt
      createdBy
      updatedAt
      updatedBy
      elder {
        id
        username
        status
        clientId
        county
        deliveryGroupId
        sortOrder
        name
        nickname
        birthday
        gender
        phoneNumber
        phoneNumber2
        phoneNumber3
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        email
        identificationCardId
        serviceCode
        noteForDelivery
        noteForMeal
        noteForHealth
        note
        noteOther
        preferDeliveryTimes
        isAllowedToEnterHome
        otherSocialServices
        isDisadvantaged
        relationshipToClient
        emergencyContact {
          name
          relationship
          phoneNumber
          phoneNumber2
        }
        govQualifiedCount
        govQualifiedPrice
        supervisorId
        linkGroupName
        tags {
          items {
            id
            elderId
            tagId
            elder {
              id
              username
              status
              clientId
              county
              deliveryGroupId
              sortOrder
              name
              nickname
              birthday
              gender
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              identificationCardId
              serviceCode
              noteForDelivery
              noteForMeal
              noteForHealth
              note
              noteOther
              preferDeliveryTimes
              isAllowedToEnterHome
              otherSocialServices
              isDisadvantaged
              relationshipToClient
              govQualifiedCount
              govQualifiedPrice
              supervisorId
              linkGroupName
              outOfPocket
              feeCollectMethod
              disadvantagedTypes
              disabilityLevel
              cmsLevel
              caseNumber
              lunchRepeatOn
              dinnerRepeatOn
              fixedDeliveryStaffFee
              onTimeDelivery
              dementiaQuestionnaire
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
        outOfPocket
        feeCollectMethod
        disadvantagedTypes
        disadvantagedTypesHistory {
          approvedDate
          disadvantagedTypes
        }
        disabilityLevel
        cmsLevel
        caseNumber
        lunchRepeatOn
        dinnerRepeatOn
        fixedDeliveryStaffFee
        onTimeDelivery
        dementiaQuestionnaire
        createdAt
        createdBy
        updatedAt
        updatedBy
        client {
          id
          shortId
          type
          isActive
          county
          username
          name
          dba
          address {
            country
            county
            district
            street
            zipCode
            lat
            lng
            note
          }
          phoneNumber
          phoneNumber2
          phoneNumber3
          email
          contact
          ageGroup
          birthday
          gender
          jobOccupation
          howDoYouKnowUs
          referredBy
          organizationId
          usersLimit
          eldersLimit
          note
          translation
          useDedicatedDeliveryStaffs
          featureConfiguration {
            skipRegisterSteps
            skipDocumentsUpload
            hideSToken
            supportNumber
            useDedicatedDeliveryStaffs
            dementiaQuestionnaire
            reportTypes
            supportedPartners
          }
          basicFee
          basicDistanceInKM
          extraFeeInKM
          extraFee
          platformFee
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          facilitySettingsId
          facilitySettings {
            id
            dba
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            receiptSettings {
              originalPrice
            }
            logoS3Keys
            deliverySetting {
              canStartDeliveryBefore
              canStartDeliveryAfter
              canCompleteOrderAfter
              canModifyDiaryAfter
            }
            mealStatisticsSettings {
              name
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          orderableRestaurants {
            id
            isPublic
          }
          assignableDeliveryStaffs
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
      deliveryGroup {
        id
        clientId
        isActive
        name
        description
        note
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createRecurringOrderLog = /* GraphQL */ `
  mutation CreateRecurringOrderLog(
    $input: CreateRecurringOrderLogInput!
    $condition: ModelRecurringOrderLogConditionInput
  ) {
    createRecurringOrderLog(input: $input, condition: $condition) {
      id
      orderIds
      recurringOrderId
      deliveryBy
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateRecurringOrderLog = /* GraphQL */ `
  mutation UpdateRecurringOrderLog(
    $input: UpdateRecurringOrderLogInput!
    $condition: ModelRecurringOrderLogConditionInput
  ) {
    updateRecurringOrderLog(input: $input, condition: $condition) {
      id
      orderIds
      recurringOrderId
      deliveryBy
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteRecurringOrderLog = /* GraphQL */ `
  mutation DeleteRecurringOrderLog(
    $input: DeleteRecurringOrderLogInput!
    $condition: ModelRecurringOrderLogConditionInput
  ) {
    deleteRecurringOrderLog(input: $input, condition: $condition) {
      id
      orderIds
      recurringOrderId
      deliveryBy
      date
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createRestaurant = /* GraphQL */ `
  mutation CreateRestaurant(
    $input: CreateRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    createRestaurant(input: $input, condition: $condition) {
      id
      owner
      restaurantOwnerId
      isActive
      isPublic
      freeDelivery
      county
      clientId
      category
      name
      description
      phoneNumber
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      notes
      previewS3Keys
      imageS3Keys
      isAddedToPandago
      openingHours {
        sunday {
          startTime
          endTime
        }
        monday {
          startTime
          endTime
        }
        tuesday {
          startTime
          endTime
        }
        wednesday {
          startTime
          endTime
        }
        thursday {
          startTime
          endTime
        }
        friday {
          startTime
          endTime
        }
        saturday {
          startTime
          endTime
        }
      }
      serviceAreas {
        county
        district
        zipCode
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const updateRestaurant = /* GraphQL */ `
  mutation UpdateRestaurant(
    $input: UpdateRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    updateRestaurant(input: $input, condition: $condition) {
      id
      owner
      restaurantOwnerId
      isActive
      isPublic
      freeDelivery
      county
      clientId
      category
      name
      description
      phoneNumber
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      notes
      previewS3Keys
      imageS3Keys
      isAddedToPandago
      openingHours {
        sunday {
          startTime
          endTime
        }
        monday {
          startTime
          endTime
        }
        tuesday {
          startTime
          endTime
        }
        wednesday {
          startTime
          endTime
        }
        thursday {
          startTime
          endTime
        }
        friday {
          startTime
          endTime
        }
        saturday {
          startTime
          endTime
        }
      }
      serviceAreas {
        county
        district
        zipCode
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteRestaurant = /* GraphQL */ `
  mutation DeleteRestaurant(
    $input: DeleteRestaurantInput!
    $condition: ModelRestaurantConditionInput
  ) {
    deleteRestaurant(input: $input, condition: $condition) {
      id
      owner
      restaurantOwnerId
      isActive
      isPublic
      freeDelivery
      county
      clientId
      category
      name
      description
      phoneNumber
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      notes
      previewS3Keys
      imageS3Keys
      isAddedToPandago
      openingHours {
        sunday {
          startTime
          endTime
        }
        monday {
          startTime
          endTime
        }
        tuesday {
          startTime
          endTime
        }
        wednesday {
          startTime
          endTime
        }
        thursday {
          startTime
          endTime
        }
        friday {
          startTime
          endTime
        }
        saturday {
          startTime
          endTime
        }
      }
      serviceAreas {
        county
        district
        zipCode
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const createRestaurantHoliday = /* GraphQL */ `
  mutation CreateRestaurantHoliday(
    $input: CreateRestaurantHolidayInput!
    $condition: ModelRestaurantHolidayConditionInput
  ) {
    createRestaurantHoliday(input: $input, condition: $condition) {
      id
      restaurantId
      year
      closedDays
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateRestaurantHoliday = /* GraphQL */ `
  mutation UpdateRestaurantHoliday(
    $input: UpdateRestaurantHolidayInput!
    $condition: ModelRestaurantHolidayConditionInput
  ) {
    updateRestaurantHoliday(input: $input, condition: $condition) {
      id
      restaurantId
      year
      closedDays
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteRestaurantHoliday = /* GraphQL */ `
  mutation DeleteRestaurantHoliday(
    $input: DeleteRestaurantHolidayInput!
    $condition: ModelRestaurantHolidayConditionInput
  ) {
    deleteRestaurantHoliday(input: $input, condition: $condition) {
      id
      restaurantId
      year
      closedDays
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createRestaurantMeal = /* GraphQL */ `
  mutation CreateRestaurantMeal(
    $input: CreateRestaurantMealInput!
    $condition: ModelRestaurantMealConditionInput
  ) {
    createRestaurantMeal(input: $input, condition: $condition) {
      id
      restaurantId
      isActive
      isPublic
      name
      description
      price
      cost
      surcharges {
        name
        price
      }
      previewS3Keys
      imageS3Keys
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantMealId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurantMeal {
            id
            restaurantId
            isActive
            isPublic
            name
            description
            price
            cost
            surcharges {
              name
              price
            }
            previewS3Keys
            imageS3Keys
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const updateRestaurantMeal = /* GraphQL */ `
  mutation UpdateRestaurantMeal(
    $input: UpdateRestaurantMealInput!
    $condition: ModelRestaurantMealConditionInput
  ) {
    updateRestaurantMeal(input: $input, condition: $condition) {
      id
      restaurantId
      isActive
      isPublic
      name
      description
      price
      cost
      surcharges {
        name
        price
      }
      previewS3Keys
      imageS3Keys
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantMealId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurantMeal {
            id
            restaurantId
            isActive
            isPublic
            name
            description
            price
            cost
            surcharges {
              name
              price
            }
            previewS3Keys
            imageS3Keys
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteRestaurantMeal = /* GraphQL */ `
  mutation DeleteRestaurantMeal(
    $input: DeleteRestaurantMealInput!
    $condition: ModelRestaurantMealConditionInput
  ) {
    deleteRestaurantMeal(input: $input, condition: $condition) {
      id
      restaurantId
      isActive
      isPublic
      name
      description
      price
      cost
      surcharges {
        name
        price
      }
      previewS3Keys
      imageS3Keys
      createdAt
      createdBy
      updatedAt
      updatedBy
      tags {
        items {
          id
          restaurantMealId
          tagId
          createdAt
          createdBy
          updatedAt
          updatedBy
          restaurantMeal {
            id
            restaurantId
            isActive
            isPublic
            name
            description
            price
            cost
            surcharges {
              name
              price
            }
            previewS3Keys
            imageS3Keys
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
          tag {
            id
            category
            subcategory
            color
            label
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
        }
        nextToken
      }
    }
  }
`;
export const createRestaurantMealTag = /* GraphQL */ `
  mutation CreateRestaurantMealTag(
    $input: CreateRestaurantMealTagInput!
    $condition: ModelRestaurantMealTagConditionInput
  ) {
    createRestaurantMealTag(input: $input, condition: $condition) {
      id
      restaurantMealId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurantMeal {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        surcharges {
          name
          price
        }
        previewS3Keys
        imageS3Keys
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantMealId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurantMeal {
              id
              restaurantId
              isActive
              isPublic
              name
              description
              price
              cost
              previewS3Keys
              imageS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const updateRestaurantMealTag = /* GraphQL */ `
  mutation UpdateRestaurantMealTag(
    $input: UpdateRestaurantMealTagInput!
    $condition: ModelRestaurantMealTagConditionInput
  ) {
    updateRestaurantMealTag(input: $input, condition: $condition) {
      id
      restaurantMealId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurantMeal {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        surcharges {
          name
          price
        }
        previewS3Keys
        imageS3Keys
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantMealId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurantMeal {
              id
              restaurantId
              isActive
              isPublic
              name
              description
              price
              cost
              previewS3Keys
              imageS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const deleteRestaurantMealTag = /* GraphQL */ `
  mutation DeleteRestaurantMealTag(
    $input: DeleteRestaurantMealTagInput!
    $condition: ModelRestaurantMealTagConditionInput
  ) {
    deleteRestaurantMealTag(input: $input, condition: $condition) {
      id
      restaurantMealId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurantMeal {
        id
        restaurantId
        isActive
        isPublic
        name
        description
        price
        cost
        surcharges {
          name
          price
        }
        previewS3Keys
        imageS3Keys
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantMealId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurantMeal {
              id
              restaurantId
              isActive
              isPublic
              name
              description
              price
              cost
              previewS3Keys
              imageS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createRestaurantOwner = /* GraphQL */ `
  mutation CreateRestaurantOwner(
    $input: CreateRestaurantOwnerInput!
    $condition: ModelRestaurantOwnerConditionInput
  ) {
    createRestaurantOwner(input: $input, condition: $condition) {
      id
      isActive
      county
      username
      name
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      email
      howDoYouKnowUs
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateRestaurantOwner = /* GraphQL */ `
  mutation UpdateRestaurantOwner(
    $input: UpdateRestaurantOwnerInput!
    $condition: ModelRestaurantOwnerConditionInput
  ) {
    updateRestaurantOwner(input: $input, condition: $condition) {
      id
      isActive
      county
      username
      name
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      email
      howDoYouKnowUs
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteRestaurantOwner = /* GraphQL */ `
  mutation DeleteRestaurantOwner(
    $input: DeleteRestaurantOwnerInput!
    $condition: ModelRestaurantOwnerConditionInput
  ) {
    deleteRestaurantOwner(input: $input, condition: $condition) {
      id
      isActive
      county
      username
      name
      address {
        country
        county
        district
        street
        zipCode
        lat
        lng
        note
      }
      phoneNumber
      email
      howDoYouKnowUs
      note
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createRestaurantSchedule = /* GraphQL */ `
  mutation CreateRestaurantSchedule(
    $input: CreateRestaurantScheduleInput!
    $condition: ModelRestaurantScheduleConditionInput
  ) {
    createRestaurantSchedule(input: $input, condition: $condition) {
      id
      restaurantId
      date
      mealSlot
      start
      end
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateRestaurantSchedule = /* GraphQL */ `
  mutation UpdateRestaurantSchedule(
    $input: UpdateRestaurantScheduleInput!
    $condition: ModelRestaurantScheduleConditionInput
  ) {
    updateRestaurantSchedule(input: $input, condition: $condition) {
      id
      restaurantId
      date
      mealSlot
      start
      end
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteRestaurantSchedule = /* GraphQL */ `
  mutation DeleteRestaurantSchedule(
    $input: DeleteRestaurantScheduleInput!
    $condition: ModelRestaurantScheduleConditionInput
  ) {
    deleteRestaurantSchedule(input: $input, condition: $condition) {
      id
      restaurantId
      date
      mealSlot
      start
      end
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createRestaurantTag = /* GraphQL */ `
  mutation CreateRestaurantTag(
    $input: CreateRestaurantTagInput!
    $condition: ModelRestaurantTagConditionInput
  ) {
    createRestaurantTag(input: $input, condition: $condition) {
      id
      restaurantId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const updateRestaurantTag = /* GraphQL */ `
  mutation UpdateRestaurantTag(
    $input: UpdateRestaurantTagInput!
    $condition: ModelRestaurantTagConditionInput
  ) {
    updateRestaurantTag(input: $input, condition: $condition) {
      id
      restaurantId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const deleteRestaurantTag = /* GraphQL */ `
  mutation DeleteRestaurantTag(
    $input: DeleteRestaurantTagInput!
    $condition: ModelRestaurantTagConditionInput
  ) {
    deleteRestaurantTag(input: $input, condition: $condition) {
      id
      restaurantId
      tagId
      createdAt
      createdBy
      updatedAt
      updatedBy
      restaurant {
        id
        owner
        restaurantOwnerId
        isActive
        isPublic
        freeDelivery
        county
        clientId
        category
        name
        description
        phoneNumber
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        notes
        previewS3Keys
        imageS3Keys
        isAddedToPandago
        openingHours {
          sunday {
            startTime
            endTime
          }
          monday {
            startTime
            endTime
          }
          tuesday {
            startTime
            endTime
          }
          wednesday {
            startTime
            endTime
          }
          thursday {
            startTime
            endTime
          }
          friday {
            startTime
            endTime
          }
          saturday {
            startTime
            endTime
          }
        }
        serviceAreas {
          county
          district
          zipCode
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        tags {
          items {
            id
            restaurantId
            tagId
            createdAt
            createdBy
            updatedAt
            updatedBy
            restaurant {
              id
              owner
              restaurantOwnerId
              isActive
              isPublic
              freeDelivery
              county
              clientId
              category
              name
              description
              phoneNumber
              notes
              previewS3Keys
              imageS3Keys
              isAddedToPandago
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            tag {
              id
              category
              subcategory
              color
              label
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          nextToken
        }
      }
      tag {
        id
        category
        subcategory
        color
        label
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;
export const createSponsorship = /* GraphQL */ `
  mutation CreateSponsorship(
    $input: CreateSponsorshipInput!
    $condition: ModelSponsorshipConditionInput
  ) {
    createSponsorship(input: $input, condition: $condition) {
      id
      clientId
      username
      transactionId
      sponsorProgramId
      status
      count
      unit
      unitValue
      totalValue
      fulfilledCount
      fulfilledValue
      fulfilledAt
      note
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sponsorProgram {
        id
        isActive
        name
        summary
        description
        count
        unit
        unitValue
        totalValue
        previewS3Keys
        imageS3Keys
        sponsors {
          name
          description
          websiteUrl
          logoUrl
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      fulfillments {
        items {
          id
          username
          clientId
          sponsorshipId
          status
          unit
          unitValue
          fulfilledAt
          orderId
          elderId
          restaurantId
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateSponsorship = /* GraphQL */ `
  mutation UpdateSponsorship(
    $input: UpdateSponsorshipInput!
    $condition: ModelSponsorshipConditionInput
  ) {
    updateSponsorship(input: $input, condition: $condition) {
      id
      clientId
      username
      transactionId
      sponsorProgramId
      status
      count
      unit
      unitValue
      totalValue
      fulfilledCount
      fulfilledValue
      fulfilledAt
      note
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sponsorProgram {
        id
        isActive
        name
        summary
        description
        count
        unit
        unitValue
        totalValue
        previewS3Keys
        imageS3Keys
        sponsors {
          name
          description
          websiteUrl
          logoUrl
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      fulfillments {
        items {
          id
          username
          clientId
          sponsorshipId
          status
          unit
          unitValue
          fulfilledAt
          orderId
          elderId
          restaurantId
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteSponsorship = /* GraphQL */ `
  mutation DeleteSponsorship(
    $input: DeleteSponsorshipInput!
    $condition: ModelSponsorshipConditionInput
  ) {
    deleteSponsorship(input: $input, condition: $condition) {
      id
      clientId
      username
      transactionId
      sponsorProgramId
      status
      count
      unit
      unitValue
      totalValue
      fulfilledCount
      fulfilledValue
      fulfilledAt
      note
      client {
        id
        shortId
        type
        isActive
        county
        username
        name
        dba
        address {
          country
          county
          district
          street
          zipCode
          lat
          lng
          note
        }
        phoneNumber
        phoneNumber2
        phoneNumber3
        email
        contact
        ageGroup
        birthday
        gender
        jobOccupation
        howDoYouKnowUs
        referredBy
        organizationId
        usersLimit
        eldersLimit
        note
        translation
        useDedicatedDeliveryStaffs
        featureConfiguration {
          skipRegisterSteps
          skipDocumentsUpload
          hideSToken
          supportNumber
          useDedicatedDeliveryStaffs
          dementiaQuestionnaire
          reportTypes
          supportedPartners
        }
        basicFee
        basicDistanceInKM
        extraFeeInKM
        extraFee
        platformFee
        govQualifiedPrice
        lunchStartTime
        lunchEndTime
        dinnerStartTime
        dinnerEndTime
        receiptInfo {
          receiptType
          taxIdNumber
          faxNumber
          bankName
          accountNumber
          accountName
          note
          managerTitle
          reverseCopyType
        }
        facilitySettingsId
        facilitySettings {
          id
          dba
          govQualifiedPrice
          lunchStartTime
          lunchEndTime
          dinnerStartTime
          dinnerEndTime
          receiptInfo {
            receiptType
            taxIdNumber
            faxNumber
            bankName
            accountNumber
            accountName
            note
            managerTitle
            reverseCopyType
          }
          receiptSettings {
            originalPrice
            copaymentSettings {
              disadvantagedType
              disadvantagedLabel
              copayment
            }
          }
          logoS3Keys
          deliverySetting {
            canStartDeliveryBefore
            canStartDeliveryAfter
            canCompleteOrderAfter
            canModifyDiaryAfter
          }
          mealStatisticsSettings {
            name
            mealStatisticsSettingsItems {
              mealItemName
              restaurantId
              restaurantName
              tags
            }
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        orderableRestaurants {
          id
          isPublic
        }
        assignableDeliveryStaffs
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      sponsorProgram {
        id
        isActive
        name
        summary
        description
        count
        unit
        unitValue
        totalValue
        previewS3Keys
        imageS3Keys
        sponsors {
          name
          description
          websiteUrl
          logoUrl
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      fulfillments {
        items {
          id
          username
          clientId
          sponsorshipId
          status
          unit
          unitValue
          fulfilledAt
          orderId
          elderId
          restaurantId
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createSponsorshipFulfillment = /* GraphQL */ `
  mutation CreateSponsorshipFulfillment(
    $input: CreateSponsorshipFulfillmentInput!
    $condition: ModelSponsorshipFulfillmentConditionInput
  ) {
    createSponsorshipFulfillment(input: $input, condition: $condition) {
      id
      username
      clientId
      sponsorshipId
      status
      unit
      unitValue
      fulfilledAt
      orderId
      elderId
      restaurantId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateSponsorshipFulfillment = /* GraphQL */ `
  mutation UpdateSponsorshipFulfillment(
    $input: UpdateSponsorshipFulfillmentInput!
    $condition: ModelSponsorshipFulfillmentConditionInput
  ) {
    updateSponsorshipFulfillment(input: $input, condition: $condition) {
      id
      username
      clientId
      sponsorshipId
      status
      unit
      unitValue
      fulfilledAt
      orderId
      elderId
      restaurantId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteSponsorshipFulfillment = /* GraphQL */ `
  mutation DeleteSponsorshipFulfillment(
    $input: DeleteSponsorshipFulfillmentInput!
    $condition: ModelSponsorshipFulfillmentConditionInput
  ) {
    deleteSponsorshipFulfillment(input: $input, condition: $condition) {
      id
      username
      clientId
      sponsorshipId
      status
      unit
      unitValue
      fulfilledAt
      orderId
      elderId
      restaurantId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createSponsorshipProgram = /* GraphQL */ `
  mutation CreateSponsorshipProgram(
    $input: CreateSponsorshipProgramInput!
    $condition: ModelSponsorshipProgramConditionInput
  ) {
    createSponsorshipProgram(input: $input, condition: $condition) {
      id
      isActive
      name
      summary
      description
      count
      unit
      unitValue
      totalValue
      previewS3Keys
      imageS3Keys
      sponsors {
        name
        description
        websiteUrl
        logoUrl
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateSponsorshipProgram = /* GraphQL */ `
  mutation UpdateSponsorshipProgram(
    $input: UpdateSponsorshipProgramInput!
    $condition: ModelSponsorshipProgramConditionInput
  ) {
    updateSponsorshipProgram(input: $input, condition: $condition) {
      id
      isActive
      name
      summary
      description
      count
      unit
      unitValue
      totalValue
      previewS3Keys
      imageS3Keys
      sponsors {
        name
        description
        websiteUrl
        logoUrl
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteSponsorshipProgram = /* GraphQL */ `
  mutation DeleteSponsorshipProgram(
    $input: DeleteSponsorshipProgramInput!
    $condition: ModelSponsorshipProgramConditionInput
  ) {
    deleteSponsorshipProgram(input: $input, condition: $condition) {
      id
      isActive
      name
      summary
      description
      count
      unit
      unitValue
      totalValue
      previewS3Keys
      imageS3Keys
      sponsors {
        name
        description
        websiteUrl
        logoUrl
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      category
      subcategory
      color
      label
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      category
      subcategory
      color
      label
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      category
      subcategory
      color
      label
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      username
      clientId
      deliveryStaffId
      restaurantOwnerId
      orderId
      orders {
        items {
          id
          clientId
          individualId
          restaurantId
          elderId
          date
          mealSlot
          deliveryBy
          status
          county
          countyOpenStatus
          isTemporarilyLocked
          category
          transactionId
          deliveryGroupId
          deliveryGroupSortOrder
          source
          orderQuoteId
          isAudited
          nextStatusCheckAt
          issuePriority
          issueSummary
          deliveryStaffId
          deliveryStaffOrderId
          deliveryStaffFee
          deliveryStaffFeeCalculationMethod
          tier
          tierExpiredAt
          noteForDelivery
          noteForMeal
          note
          mealItems {
            name
            quantity
            price
            cost
            note
            surcharges {
              name
              price
            }
          }
          totalCost
          total
          quantity
          subtotal
          discount
          discountCode
          discountDetails
          extraFee
          platformFee
          paymentMethod
          direction {
            startAddress
            startLocation {
              lat
              lng
            }
            endAddress
            endLocation {
              lat
              lng
            }
            distance
            duration
            distanceInMeters
            durationInSeconds
            overviewPolyline
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          orderGroupId
          referenceId
          linkGroupElderIds
          cancellationReason
          cancellationNote
          directionServiceProvider
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
        }
        nextToken
      }
      status
      statusMessage
      type
      description
      note
      category
      items {
        name
        qty
        unit
        unitValue
        subTotal
      }
      serviceProvider
      subTotal
      discount
      discountRule
      amount
      cardId
      cardType
      cardLastFour
      tpMerchantId
      tpBankTxId
      tpRecTradeId
      linePayTransactionId
      linePayPaymentAccessToken
      linePayPaymentUrlWeb
      linePayPaymentUrlApp
      linePayInfo
      redirectUrl
      newebPayResult
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      username
      clientId
      deliveryStaffId
      restaurantOwnerId
      orderId
      orders {
        items {
          id
          clientId
          individualId
          restaurantId
          elderId
          date
          mealSlot
          deliveryBy
          status
          county
          countyOpenStatus
          isTemporarilyLocked
          category
          transactionId
          deliveryGroupId
          deliveryGroupSortOrder
          source
          orderQuoteId
          isAudited
          nextStatusCheckAt
          issuePriority
          issueSummary
          deliveryStaffId
          deliveryStaffOrderId
          deliveryStaffFee
          deliveryStaffFeeCalculationMethod
          tier
          tierExpiredAt
          noteForDelivery
          noteForMeal
          note
          mealItems {
            name
            quantity
            price
            cost
            note
            surcharges {
              name
              price
            }
          }
          totalCost
          total
          quantity
          subtotal
          discount
          discountCode
          discountDetails
          extraFee
          platformFee
          paymentMethod
          direction {
            startAddress
            startLocation {
              lat
              lng
            }
            endAddress
            endLocation {
              lat
              lng
            }
            distance
            duration
            distanceInMeters
            durationInSeconds
            overviewPolyline
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          orderGroupId
          referenceId
          linkGroupElderIds
          cancellationReason
          cancellationNote
          directionServiceProvider
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
        }
        nextToken
      }
      status
      statusMessage
      type
      description
      note
      category
      items {
        name
        qty
        unit
        unitValue
        subTotal
      }
      serviceProvider
      subTotal
      discount
      discountRule
      amount
      cardId
      cardType
      cardLastFour
      tpMerchantId
      tpBankTxId
      tpRecTradeId
      linePayTransactionId
      linePayPaymentAccessToken
      linePayPaymentUrlWeb
      linePayPaymentUrlApp
      linePayInfo
      redirectUrl
      newebPayResult
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      username
      clientId
      deliveryStaffId
      restaurantOwnerId
      orderId
      orders {
        items {
          id
          clientId
          individualId
          restaurantId
          elderId
          date
          mealSlot
          deliveryBy
          status
          county
          countyOpenStatus
          isTemporarilyLocked
          category
          transactionId
          deliveryGroupId
          deliveryGroupSortOrder
          source
          orderQuoteId
          isAudited
          nextStatusCheckAt
          issuePriority
          issueSummary
          deliveryStaffId
          deliveryStaffOrderId
          deliveryStaffFee
          deliveryStaffFeeCalculationMethod
          tier
          tierExpiredAt
          noteForDelivery
          noteForMeal
          note
          mealItems {
            name
            quantity
            price
            cost
            note
            surcharges {
              name
              price
            }
          }
          totalCost
          total
          quantity
          subtotal
          discount
          discountCode
          discountDetails
          extraFee
          platformFee
          paymentMethod
          direction {
            startAddress
            startLocation {
              lat
              lng
            }
            endAddress
            endLocation {
              lat
              lng
            }
            distance
            duration
            distanceInMeters
            durationInSeconds
            overviewPolyline
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          client {
            id
            shortId
            type
            isActive
            county
            username
            name
            dba
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            phoneNumber
            phoneNumber2
            phoneNumber3
            email
            contact
            ageGroup
            birthday
            gender
            jobOccupation
            howDoYouKnowUs
            referredBy
            organizationId
            usersLimit
            eldersLimit
            note
            translation
            useDedicatedDeliveryStaffs
            featureConfiguration {
              skipRegisterSteps
              skipDocumentsUpload
              hideSToken
              supportNumber
              useDedicatedDeliveryStaffs
              dementiaQuestionnaire
              reportTypes
              supportedPartners
            }
            basicFee
            basicDistanceInKM
            extraFeeInKM
            extraFee
            platformFee
            govQualifiedPrice
            lunchStartTime
            lunchEndTime
            dinnerStartTime
            dinnerEndTime
            receiptInfo {
              receiptType
              taxIdNumber
              faxNumber
              bankName
              accountNumber
              accountName
              note
              managerTitle
              reverseCopyType
            }
            facilitySettingsId
            facilitySettings {
              id
              dba
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              logoS3Keys
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
            orderableRestaurants {
              id
              isPublic
            }
            assignableDeliveryStaffs
            createdAt
            createdBy
            updatedAt
            updatedBy
          }
          elder {
            id
            username
            status
            clientId
            county
            deliveryGroupId
            sortOrder
            name
            nickname
            birthday
            gender
            phoneNumber
            phoneNumber2
            phoneNumber3
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            email
            identificationCardId
            serviceCode
            noteForDelivery
            noteForMeal
            noteForHealth
            note
            noteOther
            preferDeliveryTimes
            isAllowedToEnterHome
            otherSocialServices
            isDisadvantaged
            relationshipToClient
            emergencyContact {
              name
              relationship
              phoneNumber
              phoneNumber2
            }
            govQualifiedCount
            govQualifiedPrice
            supervisorId
            linkGroupName
            tags {
              nextToken
            }
            outOfPocket
            feeCollectMethod
            disadvantagedTypes
            disadvantagedTypesHistory {
              approvedDate
              disadvantagedTypes
            }
            disabilityLevel
            cmsLevel
            caseNumber
            lunchRepeatOn
            dinnerRepeatOn
            fixedDeliveryStaffFee
            onTimeDelivery
            dementiaQuestionnaire
            createdAt
            createdBy
            updatedAt
            updatedBy
            client {
              id
              shortId
              type
              isActive
              county
              username
              name
              dba
              phoneNumber
              phoneNumber2
              phoneNumber3
              email
              contact
              ageGroup
              birthday
              gender
              jobOccupation
              howDoYouKnowUs
              referredBy
              organizationId
              usersLimit
              eldersLimit
              note
              translation
              useDedicatedDeliveryStaffs
              basicFee
              basicDistanceInKM
              extraFeeInKM
              extraFee
              platformFee
              govQualifiedPrice
              lunchStartTime
              lunchEndTime
              dinnerStartTime
              dinnerEndTime
              facilitySettingsId
              assignableDeliveryStaffs
              createdAt
              createdBy
              updatedAt
              updatedBy
            }
          }
          orderGroupId
          referenceId
          linkGroupElderIds
          cancellationReason
          cancellationNote
          directionServiceProvider
          restaurant {
            id
            owner
            restaurantOwnerId
            isActive
            isPublic
            freeDelivery
            county
            clientId
            category
            name
            description
            phoneNumber
            address {
              country
              county
              district
              street
              zipCode
              lat
              lng
              note
            }
            notes
            previewS3Keys
            imageS3Keys
            isAddedToPandago
            serviceAreas {
              county
              district
              zipCode
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            tags {
              nextToken
            }
          }
        }
        nextToken
      }
      status
      statusMessage
      type
      description
      note
      category
      items {
        name
        qty
        unit
        unitValue
        subTotal
      }
      serviceProvider
      subTotal
      discount
      discountRule
      amount
      cardId
      cardType
      cardLastFour
      tpMerchantId
      tpBankTxId
      tpRecTradeId
      linePayTransactionId
      linePayPaymentAccessToken
      linePayPaymentUrlWeb
      linePayPaymentUrlApp
      linePayInfo
      redirectUrl
      newebPayResult
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createUberOrder = /* GraphQL */ `
  mutation CreateUberOrder(
    $input: CreateUberOrderInput!
    $condition: ModelUberOrderConditionInput
  ) {
    createUberOrder(input: $input, condition: $condition) {
      id
      uberDeliveryId
      quoteId
      status
      complete
      kind
      uuid
      liveMode
      manifestReference
      fee
      courier {
        name
        rating
        vehicleType
        vehicleMake
        vehicleModel
        vehicleColor
        phoneNumber
        imgHref
        location {
          lat
          lng
        }
      }
      trackingUrl
      undeliverableAction
      undeliverableReason
      cancelledReason
      deliverableAction
      courierNotes
      amount
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToUberAt
      uberQuotePayload
      uberOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateUberOrder = /* GraphQL */ `
  mutation UpdateUberOrder(
    $input: UpdateUberOrderInput!
    $condition: ModelUberOrderConditionInput
  ) {
    updateUberOrder(input: $input, condition: $condition) {
      id
      uberDeliveryId
      quoteId
      status
      complete
      kind
      uuid
      liveMode
      manifestReference
      fee
      courier {
        name
        rating
        vehicleType
        vehicleMake
        vehicleModel
        vehicleColor
        phoneNumber
        imgHref
        location {
          lat
          lng
        }
      }
      trackingUrl
      undeliverableAction
      undeliverableReason
      cancelledReason
      deliverableAction
      courierNotes
      amount
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToUberAt
      uberQuotePayload
      uberOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteUberOrder = /* GraphQL */ `
  mutation DeleteUberOrder(
    $input: DeleteUberOrderInput!
    $condition: ModelUberOrderConditionInput
  ) {
    deleteUberOrder(input: $input, condition: $condition) {
      id
      uberDeliveryId
      quoteId
      status
      complete
      kind
      uuid
      liveMode
      manifestReference
      fee
      courier {
        name
        rating
        vehicleType
        vehicleMake
        vehicleModel
        vehicleColor
        phoneNumber
        imgHref
        location {
          lat
          lng
        }
      }
      trackingUrl
      undeliverableAction
      undeliverableReason
      cancelledReason
      deliverableAction
      courierNotes
      amount
      description
      processStatus
      processStatusMessage
      processStatusLastCheckedAt
      processlogs
      shouldBeSentToUberAt
      uberQuotePayload
      uberOrderPayload
      note
      orderId
      elderId
      restaurantId
      clientId
      deliveryStaffId
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createUploadedOrder = /* GraphQL */ `
  mutation CreateUploadedOrder(
    $input: CreateUploadedOrderInput!
    $condition: ModelUploadedOrderConditionInput
  ) {
    createUploadedOrder(input: $input, condition: $condition) {
      id
      clientId
      restaurantId
      orderFileS3Key
      fileName
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateUploadedOrder = /* GraphQL */ `
  mutation UpdateUploadedOrder(
    $input: UpdateUploadedOrderInput!
    $condition: ModelUploadedOrderConditionInput
  ) {
    updateUploadedOrder(input: $input, condition: $condition) {
      id
      clientId
      restaurantId
      orderFileS3Key
      fileName
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteUploadedOrder = /* GraphQL */ `
  mutation DeleteUploadedOrder(
    $input: DeleteUploadedOrderInput!
    $condition: ModelUploadedOrderConditionInput
  ) {
    deleteUploadedOrder(input: $input, condition: $condition) {
      id
      clientId
      restaurantId
      orderFileS3Key
      fileName
      orderIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      username
      isActive
      email
      notificationToken
      notificationTokenDeliveryStaff
      notificationTokenRestaurantOwner
      notificationTokenClient
      notificationTokenAdmin
      device {
        brand
        manufacturer
        modelName
        osName
        osVersion
        osBuildId
        appVersion
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      groups {
        items {
          username
          group
          adminId
          deliveryStaffId
          restaurantOwnerId
          clientId
          organizationId
          facilityIds
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      username
      isActive
      email
      notificationToken
      notificationTokenDeliveryStaff
      notificationTokenRestaurantOwner
      notificationTokenClient
      notificationTokenAdmin
      device {
        brand
        manufacturer
        modelName
        osName
        osVersion
        osBuildId
        appVersion
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      groups {
        items {
          username
          group
          adminId
          deliveryStaffId
          restaurantOwnerId
          clientId
          organizationId
          facilityIds
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      username
      isActive
      email
      notificationToken
      notificationTokenDeliveryStaff
      notificationTokenRestaurantOwner
      notificationTokenClient
      notificationTokenAdmin
      device {
        brand
        manufacturer
        modelName
        osName
        osVersion
        osBuildId
        appVersion
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      groups {
        items {
          username
          group
          adminId
          deliveryStaffId
          restaurantOwnerId
          clientId
          organizationId
          facilityIds
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
        nextToken
      }
    }
  }
`;
export const createUserGroup = /* GraphQL */ `
  mutation CreateUserGroup(
    $input: CreateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    createUserGroup(input: $input, condition: $condition) {
      username
      group
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateUserGroup = /* GraphQL */ `
  mutation UpdateUserGroup(
    $input: UpdateUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    updateUserGroup(input: $input, condition: $condition) {
      username
      group
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteUserGroup = /* GraphQL */ `
  mutation DeleteUserGroup(
    $input: DeleteUserGroupInput!
    $condition: ModelUserGroupConditionInput
  ) {
    deleteUserGroup(input: $input, condition: $condition) {
      username
      group
      adminId
      deliveryStaffId
      restaurantOwnerId
      clientId
      organizationId
      facilityIds
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const createWebhookEvent = /* GraphQL */ `
  mutation CreateWebhookEvent(
    $input: CreateWebhookEventInput!
    $condition: ModelWebhookEventConditionInput
  ) {
    createWebhookEvent(input: $input, condition: $condition) {
      id
      vendor
      headers
      body
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const updateWebhookEvent = /* GraphQL */ `
  mutation UpdateWebhookEvent(
    $input: UpdateWebhookEventInput!
    $condition: ModelWebhookEventConditionInput
  ) {
    updateWebhookEvent(input: $input, condition: $condition) {
      id
      vendor
      headers
      body
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const deleteWebhookEvent = /* GraphQL */ `
  mutation DeleteWebhookEvent(
    $input: DeleteWebhookEventInput!
    $condition: ModelWebhookEventConditionInput
  ) {
    deleteWebhookEvent(input: $input, condition: $condition) {
      id
      vendor
      headers
      body
      expirationUnixTime
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const quoteOrder = /* GraphQL */ `
  mutation QuoteOrder($input: QuoteOrderRequest!) {
    quoteOrder(input: $input) {
      data {
        orderQuoteId
        code
        message
        distance
        deliveryStaffFee
        startAddress
        endAddress
        overviewPolyline
        queryAddressMethod
      }
    }
  }
`;
export const quoteMultiStopOrder = /* GraphQL */ `
  mutation QuoteMultiStopOrder($input: QuoteMultiStopOrderRequest!) {
    quoteMultiStopOrder(input: $input) {
      data {
        orderQuoteId
        code
        message
        distance
        deliveryStaffFee
        deliveryQueue
      }
    }
  }
`;
export const queryAddress = /* GraphQL */ `
  mutation QueryAddress($input: QueryAddressRequest!) {
    queryAddress(input: $input) {
      success
      data {
        queryAddressMethod
        address
        lat
        lng
      }
    }
  }
`;
export const queryPublicOrder = /* GraphQL */ `
  mutation QueryPublicOrder($input: queryPublicOrderRequest!) {
    queryPublicOrder(input: $input) {
      errors
      message
      data
    }
  }
`;
export const registerDeliveryStaffOptions = /* GraphQL */ `
  mutation RegisterDeliveryStaffOptions(
    $input: registerDeliveryStaffOptionsRequest!
  ) {
    registerDeliveryStaffOptions(input: $input) {
      errors
      message
      data
    }
  }
`;
