import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoSelect from 'forms/AdminBulkOrderForm/AutoSelect';
import Chip from '@material-ui/core/Chip';
import { getRestaurantMealsByRestaurantByIsActive } from 'forms/AdminBulkOrderForm/queries';
import { getRestaurantIdSchema } from 'forms/schemas';
import { asyncListAll } from 'utilities/graph';
import cache from 'utilities/cache';

export default function RestaurantMealTagSelect({ onUpdate, tagOptions, mode='add', data: inData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [restaurantMealOptions, setRestaurantMealOptions] = useState({});
  const [item, setItem] = useState({ mealItemName: '', tags: [] });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const clientId = cache.get('app:facilityId');
      const restaurantIds = await getRestaurantIdSchema(null, null, clientId, false, false);
      setRestaurantOptions(restaurantIds.enum.map((id, index) => ({
        id,
        name: restaurantIds.enumNames[index],
      })));
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setItem({
      ...item,
      tags: [],
    });
  }, [tagOptions]);

  useEffect(() => {
    if (inData?.restaurantId) {
      (async () => {
        const mealOptions = await asyncListAll(
          getRestaurantMealsByRestaurantByIsActive, { restaurantId: inData?.restaurantId });
        restaurantMealOptions[inData?.restaurantId] = mealOptions;
        setItem(inData);
        setRestaurantMealOptions({ ...restaurantMealOptions });
      })();
    }
  }, [inData]);

  return (
    <Grid container spacing={1} alignContent='flex-end' alignItems='flex-end'>
      <Grid item xs={3}>
        <Autocomplete
          id={'restaurantSelect'}
          sx={{ width: 500 }}
          options={restaurantOptions}
          loading={isLoading}
          defaultValue={ inData?.restaurantId ? { id: inData.restaurantId, name: inData.restaurantName } : undefined}
          onChange={async (event, value = {})=> {
            const newRestaurantId = value.id;
            if (newRestaurantId && (!restaurantMealOptions[newRestaurantId])) {
              const mealOptions = await asyncListAll(
                getRestaurantMealsByRestaurantByIsActive, { restaurantId: newRestaurantId });
              restaurantMealOptions[newRestaurantId] = mealOptions;
              setRestaurantMealOptions({ ...restaurantMealOptions });
            }
            setItem({
              ...item,
              restaurantId: value.id,
              restaurantName: value.name,
              mealItemName: '',
            });
          }}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label={t('餐廳')}
              variant="standard"
            />
          )}
          noOptionsText='沒有符合所輸入的選項'
        />
      </Grid>
      <Grid item xs={3}>
        <AutoSelect
          id={'mealItem-name'}
          options={restaurantMealOptions[item.restaurantId] && restaurantMealOptions[item.restaurantId].map(({ name }) => name)}
          values={restaurantMealOptions[item.restaurantId] && restaurantMealOptions[item.restaurantId].map(({ id }) => id)}
          required={false}
          freeSolo
          value={item.mealItemName}
          label={`${t('餐點')}名稱`}
          onChange={(value)=>{
            const restaruantMeal = restaurantMealOptions[item.restaurantId].find(({ id }) => id === value);
            if (restaruantMeal) {
              const { name } = restaruantMeal;
              setItem({
                ...item,
                mealItemName: name,
              });
            } else {
              setItem({
                ...item,
                mealItemName: value,
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={tagOptions && tagOptions.map(({ label }) => label)}
          values={tagOptions && tagOptions.map(({ id }) => id)}
          defaultValue={[]}
          value={item.tags}
          disableCloseOnSelect
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} label={'飲食標籤'} placeholder="" />
          )}
          onChange={(event, values) => {
            setItem({
              ...item,
              tags: values,
            });
          }}
          fullWidth
          noOptionsText='沒有符合所輸入的選項'
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={!item.restaurantId || !item.mealItemName || !item.tags || item.tags.length === 0}
          onClick={() => {
            onUpdate && onUpdate(item);
          }}
          fullWidth
        >
          {mode === 'add' ? '新增統計項目' : '修改統計項目'}
        </Button>
      </Grid>
    </Grid>);
}

RestaurantMealTagSelect.propTypes = {
  tagOptions: PropTypes.array,
  onUpdate: PropTypes.func,
  mode: PropTypes.string,
  data: PropTypes.object,
};
