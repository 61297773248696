/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormControl from '@material-ui/core/FormControl';

import { Colors } from '@silvergatedelivery/constants';
import MealDateSelector from 'components/Date/MealDateSelector';
import { asyncListAll } from 'utilities/graph';
import AutoSelect from './AutoSelect';
import { sortBy } from 'utilities/sorting';
import OrderItem from './OrderItem';
import { getSampleOrder, ORDER_CATEGORY } from './helpers';
import {
  getRestaurantHolidayByRestaurantByYear,
  getRestaurantMealsByRestaurantByIsActive,
} from './queries';
import moment from 'moment';
import { TIME_ZONE } from '@silvergatedelivery/constants';
import DataJoinEditorInput from 'components/DataJoinEditor/DataJoinEditorInput';

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    width: 40,
  },
  itemSummaryContainer: {
    display: 'inline-block',
    width: 32,
    paddingTop: 12,
    fontWeight: 'bold',
  },
  itemContainer: {
    display: 'inline-block',
    flex: 1,
  },
  floatingCloseButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
}));

export default function OrderBatchForm({
  index,
  // targetClientId,
  onRemove,
  onUpdate,
  showMap,
  unlockDates,
  deliveryGroupOptions = [],
  elderDeliveryGroups = [],
  elderOptions = [],
  restaurantOptions = [],
  deliveryStaffOptions = {},
  orderConfigurations = [],
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deliveryGroupId, setDeliveryGroupId] = useState();
  const [defaultRestaurantId, setDefaulRestaurantId] = useState();
  const [restaurantMealOptions, setRestaurantMealOptions] = useState({});
  const [restaurantHolidays, setRestaurantHolidays] = useState({});
  const [defaultMealItemNames, setDefaultMealItemNames] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState(ORDER_CATEGORY[0]);
  const [defaultDeliveryStaffId, setDefaultDeliveryStaffId] = useState();
  const [defaultLunchs, setDefaultLunchs] = useState([]);
  const [defaultDinners, setDefaultDinners] = useState([]);
  const [openingHours, setOpeningHours] = useState();

  const [orders, setOrders] = useState([]);

  const handleUpdate = (orders) => {
    if (onUpdate) {
      onUpdate({
        deliveryGroupId,
        defaultRestaurantId,
        defaultCategory,
        defaultDeliveryStaffId,
        defaultLunchs,
        defaultDinners,
        orders,
      });
    }
  };

  // useEffect(() => {
  //   if (targetClientId === 'N/A') {
  //     setOrders([getSampleOrder()]);
  //   } else {
  //     const orders = elderOptions.map(({ id, noteForDelivery, noteForMeal, note }) => {
  //       return getSampleOrder({
  //         elderId: id,
  //         noteForDelivery,
  //         noteForMeal,
  //         note,
  //       });
  //     });
  //     setOrders(orders);
  //   }
  // }, [targetClientId, elderOptions]);

  return (
    <Grid item xs={12} container style={{ marginTop: 16, borderBottom: `1px solid ${Colors.light2}`, position: 'relative' }}>
      <IconButton
        size={'medium'}
        className={classes.floatingCloseButton}
        onClick={() => {
          if (onRemove) {
            onRemove();
          }
        }}
      >
        <HighlightOffIcon />
      </IconButton>
      <div className={classes.itemContainer}>
        <Grid container>
          <Grid item xs={showMap ? 4 : 6}>
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <AutoSelect
                id={`deliveryGroup-${index}`}
                options={deliveryGroupOptions.map(({ name }) => name)}
                values={deliveryGroupOptions.map(({ id }) => id)}
                required={false}
                value={deliveryGroupId}
                label={t('送餐群組')}
                onChange={(newItem) => {
                  setOrders([]);

                  setDeliveryGroupId(newItem);

                  const orders = elderDeliveryGroups
                    .filter(({ deliveryGroupId }) => deliveryGroupId === newItem)
                    .sort((sortBy('sortOrder')))
                    .map(({ elderId, sortOrder }) => {
                      const elder = elderOptions.find(({ id }) => id === elderId);
                      if (elder) {
                        const { id, noteForDelivery, noteForMeal, note } = elder;
                        return getSampleOrder({
                          elderId: id,
                          noteForDelivery,
                          noteForMeal,
                          note,
                          deliveryGroupId: newItem,
                          deliveryGroupSortOrder: sortOrder,
                        });
                      } else {
                        // 長輩不存在 或 已停用
                        return null;
                      }
                    })
                    .filter((x) => x);

                  setTimeout(()=>{
                    setOrders([...orders]);
                    handleUpdate(orders);
                  }, 200);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <AutoSelect
                id={`restaurant-${index}`}
                options={restaurantOptions.map(({ address, name }) => (`${address.county} - ${name}`))}
                values={restaurantOptions.map(({ id }) => id)}
                required={true}
                value={defaultRestaurantId}
                label={`預設${t('餐廳')}`}
                onChange={async (newItem)=>{
                  setDefaulRestaurantId(newItem);
                  const restaurantSelected = restaurantOptions.find((restaurantOption) => restaurantOption.id === newItem);
                  setOpeningHours(restaurantSelected ? restaurantSelected.openingHours : undefined);
                  if (newItem && (!restaurantMealOptions[newItem] || !restaurantHolidays[newItem])) {
                    const start = moment().tz(TIME_ZONE).year();
                    const end = start + 1;
                    const [mealOptions, holidays] = await Promise.all([
                      await asyncListAll(getRestaurantMealsByRestaurantByIsActive, { restaurantId: newItem }),
                      await asyncListAll(getRestaurantHolidayByRestaurantByYear, {
                        restaurantId: newItem,
                        year: {
                          between: [start, end],
                        },
                      }),
                    ]);
                    restaurantMealOptions[newItem] = mealOptions;
                    setRestaurantMealOptions({ ...restaurantMealOptions });
                    restaurantHolidays[newItem] = holidays;
                    setRestaurantHolidays({ ...restaurantHolidays });
                  }
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <DataJoinEditorInput
                title={`預設${t('餐點')}名稱`}
                options={restaurantMealOptions[defaultRestaurantId] ?
                  restaurantMealOptions[defaultRestaurantId].map(({ name }) => ({ label: name })) : []}
                defaultValues={[]}
                onChange={(newMealItemNames) => setDefaultMealItemNames(newMealItemNames.map(({ label }) => label))}
                onUpdateOptions={() => {}}
                showHelperText={false}
                disabled={!defaultRestaurantId}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <AutoSelect
                id={`category-${index}`}
                options={ORDER_CATEGORY}
                values={ORDER_CATEGORY}
                required={false}
                freeSolo
                value={defaultCategory}
                label="預設訂單類別"
                onChange={(value)=>{
                  setDefaultCategory(value);
                }}
              />
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <AutoSelect
                id={`deliveryStaff-${index}`}
                options={deliveryStaffOptions.enumNames}
                values={deliveryStaffOptions.enum}
                required={false}
                value={defaultDeliveryStaffId}
                label={`預設${t('送餐大使')}`}
                onChange={(newItem)=>{
                  setDefaultDeliveryStaffId(newItem);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <MealDateSelector
              mealSlot="lunch"
              unlockDates={unlockDates}
              openingHours={openingHours}
              holidays={restaurantHolidays[defaultRestaurantId]}
              onChange={(items)=>{
                setDefaultLunchs(items);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MealDateSelector
              mealSlot="dinner"
              unlockDates={unlockDates}
              openingHours={openingHours}
              holidays={restaurantHolidays[defaultRestaurantId]}
              onChange={(items)=>{
                setDefaultDinners(items);
              }}
            />
          </Grid>
        </Grid>

        {orders.map((order, orderIndex) => (
          <React.Fragment key={orderIndex}>
            <OrderItem
              index={orderIndex}
              order={order}
              onRemove={() => {
                orders.splice(orderIndex, 1);
                setOrders([...orders]);
                handleUpdate(orders);
              }}
              onUpdate={(updatedOrder) => {
                order = updatedOrder;
                handleUpdate(orders);
              }}
              elderOptions={elderOptions}
              restaurantOptions={restaurantOptions}
              restaurantMealOptions={restaurantMealOptions}
              deliveryStaffOptions={deliveryStaffOptions}
              orderConfigurations={orderConfigurations}
              defaultLunchs={defaultLunchs}
              defaultDinners={defaultDinners}
              defaultRestaurantId={defaultRestaurantId}
              defaultDeliveryStaffId={defaultDeliveryStaffId}
              defaultMealItemNames={defaultMealItemNames}
              defaultCategory={defaultCategory}
              defaultOpeningHours={openingHours}
              defaultRestaurantHolidays={restaurantHolidays}
              showMap={showMap}
              unlockDates={unlockDates}
            />
          </React.Fragment>
        ))}

        <div style={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ height: 24, margin: 24 }}
            onClick={() => {
              orders.push(getSampleOrder());
              setOrders([...orders]);
              handleUpdate(orders);
            }}
          >
            {`新增${t('送餐對象')}`}
          </Button>
        </div>
      </div>
    </Grid>);
}
