import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { Colors } from '@silvergatedelivery/constants';
import MealDateSelector from 'components/Date/MealDateSelector';
import AutoSelect from './AutoSelect';
import Directions from 'components/Map/Directions';

import { ORDER_CATEGORY, getDefaultMealItem } from './helpers';
import { asyncListAll } from 'utilities/graph';
import { getRestaurantHolidayByRestaurantByYear, getRestaurantMealsByRestaurantByIsActive } from './queries';
import moment from 'moment';
import { TIME_ZONE } from '@silvergatedelivery/constants';

export default function OrderItem({
  index = 0,
  order,
  onRemove,
  onUpdate,
  elderOptions = [],
  restaurantOptions = [],
  restaurantMealOptions: inRestaurantMealOptions = {},
  deliveryStaffOptions = {},
  orderConfigurations = [],
  defaultLunchs = [],
  defaultDinners = [],
  defaultRestaurantId,
  defaultDeliveryStaffId,
  defaultMealItemNames,
  defaultCategory,
  defaultOpeningHours,
  defaultRestaurantHolidays,
  showMap = false,
  unlockDates = false,
}) {
  const { t } = useTranslation();
  const [elderId, setElderId] = useState();
  const [noteForDelivery, setNoteForDelivery] = useState();
  const [noteForMeal, setNoteForMeal] = useState();
  const [category, setCategory] = useState(ORDER_CATEGORY[0]);
  const [paymentMethod, setPaymentMethod] = useState('PAID');
  const [deliveryStaffFee, setDeliveryStaffFee] = useState(0);
  const [deliveryStaffId, setDeliveryStaffId] = useState();
  const [restaurantId, setRestaurantId] = useState();
  const [mealItems, setMealItems] = useState([getDefaultMealItem()]);
  const [lunchs, setLunchs] = useState([]);
  const [dinners, setDinners] = useState([]);
  const [restaurantHolidays, setRestaurantHolidays] = useState({});
  const [openingHours, setOpeningHours] = useState();
  const [restaurantMealOptions, setRestaurantMealOptions] = useState([]);
  const [isLalamoveMultiStopOrder, setIsLalamoveMultiStopOrder] = useState(false);

  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }
  };

  const handleUpdate = (obj = {}) => {
    if (onUpdate) {
      onUpdate(Object.assign(order, obj));
    }
  };

  const getConfigurationValues = (inConfigurationKey) => {
    try {
      const items = orderConfigurations.find(({ key }) => key === inConfigurationKey).value.split(',').map((x) => parseInt(x, 10));
      return items;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    setLunchs([...defaultLunchs]);
    handleUpdate({ lunchs: [...defaultLunchs] });
  }, [defaultLunchs]);

  useEffect(() => {
    setDinners([...defaultDinners]);
    handleUpdate({ dinners: [...defaultDinners] });
  }, [defaultDinners]);

  useEffect(() => {
    setRestaurantId(defaultRestaurantId);
    handleUpdate({ restaurantId: defaultRestaurantId });
  }, [defaultRestaurantId]);

  useEffect(() => {
    let lalamoveMultiStopOrder = false;
    if (defaultDeliveryStaffId) {
      const deliveryStaffIndex = deliveryStaffOptions.enum.findIndex((value) => value === defaultDeliveryStaffId);
      if (deliveryStaffIndex !== -1) {
        const deliveryStaffName = deliveryStaffOptions.enumNames[deliveryStaffIndex];
        const regex = /^\[\d{1,4}\] lalamove\(multi-stop\)$/;
        if (regex.test(deliveryStaffName)) {
          lalamoveMultiStopOrder = true;
        }
      }
    }
    setIsLalamoveMultiStopOrder(lalamoveMultiStopOrder);
    setDeliveryStaffId(defaultDeliveryStaffId);
    handleUpdate({ deliveryStaffId: defaultDeliveryStaffId });
  }, [defaultDeliveryStaffId]);

  useEffect(() => {
    setCategory(defaultCategory);
    handleUpdate({ category: defaultCategory });
  }, [defaultCategory]);

  useEffect(() => {
    setOpeningHours(defaultOpeningHours);
  }, [defaultOpeningHours]);

  useEffect(() => {
    setRestaurantHolidays({ ...restaurantHolidays, ...defaultRestaurantHolidays });
  }, [defaultRestaurantHolidays]);

  useEffect(() => {
    setRestaurantMealOptions({ ...restaurantMealOptions, ...inRestaurantMealOptions });

    if (!defaultRestaurantId || !defaultMealItemNames || !defaultMealItemNames.length === 0) return;

    const options = inRestaurantMealOptions[defaultRestaurantId];
    if (!options) return;

    const mealItems = [];
    defaultMealItemNames.forEach((defaultMealItemName) => {
      const matchedMealItem = options.find(({ name }) => name === defaultMealItemName);
      const cost = (matchedMealItem ? matchedMealItem.cost : 0) || 0;
      const price = (matchedMealItem ? matchedMealItem.price : 0) || 0;
      const surcharges = (matchedMealItem ? matchedMealItem.surcharges : []) || [];
      mealItems.push({
        name: defaultMealItemName,
        quantity: 1,
        price,
        cost,
        note: '',
        surcharges,
      });
    });

    setMealItems(mealItems);
    handleUpdate({ mealItems });
  }, [defaultMealItemNames, defaultRestaurantId, inRestaurantMealOptions]);

  useEffect(() => {
    if (!order) return;

    setElderId(order.elderId);
    setNoteForDelivery(order.noteForDelivery || '');
    setNoteForMeal(order.noteForMeal || '');
    setCategory(order.category);
    setPaymentMethod(order.paymentMethod);
    setDeliveryStaffFee(order.deliveryStaffFee);
    setDeliveryStaffId(order.deliveryStaffId);
    setRestaurantId(order.restaurantId);
    setLunchs(order.lunchs);
    setDinners(order.dinners);
    setMealItems(order.mealItems);
  }, [order, elderId]);

  if (!order) return null;

  return (
    <Grid container spacing={1} style={{ padding: 8, backgroundColor: Colors.highlight, marginTop: 10, marginBottom: 5 }}>
      <Grid item md={10}>
        {index + 1}
      </Grid>
      <Grid item md={2} container justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          style={{ height: 24 }}
          onClick={handleRemove}
        >
          取消
        </Button>
      </Grid>
      <Grid item md={showMap ? 4 : 6} container spacing={1}>
        <Grid container spacing={1}>
          <Grid item md={5}>
            <FormControl fullWidth={true}>
              <AutoSelect
                id={`elder-${index}`}
                options={elderOptions.map(({ name }) => name)}
                values={elderOptions.map(({ id }) => id)}
                value={elderId}
                required={true}
                label={`${t('送餐對象')}`}
                onChange={(elderId) =>{
                  const matched = elderOptions.find(({ id }) => id === elderId);

                  if (!matched) {
                    setElderId(null);
                    setNoteForDelivery('');
                    setNoteForMeal('');
                    handleUpdate({
                      elderId: null,
                      noteForDelivery: '',
                      noteForMeal: '',
                    });
                    return;
                  }
                  const noteForDelivery = matched.noteForDelivery || '';
                  const noteForMeal = matched.noteForMeal || '';

                  setElderId(elderId);
                  setNoteForDelivery(noteForDelivery);
                  setNoteForMeal(noteForMeal);

                  handleUpdate({
                    elderId,
                    noteForDelivery,
                    noteForMeal,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <FormControl fullWidth={true}>
              <AutoSelect
                id={`category-${index}`}
                options={ORDER_CATEGORY}
                values={ORDER_CATEGORY}
                value={category}
                required={true}
                label="類別"
                onChange={(category) => {
                  setCategory(category);
                  handleUpdate({
                    category,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth={true}>
              <AutoSelect
                id={`paymentMethod-${index}`}
                options={['已付款', '到府收款']}
                values={['PAID', 'CASH_ON_DELIVERY']}
                value={paymentMethod}
                required={true}
                label="付款方式"
                onChange={(paymentMethod) => {
                  setPaymentMethod(paymentMethod);
                  handleUpdate({
                    paymentMethod,
                  });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={2}>
            <div>
              <AutoSelect
                id={`deliveryFee`}
                fullWidth
                type="number"
                options={getConfigurationValues('送餐費用選單').map((x) => `${x}`)}
                getOptionLabel={(x) => `${x}`}
                values={getConfigurationValues('送餐費用選單')}
                value={deliveryStaffFee}
                required={true}
                label={t('送餐費用')}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                freeSolo={true}
                onChange={(x) => {
                  const value = x ? parseFloat(x) : '';
                  setDeliveryStaffFee(value);
                  handleUpdate({
                    deliveryStaffFee: value,
                  });
                }}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={5}>
            <FormControl fullWidth={true}>
              <AutoSelect
                id={`deliveryStaff-${index}`}
                options={deliveryStaffOptions.enumNames}
                values={deliveryStaffOptions.enum}
                required={false}
                value={deliveryStaffId}
                label={t('送餐大使')}
                onChange={(deliveryStaffId) => {
                  setDeliveryStaffId(deliveryStaffId);
                  handleUpdate({
                    deliveryStaffId,
                  });
                }}
                disabled={isLalamoveMultiStopOrder}
              />
            </FormControl>
          </Grid>
          <Grid item md={7}>
            <TextField
              fullWidth={true}
              type="text"
              value={noteForDelivery}
              label={`${t('送餐')}備註`}
              InputLabelProps={{ shrink: true }}
              onChange={(e)=>{
                const noteForDelivery = e.target.value;

                setNoteForDelivery(noteForDelivery);
                handleUpdate({
                  noteForDelivery,
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={5}>
            <FormControl fullWidth={true}>
              <AutoSelect
                id={`restaurant-${index}`}
                options={restaurantOptions.map(({ name }) => name)}
                values={restaurantOptions.map(({ id }) => id)}
                required={true}
                value={restaurantId}
                label={t('餐廳')}
                onChange={async (restaurantId) => {
                  setRestaurantId(restaurantId);
                  const restaurantSelected = restaurantOptions.find((restaurantOption) => restaurantOption.id === restaurantId);
                  setOpeningHours(restaurantSelected ? restaurantSelected.openingHours : undefined);
                  if (restaurantId && !restaurantHolidays[restaurantId]) {
                    const start = moment().tz(TIME_ZONE).year();
                    const end = start + 1;
                    const holidays = await asyncListAll(getRestaurantHolidayByRestaurantByYear, {
                      restaurantId: restaurantId,
                      year: {
                        between: [start, end],
                      },
                    });
                    restaurantHolidays[restaurantId] = holidays;
                    setRestaurantHolidays({ ...restaurantHolidays });

                    if (!restaurantMealOptions[restaurantId]) {
                      const restaurantMeals =
                        await asyncListAll(getRestaurantMealsByRestaurantByIsActive, { restaurantId }) || [];
                      if (restaurantMeals.length !== 0) {
                        setRestaurantMealOptions({
                          ...restaurantMealOptions,
                          [restaurantId]: restaurantMeals,
                        });
                      }
                    }
                  }
                  handleUpdate({ restaurantId });
                }}
                disabled={isLalamoveMultiStopOrder}
              />
            </FormControl>
          </Grid>
          <Grid item md={7}>
            <TextField
              fullWidth={true}
              type="text"
              value={noteForMeal}
              label={`${t('餐點')}備註`}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>{
                const noteForMeal = e.target.value;
                setNoteForMeal(noteForMeal);
                handleUpdate({
                  noteForMeal,
                });
              }}
            />
          </Grid>
        </Grid>
        {mealItems && mealItems.map((mealItem, index) => {
          return (
            <Grid container spacing={1} key={`mealItem-${index}`}>
              <Grid item md={4}>
                <AutoSelect
                  options={restaurantMealOptions[restaurantId] ? restaurantMealOptions[restaurantId].map(({ name }) => name) : []} // eslint-disable-line max-len
                  values={restaurantMealOptions[restaurantId] ? restaurantMealOptions[restaurantId].map(({ name }) => name) : []} // eslint-disable-line max-len
                  required={true}
                  freeSolo
                  value={mealItem.name}
                  label={`${t('餐點')}名稱`}
                  onChange={(value)=>{
                    const options = restaurantMealOptions[restaurantId] || [];
                    const matchedMealItem = options.find(({ name }) => name === value);
                    const cost = (matchedMealItem ? matchedMealItem.cost : 0) || 0;
                    const price = (matchedMealItem ? matchedMealItem.price : 0) || 0;
                    const surcharges = (matchedMealItem ? matchedMealItem.surcharges : []) || [];
                    mealItems[index] = {
                      name: value,
                      quantity: mealItems[index].quantity || 1,
                      price,
                      cost,
                      note: '',
                      surcharges,
                    };
                    setMealItems([...mealItems]);
                    handleUpdate({ mealItems });
                  }}
                />
              </Grid>
              <Grid item md={1}>
                <AutoSelect
                  id={`mealItem-quantity`}
                  fullWidth
                  type="number"
                  options={getConfigurationValues('餐點數量選單').map((x) => `${x}`)}
                  getOptionLabel={(x) => `${x}`}
                  values={getConfigurationValues('餐點數量選單')}
                  value={mealItem.quantity}
                  required={true}
                  label="數量"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 0 }}
                  freeSolo={true}
                  onChange={(x) => {
                    const value = x ? parseFloat(x) : '';
                    mealItems[index].quantity = value;
                    setMealItems([...mealItems]);
                    handleUpdate({ mealItems });
                  }}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Grid>
              <Grid item md={2}>
                <AutoSelect
                  id={`mealItem-cost`}
                  fullWidth
                  type="number"
                  options={getConfigurationValues('餐點進價選單').map((x) => `${x}`)}
                  getOptionLabel={(x) => `${x}`}
                  values={getConfigurationValues('餐點進價選單')}
                  value={mealItem.cost}
                  required={true}
                  label="進價"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 0 }}
                  freeSolo={true}
                  onChange={(x) => {
                    const value = x ? parseFloat(x) : '';
                    mealItems[index].cost = value;
                    setMealItems([...mealItems]);
                    handleUpdate({ mealItems });
                  }}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Grid>
              <Grid item md={2}>
                <AutoSelect
                  id={`mealItem-price`}
                  fullWidth
                  type="number"
                  options={getConfigurationValues('餐點價格選單').map((x) => `${x}`)}
                  getOptionLabel={(x) => `${x}`}
                  values={getConfigurationValues('餐點價格選單')}
                  value={mealItem.price}
                  required={true}
                  label="單價"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 0 }}
                  freeSolo={true}
                  onChange={(x) => {
                    const value = x ? parseFloat(x) : '';
                    mealItems[index].price = value;
                    setMealItems([...mealItems]);
                    handleUpdate({ mealItems });
                  }}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </Grid>
              <Grid container item md={3}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    type="text"
                    value={mealItem.note}
                    label={'註記'}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) =>{
                      const value = event.target.value;
                      mealItems[index].note = value;
                      setMealItems([...mealItems]);
                      handleUpdate({ mealItems });
                    }}
                  />
                </Grid>
                {mealItems.length > 1 &&
                <IconButton
                  size={'small'}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                  onClick={() => {
                    mealItems.splice(index, 1);
                    setMealItems([...mealItems]);
                    handleUpdate({ mealItems });
                  }}
                >
                  <ClearIcon />
                </IconButton>}
              </Grid>
            </Grid>);
        })}
        <Grid container item md={12} justifyContent='center'>
          <Button
            aria-label={'add meal item'}
            size={'small'}
            variant={'text'}
            color={'secondary'}
            style={{ height: '36px' }}
            onClick={() => {
              mealItems.push(getDefaultMealItem());
              setMealItems([...mealItems]);
              handleUpdate({ mealItems });
            }}
          >
            <AddIcon fontSize="small" />
            新增餐點
          </Button>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <MealDateSelector
          mealSlot="lunch"
          unlockDates={unlockDates}
          defaultSelectedDays={lunchs}
          forceUpdateDefaultSelectedDays={true}
          openingHours={openingHours}
          holidays={restaurantHolidays[restaurantId]}
          onChange={(lunchs)=>{
            setLunchs([...lunchs]);
            handleUpdate({
              lunchs,
            });
          }}
        />
      </Grid>
      <Grid item md={3}>
        <MealDateSelector
          mealSlot="dinner"
          unlockDates={unlockDates}
          defaultSelectedDays={dinners}
          forceUpdateDefaultSelectedDays={true}
          openingHours={openingHours}
          holidays={restaurantHolidays[restaurantId]}
          onChange={(dinners)=>{
            setDinners([...dinners]);
            handleUpdate({
              dinners,
            });
          }}
          disabled={isLalamoveMultiStopOrder}
        />
      </Grid>
      {showMap && <Grid item md={2}>
        <Directions
          startAddress={restaurantOptions.find(({ id }) => id === restaurantId)?.address}
          endAddress={elderOptions.find(({ id }) => id === elderId)?.address}
          options={{ height: 300, width: '100%' }}
        />
      </Grid>}
    </Grid>);
}

OrderItem.propTypes = {
  index: PropTypes.number,
  order: PropTypes.any,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
  elderOptions: PropTypes.array,
  restaurantOptions: PropTypes.array,
  restaurantMealOptions: PropTypes.object,
  deliveryStaffOptions: PropTypes.object,
  orderConfigurations: PropTypes.array,
  defaultLunchs: PropTypes.array,
  defaultDinners: PropTypes.array,
  defaultRestaurantId: PropTypes.string,
  defaultDeliveryStaffId: PropTypes.string,
  defaultCategory: PropTypes.string,
  defaultOpeningHours: PropTypes.object,
  defaultRestaurantHolidays: PropTypes.object,
  showMap: PropTypes.bool,
  unlockDates: PropTypes.bool,
  defaultMealItemNames: PropTypes.array,
};
