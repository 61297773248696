import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Authenticator,
  useAuthenticator,
  CheckboxField,
  Autocomplete,
} from '@aws-amplify/ui-react';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import querystring from 'query-string';
import { Auth, Hub, I18n } from 'aws-amplify';
import { dict } from 'i18n/Amplify';
// import liff from '@line/liff';
import { SHOP_PREFIX } from '@silvergatedelivery/constants';

import {
  // Colors,
  // SHORT_NAME,
  // authErrorCodes,
  counties,
} from '@silvergatedelivery/constants';
import cache from 'utilities/cache';

export default function CustomAuthenticator({
  children = <React.Fragment />,
  initialAuthState: inAuthState,
  currentAuthState,
}) {
  const [authState, setAuthState] = useState();
  const shopClientMode = window.location.hostname.startsWith(SHOP_PREFIX);

  const components = {
    SignIn: {
      Header() {
        Hub.dispatch('app', { event: 'signIn_view', data: { currentAuthState } });
        if (!shopClientMode) {
          return <></>;
        }
        return <>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 16 }}>
            <Button
              style={{ width: '100%', marginLeft: 32, marginRight: 32 }}
              variant="outlined"
              onClick={async () => {
                try {
                  // 1. 使用 Line Login API的 workflow
                  await Auth.federatedSignIn({
                    provider: 'access.line.me',
                    customState: 'facilityIdHere',
                  });

                  // 2. 使用liff login的workflow
                  // 因為line liff需要設定合法的https網址，這是ngrok導向到Justin localhost
                  // liff.login({ redirectUri: 'https://0b46-2401-e180-8870-9104-dcc0-6964-2336-a86f.ngrok-free.app/' });
                  // console.log(cred);
                  // const authenticatedUser = await Auth.currentAuthenticatedUser();
                  // console.log(authenticatedUser);
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              <img
                src="/images/line_btn_base.png"
                alt="icon"
                style={{
                  marginRight: '10px',
                  width: '16px',
                }}
              />
              LINE 登入
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 32,
              marginRight: 32,
              marginTop: 16,
            }}
          >
            <div
              style={{
                flex: 1,
                borderBottom: '1px solid #ccc',
                marginRight: '16px',
              }}
            ></div>
            <Typography variant="body2" color='textSecondary' >或者</Typography>
            <div
              style={{
                flex: 1,
                borderBottom: '1px solid #ccc',
                marginLeft: '16px',
              }}
            ></div>
          </div>
        </>;
      },
    },
    SignUp: {
      FormFields() {
        const { validationErrors } = useAuthenticator();
        Hub.dispatch('app', { event: 'signUp_view', data: { currentAuthState } });

        return (
          <React.Fragment>
            <Authenticator.SignUp.FormFields />
            <Autocomplete
              label="所在縣市"
              options={counties.map((item)=>{
                return {
                  id: item,
                  label: item,
                };
              })}
              placeholder="所在縣市"
              onSelect={(option)=>{
                cache.set('app:location', option.id);
              }}
            />

            <CheckboxField
              errorMessage={validationErrors.acknowledgement}
              hasError={!!validationErrors.acknowledgement}
              name="acknowledgement"
              value="yes"
              label={
                <div style={{ fontSize: 14 }}>
                  我同意
                  <Link
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    variant="body2"
                    underline="always"
                  >
                    隱私權及保護政策
                  </Link>
                  與
                  <Link
                    href="/service-terms"
                    target="_blank"
                    rel="noopener"
                    variant="body2"
                    underline="always"
                  >
                    會員服務條款
                  </Link>
                </div>}
            />
          </React.Fragment>
        );
      },
    },
  };

  const services = {
    async validateCustomSignUp(formData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: '您必須同意隱私權及保護政策與會員服務條款',
        };
      }
    },
    async handleSignUp(formData) {
      const {
        username,
        password,
        attributes: {
          name,
          phone_number: phoneNumber,
        },
      } = formData;
      cache.set('app:phoneNumber', phoneNumber);

      const email = username.toLowerCase();
      const signUpResult = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      global.logger.debug('signUp', signUpResult);

      cache.set('app:isNewRegistered', 'yes');

      // const registerResult = await request(registerClient, {
      //   input: {
      //     username,
      //     name,
      //     addressCounty: location,
      //     addressZipCode: '無',
      //     addressDistrict: '無',
      //     addressStreet: '無',
      //     phoneNumber,
      //     email,
      //   },
      // });

      // global.logger.debug('registerClient', registerResult);

      return signUpResult;
    },
  };

  const formFields = {
    signUp: {
      username: {
        label: '電子信箱',
        isRequired: true,
        order: 1,
      },
      password: {
        label: '密碼',
        // placeholder: '密碼',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: '確認密碼',
        order: 3,
      },
      name: {
        label: '姓名',
        isRequired: true,
        order: 4,
      },
      phone_number: {
        label: '電話',
        isRequired: true,
        order: 5,
        dialCodeList: ['+886'],
      },
    },
  };

  useEffect(() => {
    I18n.putVocabularies(dict);

    const { state } = querystring.parse(window.location.search);
    setAuthState(state || 'signIn');
  }, []);

  useEffect(() => {
    if (!inAuthState) return;

    setAuthState(null);

    setTimeout(() => {
      if (inAuthState) {
        setAuthState(inAuthState);
      }
    });
  }, [inAuthState]);

  if (!authState) return null;

  return (
    <Authenticator
      initialState={authState}
      services={services}
      components={components}
      formFields={formFields}
      signUpAttributes={['username', 'password', 'name', 'phone_number']}
    >
      {() => children}
    </Authenticator>
  );
}

CustomAuthenticator.propTypes = {
  children: PropTypes.any,
  initialAuthState: PropTypes.string,
  currentAuthState: PropTypes.string,
};
