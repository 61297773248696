import { formatGender } from 'utilities/format';
import { utils, write } from 'sheetjs-style';
import JSZip from 'jszip';
import { download } from 'utilities/file';
import moment from 'moment-timezone';
import {
  TIME_ZONE,
} from '@silvergatedelivery/constants';

export default function writeVulnerable({
  elders,
  orders: allOrders,
  deliveryStaffs,
  pandagoOrders,
  selectedMonth = 'YYYY-MM',
  clientName,
  includeUndeliveredOrders = false,
  excludeNonFoodDeliveryOrders = false,
  county,
  restaurants = [],
  oneWayDeliveryStaffFee,
}) {
  let includedOrderStatus = ['delivered', 'completed'];
  if (includeUndeliveredOrders) {
    includedOrderStatus = includedOrderStatus.concat(['ready', 'readyForPickup', 'delivering']);
  }
  let orders = allOrders
    .filter(({ status }) => includedOrderStatus.includes(status));
  if (excludeNonFoodDeliveryOrders) {
    orders = orders.filter(({ category }) => category === '送餐' || category === '送餐和物資');
  }
  const zip = new JSZip();
  const [year, month] = selectedMonth.split('-');
  const yearMonthTitle = `${parseInt(year)-1911}年${month}月`;

  elders.sort((a, b) => a.name > b.name ? 1 : -1).forEach((elder) => {
    const elderOrders = orders
      .filter(({ elderId }) => elderId === elder.id);

    if (elderOrders.length === 0) {
      return;
    }

    // \ / ? * [ ]
    const sanitizedElderName = elder.name
      // .replace(/ /g, '')
      .replace(/\\/g, '')
      .replace(/\//g, '')
      .replace(/\?/g, '')
      .replace(/\*/g, '')
      .replace(/\[/g, '')
      .replace(/\]/g, '');

    let totalDeliveryStaffFee = 0;
    const rows = elderOrders
      .sort((a, b) => a.mealSlot < b.mealSlot ? 1 : -1)
      .sort((a, b) => a.deliveryBy > b.deliveryBy ? 1 : -1)
      .map((order) => {
        totalDeliveryStaffFee += order.deliveryStaffFee;
        return {
          '送餐對象': sanitizedElderName,
          // '餐廳': (restaurants.find(({ id }) => id === order.restaurantId) || {}).name || '',
          '日期': moment(order.deliveryBy).tz(TIME_ZONE).format('YYYY/MM/DD'),
          '時段': order.mealSlot === 'lunch' ? '午餐' : '晚餐',
          // '狀態': orderStatus.find(({ value }) => value === order.status).label,
          '距離': order.direction ? order.direction.distance : 'N/A',
          '送餐費用': order.deliveryStaffFee,
        };
      });

    const worksheet = utils.json_to_sheet(rows);
    utils.sheet_add_aoa(worksheet, [['共計趟數', rows.length, '共計費用', totalDeliveryStaffFee]], { origin: `B${rows.length + 2}` });
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, `${sanitizedElderName} 核銷報表`);
    const wbout = write(workbook, { bookType: 'xls', bookSST: true, type: 'binary' });

    zip.file(`午晚-${sanitizedElderName}.xls`, wbout, { binary: true });
  });

  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, getWorksheetElders(elders, orders, month), `個案`);
  utils.book_append_sheet(workbook, getWorksheetStatistics(elders, orders, yearMonthTitle, county), `統計表`);
  utils.book_append_sheet(workbook, getWorksheetStatistics([], [], yearMonthTitle, county), `核增統計表`);
  utils.book_append_sheet(workbook, getWorksheetAugementList([], []), `核增清冊`);
  utils.book_append_sheet(
    workbook,
    getWorksheetDeliveryFee(elders, orders, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName, false, oneWayDeliveryStaffFee),
    `車馬（送餐大使）`,
  );
  utils.book_append_sheet(
    workbook,
    getWorksheetDeliveryFee(elders, orders, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName, true),
    `車馬（Pandago）`,
  );

  const wbout = write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });
  zip.file(`${clientName}__核銷報表__${selectedMonth}.xlsx`, wbout, { binary: true });
  const zipContent = zip.generate({ type: 'blob' });
  download(`${clientName}__${selectedMonth}.zip`, zipContent);
}

function getWorksheetElders(elders = [], allOrders = [], month) {
  const elderRows = [];
  elders
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map(({
      id,
      name,
      gender,
      address: {
        county, district,
      },
      client: {
        facilitySettings: { dba },
        name: clientName,
      },
    }) => {
      const elderOrders = allOrders
        .filter(({ elderId }) => elderId === id);
      if (elderOrders.length === 0) {
        return;
      }
      elderRows.push({
        姓名: name,
        性別: formatGender(gender),
        所屬機構: dba || clientName,
        共計: elderOrders.length,
        午餐: elderOrders.filter(({ mealSlot }) => mealSlot === 'lunch').length,
        晚餐: elderOrders.filter(({ mealSlot }) => mealSlot === 'dinner').length,
        地區: county + district,
        轉介單: '',
      });
    });
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];
  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 8 } });
  utils.sheet_add_aoa(worksheet, [[`${month.replace(/^0+/, '')}月脆弱家庭送餐清冊`]], { origin: 'A1' });
  worksheet['A1'].s = {
    ...worksheet['A1'].s,
    alignment: { horizontal: 'center' },
  };

  utils.sheet_add_json(
    worksheet, elderRows, { origin: 'A2' });

  worksheet['!cols'] = [
    { wch: 13 },
    { wch: 5 },
    { wch: 18 }, { wch: 8 },
    { wch: 8 }, { wch: 8 },
    { wch: 15 },
  ];

  return worksheet;
}

function getWorksheetStatistics(elders = [], orders = [], yearMonthTitle, county) {
  const clientNames = elders.map(({ client }) => client.facilitySettings.dba || client.name);
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [];

  let totalLunchCount = 0;
  let totalDinnerCount = 0;
  const clientLunch = clientNames.reduce((obj, clientName) => {
    obj[clientName] = 0;
    return obj;
  }, { '機構': '午餐' });
  const clientDinner = clientNames.reduce((obj, clientName) => {
    obj[clientName] = 0;
    return obj;
  }, { '機構': '晚餐' });

  orders.forEach(({ elderId, mealSlot }) => {
    const elder = elders.find(({ id }) => id === elderId);
    const { facilitySettings: { dba }, name } = elder.client;
    if (mealSlot === 'lunch') {
      clientLunch[dba || name]++;
      totalLunchCount++;
    } else {
      clientDinner[dba || name]++;
      totalDinnerCount++;
    }
  });

  clientNames.forEach((clientName) => {
    if (clientLunch[clientName] === 0 && clientDinner[clientName] === 0) {
      delete clientLunch[clientName];
      delete clientDinner[clientName];
    }
  });

  clientLunch['合計'] = totalLunchCount;
  clientDinner['合計'] = totalDinnerCount;

  worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } });

  utils.sheet_add_aoa(worksheet, [[`${yearMonthTitle} ${county}公益盈餘補助計畫送餐統計表`]], { origin: 'A1' });
  utils.sheet_add_json(
    worksheet, [clientLunch, clientDinner], { origin: 'A2', skipHeader: false });

  worksheet['!merges'].push({ s: { r: 5, c: 0 }, e: { r: 6, c: 0 } });
  worksheet['!merges'].push({ s: { r: 5, c: 1 }, e: { r: 5, c: 3 } });
  utils.sheet_add_aoa(worksheet, [['脆弱家庭']], { origin: 'B6' });

  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'B7' });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'C7' });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'D7' });

  worksheet['!merges'].push({ s: { r: 5, c: 4 }, e: { r: 5, c: 5 } });
  utils.sheet_add_aoa(worksheet, [['服務人次']], { origin: 'E6' });

  utils.sheet_add_aoa(worksheet, [['男']], { origin: 'E7' });
  utils.sheet_add_aoa(worksheet, [['女']], { origin: 'F7' });

  utils.sheet_add_aoa(worksheet, [['補助金額']], { origin: 'G6' });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'G7' });

  const shortYearMonthTitle = yearMonthTitle.replace('年', '').replace('月', '');
  utils.sheet_add_aoa(worksheet, [[`${shortYearMonthTitle}(午餐)`]], { origin: 'A8' });
  utils.sheet_add_aoa(worksheet, [[`${shortYearMonthTitle}(晚餐)`]], { origin: 'A9' });
  utils.sheet_add_aoa(worksheet, [['合計']], { origin: 'A10' });

  const serviceCodeList = ['脆家'];

  const getSampleData = () => {
    return {
      '脆家男': 0,
      '脆家女': 0,
      '服務人數合計': 0,
      '服務人次男': 0,
      '服務人次女': 0,
      '金額合計': 0,
    };
  };

  const rate = {
    '縣市': {
      '脆家': 0,
    },
    '中央': {
      '脆家': 100,
    },
  };

  const data = {
    lunch: getSampleData(),
    dinner: getSampleData(),
    total: getSampleData(),
  };

  elders.forEach(({ id, serviceCode, gender, name }) => {
    if (serviceCode && !serviceCodeList.includes(serviceCode)) {
      return;
    }
    let genderString = formatGender(gender);
    if (!['男', '女'].includes(genderString)) {
      console.error(`${name} 沒有設定性別`);
      genderString = '男';
    }
    const elderOrders = orders.filter(({ elderId }) => elderId === id);

    const countMappings = {
      lunch: false,
      dinner: false,
    };
    elderOrders.forEach(({ mealSlot }) => {
      const type = serviceCode;
      if (type) {
        if (!countMappings[mealSlot]) {
          data[mealSlot][`${type}${genderString}`]++;
          data[mealSlot][`服務人數合計`]++;
          countMappings[mealSlot] = true;
        }
        data[mealSlot][`服務人次${genderString}`]++;
        data[mealSlot]['金額合計'] += 1 * rate['縣市'][type] + 1 * rate['中央'][type];
      }
    });
  });

  Object.keys(data.total).map((key) => {
    data.total[key] = data.lunch[key] + data.dinner[key];
  });

  utils.sheet_add_json(
    worksheet, [data.lunch, data.dinner, data.total], { origin: 'B8', skipHeader: true });

  return worksheet;
}

function getWorksheetAugementList() {
  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
  ];
  utils.sheet_add_aoa(worksheet, [[`照顧組合服務費用項目核增清冊`]], { origin: 'A1' });
  utils.sheet_add_aoa(worksheet, [['序號', '身分證號', '個案姓名', '福利身分別', '服務日期', '給(支)付價格', '次數', '申報費用', '目前居住縣市', '目前居住行政區', '服務人員']], { origin: 'A2' });
  return worksheet;
}

function getWorksheetDeliveryFee(elders, orders, deliveryStaffs, pandagoOrders, yearMonthTitle, clientName, pandagoSheet, oneWayDeliveryStaffFee) {
  const mappings = {};
  let totalCount = 0;
  let totalDeliveryStaffFee = 0;
  let totalMealCount = 0;
  orders.forEach(({ id: orderId, elderId, mealSlot, deliveryStaffId, date: fullDate, deliveryStaffFee }) => {
    const { name: elderName, address: { county, district } } = elders.find(({ id }) => id === elderId);
    const { name: deliveryStaffName } = deliveryStaffs.find(({ id }) => id === deliveryStaffId);
    const pandagoOrder = pandagoOrders.find((x) => x.orderId === orderId);
    const padnagoDeliveryStaffName = pandagoOrder ? `${pandagoOrder.driver.name} ${pandagoOrder.driver.phoneNumber}` : undefined;

    if (!!pandagoOrder !== pandagoSheet) {
      return;
    }

    const key = `${padnagoDeliveryStaffName || deliveryStaffName}__${deliveryStaffId}__${mealSlot}`;
    mappings[key] = mappings[key] || {
      '鄉鎮': [],
      '編號': '', // fill later
      '志工姓名': padnagoDeliveryStaffName || deliveryStaffName,
      '午餐/晚餐': mealSlot === 'lunch'? '午餐':'晚餐',
      // '單價(元)': 100,
      '送餐天數(天)': 0,
      '送餐數': 0,
      '合計(元)': 0,
      '服務個案姓名': [],
      '送餐日期': {},
    };

    if (!mappings[key]['鄉鎮'].includes(county + district)) {
      mappings[key]['鄉鎮'].push(county + district);
    }

    if (!mappings[key]['服務個案姓名'].includes(elderName)) {
      mappings[key]['服務個案姓名'].push(elderName);
    }

    const date = fullDate.split('-').pop();

    if (!mappings[key]['送餐日期'][date]) {
      mappings[key]['送餐天數(天)']++;
      totalCount++;
      if (oneWayDeliveryStaffFee) {
        mappings[key]['合計(元)'] += Number(oneWayDeliveryStaffFee);
        totalDeliveryStaffFee += Number(oneWayDeliveryStaffFee);
      }
    }
    mappings[key]['送餐數']++;
    totalMealCount++;
    if (!oneWayDeliveryStaffFee) {
      mappings[key]['合計(元)'] += deliveryStaffFee;
      totalDeliveryStaffFee += deliveryStaffFee;
    }

    mappings[key]['送餐日期'][date] = 1;
  });

  const data = Object.keys(mappings)
    .sort((a, b) => a < b ? 1 : -1)
    .map((key, index) => {
      const item = mappings[key];

      return Object.assign(item, {
        鄉鎮: item['鄉鎮'].join('、\n'),
        編號: index + 1,
        服務個案姓名: item['服務個案姓名'].reduce((groups, item) => {
          const lastGroup = groups[groups.length - 1];
          if (lastGroup && lastGroup.length < 3) {
            lastGroup.push(item);
          } else {
            groups.push([item]);
          }
          return groups;
        }, []).map((group) => `${group.join('、')}`).join('\n'),
        送餐日期: Object.keys(item['送餐日期'])
          .sort((a, b) => a > b ? 1 : -1)
          .reduce((array, dateKey) => {
            const previousItem = array[array.length - 1];

            if (previousItem && previousItem.dateKey) {
              const [from, to] = previousItem.dateKey.split('-');
              const lastDate = to || from;
              if (parseInt(lastDate) + 1 === parseInt(dateKey)) {
                previousItem.dateKey = `${from}-${dateKey}`;
                previousItem.value += item['送餐日期'][dateKey];
                return array;
              }
            }

            array.push({
              dateKey,
              value: item['送餐日期'][dateKey],
            });

            return array;
          }, [])
          .map(({ dateKey, value }) => `${dateKey}(${value})`)
          .join(', '),
      });
    });

  // https://docs.sheetjs.com/docs/solutions/processing#worksheet
  const worksheet = utils.json_to_sheet([]);
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
  ];
  utils.sheet_add_aoa(worksheet, [[`${yearMonthTitle} 送餐大使送餐服務費清冊`]], { origin: 'A1' });
  utils.sheet_add_aoa(worksheet, [[`服務單位: ${clientName}`]], { origin: 'A2' });
  utils.sheet_add_aoa(worksheet, [['鄉鎮', '編號', '志工姓名', '午餐/晚餐', '送餐天數(天)', '送餐數', '合計(元)', '服務個案姓名', '送餐日期']], { origin: 'A3' });
  utils.sheet_add_json(worksheet, data, { origin: 'A4', skipHeader: true });

  const offset = 3 + data.length;
  worksheet['!merges'].push({ s: { r: offset, c: 0 }, e: { r: offset, c: 3 } });
  utils.sheet_add_aoa(worksheet, [[`合計`]], { origin: `A${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalCount]], { origin: `E${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalMealCount]], { origin: `F${offset+1}` });
  utils.sheet_add_aoa(worksheet, [[totalDeliveryStaffFee]], { origin: `G${offset+1}` });

  utils.sheet_add_aoa(worksheet, [[`承辦人簽章：`]], { origin: `A${offset+3}` });
  utils.sheet_add_aoa(worksheet, [[`主管簽章：`]], { origin: `E${offset+3}` });

  worksheet['!cols'] = [
    { wch: 13 },
    { wch: 5 },
    { wch: 20 },
    { wch: 13 },
    { wch: 13 },
    { wch: 13 },
    { wch: 10 },
    { wch: 45 },
    { wch: 40 },
  ];

  return worksheet;
}
