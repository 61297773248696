import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';

import DataForm from 'forms/DataForm';
import { request } from 'utilities/graph';
import { getOrder } from 'graphql/queries';
import { adminUpdateOrder } from 'graphql/mutations';
import TextField from '@material-ui/core/TextField';
// import Directions from 'components/Map/Directions';

import uiSchema from './AdminUpdateOrderForm.uiSchema';
import { TIME_ZONE } from '@silvergatedelivery/constants';

const defaultFormData = {};

export default function AdminUpdateOrderForm({ formData: inFormData, ...props }) {
  const { t } = useTranslation();
  // load here for translation purpose
  const { default: schema } = require('./AdminUpdateOrderForm.schema.js');
  const formData = (inFormData || defaultFormData);
  if (!formData.deliveryStaffId) delete formData.deliveryStaffId;

  const [deliveryBy, setDeliveryBy] = useState(formData.deliveryBy);
  const [dirty, setDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [directionKey, setDirectionKey] = useState();
  // const [startAddress, setSartAddress] = useState();
  // const [endAddress, setEndAddress] = useState();

  const createFunc = async () => {};

  const updateFunc = async (data) => {
    setIsLoading(true);

    const status = data.deliveryStaffId && ['waitingForDeliveryStaff', 'reMatchingDeliveryStaff'].includes(data.status) ?
      'ready' : data.status;

    // workaroud...
    data.mealItems.forEach((mealItem) => {
      if (!mealItem.surcharges) {
        mealItem.surcharges = [];
      }
    });
    const toUpdateData = {
      id: data.id,
      restaurantId: data.restaurantId,
      deliveryBy,
      status,
      deliveryStaffId: data.deliveryStaffId,
      deliveryStaffFee: data.deliveryStaffFee || 0,
      tier: data.tier,
      noteForDelivery: data.noteForDelivery,
      noteForMeal: data.noteForMeal,
      note: data.note,
      mealItems: data.mealItems,
      total: (data.mealItems || []).reduce((sum, item) => {
        return sum + item.price * item.quantity;
      }, 0),
      totalCost: (data.mealItems || []).reduce((sum, item) => {
        return sum + item.cost * item.quantity;
      }, 0),
      category: data.category,
      cancellationReason: data.cancellationReason,
      cancellationNote: data.cancellationNote,
    };
    global.logger.debug({ data, toUpdateData });

    try {
      await request(adminUpdateOrder, { input: { orders: [toUpdateData] } });
    } catch (e) {
      console.log(e);
    }
    const { data: { getOrder: order } } = await request(getOrder, { id: data.id });
    setIsLoading(false);
    return order;
  };

  // useEffect(() => {
  //   if (directionKey) {
  //     const [restaurantId, elderId] = directionKey.split('__');
  //     (async () => {
  //       const [
  //         { data: { getRestaurant: restaurant } },
  //         { data: { getElder: elder } },
  //       ] = await Promise.all([
  //         request(getRestaurant, { id: restaurantId }),
  //         request(getElder, { id: elderId }),
  //       ]);

  //       setSartAddress(restaurant.address);
  //       setEndAddress(elder.address);
  //     })();
  //   }
  // }, [directionKey]);

  return (
    <React.Fragment>
      {deliveryBy &&
      <TextField
        id="deliveryBy"
        label={`${t('送餐')}時間`}
        type="datetime-local" // format 2017-05-24T10:30
        value={moment(deliveryBy).tz(TIME_ZONE).format('YYYY-MM-DDTHH:mm')}
        disabled={
          isLoading ||
          ['readyForPickup', 'delivering', 'delivered', 'completed', 'cancelled'].includes(formData.status)
        }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          const updatedDatetime = moment.tz(e.target.value, TIME_ZONE).toISOString();
          if (updatedDatetime) {
            setDeliveryBy(updatedDatetime);
            setDirty(true);
          }
        }}
      />}
      <DataForm
        schema={schema}
        uiSchema={uiSchema}
        createFunc={createFunc}
        updateFunc={updateFunc}
        formData={formData}
        dirty={dirty}
        {...props}
      >
        {/* <Directions startAddress={startAddress} endAddress={endAddress}/> */}
      </DataForm>
    </React.Fragment>
  );
}

AdminUpdateOrderForm.propTypes = {
  formData: PropTypes.object,
};
