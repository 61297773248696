/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutoSelectWidget = (props) => {
  const {
    schema,
    id,
    options,
    label,
    required,
    disabled,
    readonly,
    value: inValue,
    autofocus,
    onChange,
    rawErrors = [],
  } = props;
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const { allowCustomInput = false } = options;
  const { referenceOptions } = schema;

  useEffect(() => {
    if (typeof inValue !== 'undefined') {
      if (referenceOptions.includes(inValue)) {
        setValue(inValue);
        setInputValue(inValue);
      } else if (allowCustomInput) {
        setValue('');
        setInputValue(inValue);
      }
    }
  }, [inValue, referenceOptions]);

  const renderInput = (params) => {
    return (<TextField
      {...params}
      label={label || schema.title}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      error={rawErrors.length > 0 || (errorMsg ? true : false)}
      required={required}
      InputLabelProps={{
        shrink: true,
      }}
      helperText={errorMsg || params.helperText}
    />);
  };

  return (
    <Autocomplete
      id={`${id}_${Math.random()}`}
      options={referenceOptions}
      required={required}
      freeSolo
      value={value}
      onChange={(event, newItem) => {
        if (newItem) {
          if (referenceOptions.includes(newItem)) {
            onChange(newItem);
            setErrorMsg();
          } else {
            if (allowCustomInput) {
              onChange(newItem);
            } else {
              setErrorMsg('沒有符合的資料');
            }
          }
        } else if (allowCustomInput) {
          onChange(newItem);
        }
      }}
      inputValue={inputValue || ''}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'reset') return;

        setInputValue(newInputValue);
        if (newInputValue) {
          if (referenceOptions.includes(newInputValue)) {
            onChange(newInputValue);
            setErrorMsg();
          } else {
            if (allowCustomInput) {
              onChange(newInputValue);
            } else {
              setErrorMsg('沒有符合的資料');
            }
          }
        }
      }}
      // onBlur={_onBlur}
      // onFocus={_onFocus}
      renderInput={renderInput}
    />
  );
};

export default AutoSelectWidget;
