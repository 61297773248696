import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Form from '@rjsf/material-ui';
import { customizeValidator } from '@rjsf/validator-ajv8';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'debounce';
import { countiesDistricts } from '@silvergatedelivery/constants';
import { getRestaurantMealsByRestaurantByIsActive } from './AdminBulkOrderForm/queries';
import { asyncListAll } from 'utilities/graph';
import localizer from 'ajv-i18n';

const validator = customizeValidator({}, localizer['zh-TW']);

import SubmitButton from 'forms/SubmitButton';
import {
  getOrganizationIdSchema,
  getElderIdSchema,
  getDeliveryGroupIdSchema,
  getRestaurantIdSchemaByClientId,
  getRestaurantOwnerIdSchema,
  getDeliveryStaffIdSchemaByClientId,
  getClientIdSchema,
  getAddressSchema,
  getEmergencyContactSchema,
  getCountySchema,
  getMealItemsSchema,
  getSponsorProgramIdSchema,
} from 'forms/schemas';
import {
  getTagsUiSchema,
  getMealItemsUiSchema,
  getEmergencyContactUiSchema,
  getAddressUiSchema,
} from 'forms/uiSchema';
import Place from 'components/Map/Place';
import ArrayTemplate from './components/ArrayTemplate';
import ObjectFieldTemplate from './components/ObjectFieldTemplate';
import FieldTemplate from './components/FieldTemplate';
import SelectWidget from './components/SelectWidget';
import TimeWidget from './components/TimeWidget';
import cache from 'utilities/cache';
import { request } from 'utilities/graph';
import {
  queryAddress,
} from 'graphql/mutations';
import { formatAddress } from 'utilities/format';

// https://react-jsonschema-form.readthedocs.io/en/latest/api-reference/form-props/
// formData
// onSubmit

const widgets = {
  SelectWidget,
  TimeWidget,
};

const INPUT_DELAY = 1000;

function findKeyPaths(obj, targetKey) {
  const keyPaths = [];

  function findKeyPathsRecursive(currentObj, currentPath) {
    // eslint-disable-next-line guard-for-in
    for (const key in currentObj) {
      if (Array.isArray(currentObj[key])) {
        currentObj[key].forEach((item, index) => {
          findKeyPathsRecursive(item, currentPath + '.' + key + '[' + index + ']');
        });
      } else if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
        findKeyPathsRecursive(currentObj[key], currentPath + '.' + key);
      }
      if (key === targetKey) {
        keyPaths.push((currentPath + '.' + key).replace(/^\./, ''));
      }
    }
  }

  findKeyPathsRecursive(obj, '');

  return keyPaths;
}

function getValueByPath(obj, path) {
  return path.split('.').reduce((current, key) => {
    if (key.includes('[')) {
      const [arrayKey, index] = key.split('[');
      const arrayIndex = parseInt(index.replace(']', ''));
      return current[arrayKey][arrayIndex];
    }
    return current[key];
  }, obj);
}

const DataForm = forwardRef(function DataForm({
  schema: inSchema,
  uiSchema: inUiSchema,
  createFunc,
  updateFunc,
  onComplete,
  formData: inFormData,
  children,
  onChange,
  hideSubmitButton = false,
  dirty: inDirty = false,
  extMappings: inExtMappings = [],
  extUiMappings: inExtUiMappings = [],
  targetZipcodes,
  addressLiteVersion = false,
  ...props
}, ref) {
  const [schema, setSchema] = useState();
  const [uiSchema, setUiSchema] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentAddress, setCurrentAddress] = useState({});
  const [dirty, setDirty] = useState(inDirty);
  const [errorMessage, setErrorMessage] = useState();
  const [showPlace, setShowPlace] = useState(false);
  const [mealOptionsList, setMealOptionsList] = useState({});

  const handleSubmit = async ({ formData }) => {
    global.logger.debug('handleSubmit', formData);
    try {
      setIsLoading(true);

      // run address query to fill lat/lng
      if (currentAddress && formData.address && (
        currentAddress.county !== formData.address.county ||
        currentAddress.district !== formData.address.district ||
        currentAddress.street !== formData.address.street ||
        !formData.address.lat || !formData.address.lng
      )) {
        global.logger.debug('Fetching address lat/lng');
        const params = {
          input: {
            address: formatAddress(formData.address, { includeZipCode: false, ignoreNote: true }),
          },
        };

        try {
          const {
            data: {
              queryAddress: {
                success,
                data: {
                  lat,
                  lng,
                },
              },
            },
          } = await request(queryAddress, params);
          if (!success) {
            if (addressLiteVersion) {
              setIsLoading(false);
              setErrorMessage('無法定位地址，請重新確認輸入的地址');
              return;
            }
            if (formData.address.lat && formData.address.lng) {
              if (!window.confirm('無法定位地址，要使用所填入的經緯度請按確認，或按取消重新確認輸入的地址？')) {
                setIsLoading(false);
                setErrorMessage('無法定位地址，請重新確認輸入的地址');
                return;
              }
            } else {
              setIsLoading(false);
              setErrorMessage('無法定位地址，請重新確認輸入的地址，或自行填入經緯度');
              return;
            }
          } else {
            if (!addressLiteVersion && formData.address.lat && formData.address.lng) {
              if (formData.address.lat !== lat || formData.address.lng !== lng) {
                if (window.confirm('定位結果與輸入的經緯度不同，按確定使用定位結果，或按取消使用原輸入的經緯度')) {
                  formData.address.lat = lat;
                  formData.address.lng = lng;
                }
              }
            } else {
              formData.address.lat = lat;
              formData.address.lng = lng;
            }
          }
        } catch (e) {
          if (formData.address.lat && formData.address.lng) {
            if (!window.confirm('定位系統忙碌或出錯，要使用所填入的經緯度請按確認，或按取消稍後再試？')) {
              setIsLoading(false);
              setErrorMessage('定位系統忙碌或出錯，請稍後再試');
              return;
            }
          } else {
            setIsLoading(false);
            setErrorMessage('定位系統忙碌或出錯，請稍後再試，或自行填入經緯度');
            return;
          }
        }
      }

      setFormData(formData); // persist the data;

      const now = moment().toISOString();
      const username = cache.get('app:username');

      let data;
      let returnData;
      if (formData.id) {
        data = Object.assign(formData, {
          updatedAt: now,
          updatedBy: username,
        });
        returnData = await updateFunc(data);
      } else {
        data = Object.assign(formData, {
          createdAt: now,
          createdBy: username,
          updatedAt: now,
          updatedBy: username,
        });
        returnData = await createFunc(data);
      }

      setIsLoading(false);
      onComplete && onComplete(returnData || data);
    } catch (e) {
      global.logger.debug(e);
      setIsLoading(false);
    }
  };

  const getMealOptions = async (restaurantId) => {
    if (!restaurantId) {
      return;
    }
    let mealOptions = mealOptionsList[restaurantId];
    if (!mealOptions) {
      mealOptions = await asyncListAll(
        getRestaurantMealsByRestaurantByIsActive, { restaurantId });
      mealOptionsList[restaurantId] = mealOptions;
      setMealOptionsList(mealOptionsList);
    }
    return mealOptions;
  };

  const updateSchema = async () => {
    setIsLoading(true);
    let mealOptions = [];
    if (inFormData && inFormData.restaurantId) {
      mealOptions = await getMealOptions(inFormData.restaurantId);
    }
    const clientId = cache.get('app:facilityId');
    // Update schema
    const extMappings = [
      { key: 'organizationId', func: getOrganizationIdSchema },
      { key: 'elderId', func: getElderIdSchema },
      { key: 'deliveryGroupId', func: getDeliveryGroupIdSchema },
      { key: 'restaurantId', func: getRestaurantIdSchemaByClientId(clientId) },
      { key: 'restaurantOwnerId', func: getRestaurantOwnerIdSchema },
      { key: 'deliveryStaffId', func: getDeliveryStaffIdSchemaByClientId(clientId) },
      { key: 'clientId', func: getClientIdSchema },
      { key: 'address', func: getAddressSchema(targetZipcodes, addressLiteVersion) },
      { key: 'emergencyContact', func: getEmergencyContactSchema },
      { key: 'county', func: getCountySchema },
      { key: 'mealItems', func: getMealItemsSchema(mealOptions) },
      { key: 'sponsorProgramId', func: getSponsorProgramIdSchema },
    ];
    const schema = inSchema;
    const propertiesPathes = findKeyPaths(schema, 'properties');
    const allPromises = [];
    propertiesPathes.forEach(async (path) => {
      const properties = getValueByPath(schema, path);
      const promises = Object.keys(properties).map(async (key) => {
        const matched = inExtMappings.find((item) => item.key === key) ||
          extMappings.find((item) => item.key === key);
        if (matched) {
          const currentValue = (inFormData || {})[key];
          const extSchema = await matched.func(currentValue, properties[key].title);
          if (extSchema) {
            properties[key] = Object.assign(properties[key], extSchema);
          }
          if (key === 'address') {
            setShowPlace(true);
          }
        }
      });
      allPromises.push(...promises);
    });

    await Promise.all(allPromises);

    setSchema(schema);

    // UI Schema
    const uiSchema = inUiSchema;
    const extUiMappings = [
      { key: 'tags', func: getTagsUiSchema },
      { key: 'mealItems', func: getMealItemsUiSchema },
      { key: 'emergencyContact', func: getEmergencyContactUiSchema },
      { key: 'address', func: getAddressUiSchema },
    ];

    Object.keys(schema.properties).forEach((key) => {
      uiSchema[key] = uiSchema[key] || {};

      const matched = inExtUiMappings.find((item) => item.key === key) ||
        extUiMappings.find((item) => item.key === key);

      if (matched) {
        const extSchema = matched.func();
        uiSchema[key] = Object.assign(uiSchema[key], extSchema);
      }

      if (schema.properties[key].type === 'string' ||
        (Array.isArray(schema.properties[key].type) && schema.properties[key].type.includes('string'))
      ) {
        uiSchema[key] = Object.assign(uiSchema[key], {
          'ui:emptyValue': '',
        });
      }
    });

    setUiSchema(uiSchema);
    setIsLoading(false);
  };

  useImperativeHandle(ref, () => ({
    updateSchema,
  }));

  useEffect(() => {
    if (schema || isLoading) return;

    (async () => {
      await updateSchema();
    })();
  }, [inSchema, schema, inUiSchema, inFormData, inExtMappings, inExtUiMappings, isLoading]);

  useEffect(() => {
    setFormData(inFormData);

    if (inFormData && inFormData.address) {
      setCurrentAddress(inFormData.address);
    }
  }, [inFormData]);

  useEffect(() => {
    setDirty(inDirty);
  }, [inDirty]);

  const updateMealItems = async (newFormData) => {
    if (!newFormData.restaurantId || !schema.properties.mealItems) {
      return;
    }
    const mealOptions = await getMealOptions(newFormData.restaurantId);
    if (newFormData.restaurantId && newFormData.restaurantId !== formData.restaurantId) {
      if (mealOptions.length) {
        if (newFormData.mealItems) {
          const newMealItems = [];
          const { name, price = 0, cost = 0, surcharges = [] } = mealOptions[0];
          newFormData.mealItems.forEach((mealItem) => {
            newMealItems.push({
              ...mealItem,
              name,
              price,
              cost,
              surcharges: surcharges || [],
            });
          });
          newFormData.mealItems = newMealItems;
        }
      }
      schema.properties.mealItems = getMealItemsSchema(mealOptions)();
      setSchema(schema);
    } else {
      const mealOptions = mealOptionsList[newFormData.restaurantId];
      newFormData.mealItems.forEach((mealItem, index) => {
        if (mealItem.name && (!formData.mealItems[index] || mealItem.name !== formData.mealItems[index].name)) {
          if (mealOptions.length) {
            const option = mealOptions.find(({ name }) => name === mealItem.name);
            if (option) {
              mealItem.price = option.price;
              mealItem.cost = option.cost;
              mealItem.surcharges = option.surcharges || [];
            }
          }
        }
      });
    }
  };

  if (!schema) return null;

  return (
    <Form
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      formData={formData}
      onSubmit={handleSubmit}
      disabled={isLoading}
      showErrorList={false}
      focusOnFirstError
      // liveValidate={true}
      onChange={debounce(async ({ formData }) =>{
        global.logger.debug('formData update', formData);
        if (formData.address && formData.address.county && formData.address.district) {
          const { zipCode } = countiesDistricts.find(
            (item) => item.county === formData.address.county && item.districts.includes(formData.address.district),
          ) || {};
          if (zipCode) {
            formData.address.zipCode = zipCode;
          }
        }
        await updateMealItems(formData);
        setFormData(formData);
        setDirty(true);
        onChange && onChange(formData);
      }, INPUT_DELAY)}
      templates={{ ArrayFieldTemplate: ArrayTemplate, ObjectFieldTemplate, FieldTemplate }}
      widgets={widgets}
      {...props}
    >
      {showPlace && formData && formData.address && (
        <div>
          {!addressLiteVersion && <Typography variant="caption" color="textSecondary" >
          按下顯示詳細地圖，在google地圖上按下滑鼠右鍵可以取得經緯度
          </Typography>}
          <Place address={formData.address} />
        </div>
      )}

      {children}

      {!hideSubmitButton &&
      <Grid container justifyContent="center" spacing={2}>
        <SubmitButton disabled={!dirty || isLoading} />
      </Grid>}

      {errorMessage && <div style={{ color: 'red', textAlign: 'center', marginTop: 12 }}>{errorMessage}</div>}
    </Form>
  );
});

DataForm.propTypes = {
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
  createFunc: PropTypes.func,
  updateFunc: PropTypes.func,
  formData: PropTypes.object,
  onComplete: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.any,
  hideSubmitButton: PropTypes.bool,
  dirty: PropTypes.bool,
  extMappings: PropTypes.array,
  extUiMappings: PropTypes.array,
  targetZipcodes: PropTypes.array,
  addressLiteVersion: PropTypes.bool,
};

export default DataForm;
